import React from "react";
import { withRouter } from "react-router-dom";
import {
	handleManageProfiles,
	handleDownloadMultipleFiles,
	handleReprocessFiles,
	handleDeleteFiles,
	handleDeleteFilePermanently,
	handleMarkAsDeleted,
	handleMarkAsActived,
	handleReprocessRepository,
	handleGstReturnSubmission,
	handleGst2aRequestSubmit,
} from "./HandleUserActions";
import {
	pushModal,
	pushDialog,
	auditNoteSubmit,
	setDataFileStatusAction,
	deleteFilePermanentlyAction,
	deleteInvalidFilePermanentlyAction,
	hideFileAction,
	reprocessRepositoryAction,
	uploadAction,
	downloadAction,
	handleDownloadFilesAction,
} from "../../store/actions";
import { connect } from "react-redux";
import { datetime } from "../../utils/datetime";
import { UserRoles } from "../../enums/userRoles";

const UserActions = (props) => {
	const { params } = props;

	if (params.section) {
		switch (params.section) {
			case "kpis":
			case "reports":
			case "profile_rules":
				return settingsProfilesActions(props);
			case "file_logs":
				return settingsFileLogsActions(props);
			case "folders":
				return repositoryFoldersActions(props);
			case "invalid_files":
				return repositoryInvalidFiles(props);
			default:
				break;
		}
	} else if (params.entity && params.entity === "gst_return_submit") {
		return gstReturnSubmit(props);
	} else if (params.entity && params.entity === "report") {
		return gst2aRequestSubmit(props);
	}

	return null;
};

const repositoryInvalidFiles = (props) => {
	const { user, styles, i18n, RegularButton, checkbox, total } = props;
	const validateUser = [
		UserRoles.ADMIN,
		UserRoles.OWNER,
		UserRoles.WRITER,
		UserRoles.PP_SUPPORT,
		UserRoles.SUPPORT,
	].includes(user.role);
	return (
		<>
			{validateUser ? (
				<div>
					{checkbox && total ? (
						<div className={styles["actions-repository"]}>
							<RegularButton
								danger
								icon="success"
								onClick={() => {
									handleDeleteFilePermanently(props);
								}}
								className={`${styles["simple-button"]} ${styles["btn-delete-permanently"]}`}
								data-toggle={"tooltip"}
								data-placement={"top"}
								title={i18n`This Action will delete the file from system`}
							>
								{i18n`Delete permanently`}
							</RegularButton>
						</div>
					) : null}
				</div>
			) : (
				<div />
			)}
		</>
	);
};
const repositoryFoldersActions = (props) => {
	const {
		user,
		styles,
		i18n,
		RegularButton,
		checkbox,
		total,
		repositoryStatus,
	} = props;
	const validateUser = [
		UserRoles.ADMIN,
		UserRoles.OWNER,
		UserRoles.WRITER,
		UserRoles.PP_SUPPORT,
		UserRoles.SUPPORT,
	].includes(user.role);
	return (
		<>
			{validateUser ? (
				<div>
					{checkbox && total ? (
						<div className={styles["actions-repository"]}>
							<RegularButton
								icon="download-file"
								onClick={() => {
									handleDownloadMultipleFiles(props);
								}}
								className={`${styles["simple-button"]} ${styles["btn-download_files"]}`}
							>
								{i18n`Download file`}
							</RegularButton>

							<RegularButton
								danger
								icon="success"
								onClick={() => {
									handleDeleteFilePermanently(props);
								}}
								className={`${styles["simple-button"]} ${styles["btn-delete-permanently"]}`}
								data-toggle={"tooltip"}
								data-placement={"top"}
								title={i18n`This Action will delete the file from the system and consequently making the data for this project inconsistent`}
							>
								{i18n`Delete permanently`}
							</RegularButton>

							<RegularButton
								warning
								icon="success"
								onClick={() => {
									handleMarkAsDeleted(props);
								}}
								className={`${styles["simple-button"]} ${styles["btn-mark-delete"]}`}
								data-toggle={"tooltip"}
								data-placement={"top"}
								title={i18n`This action makes the file inactive but remains physically stored on the system. Consequently making the data for this project inconsistent`}
							>
								{i18n`Inactivate file`}
							</RegularButton>

							<RegularButton
								primary
								icon="success"
								onClick={() => {
									handleMarkAsActived(props);
								}}
								className={`${styles["simple-button"]} ${styles["btn-mark-active"]}`}
								data-toggle={"tooltip"}
								data-placement={"top"}
								title={i18n`This action turns the inactive files into active. Makes the file data visible in views.`}
							>
								{i18n`Activate file`}
							</RegularButton>

							{repositoryStatus && !repositoryStatus.status && (
								<RegularButton
									icon="success"
									onClick={() => {
										handleReprocessRepository(props);
									}}
									className={`${styles["simple-button"]} ${styles["btn-process-all"]} ${styles["disabled"]}`}
								>
									{i18n`Process all`}
								</RegularButton>
							)}
						</div>
					) : null}
				</div>
			) : (
				<div />
			)}
		</>
	);
};
const settingsFileLogsActions = (props) => {
	const { styles, i18n, RegularButton } = props;
	return (
		<>
			{
				<div className={styles["actions-buttons"]}>
					<div>
						<RegularButton
							icon="refresh"
							onClick={() => window.location.reload()}
							iconClassName={`${styles["small-background-color"]}`}
							className={`${styles["simple-button"]} ${styles["btn-refresh"]} ${styles["btn-background-color"]}`}
						/>
						<small
							className={`${styles["btn-refresh"]} ${styles["small-background-color"]}`}
						>
							{i18n`Last update:`} {datetime(new Date())}
						</small>
					</div>
				</div>
			}
			{
				<div className={styles["actions-user-buttons"]}>
					<RegularButton
						icon="download-file"
						onClick={() => {
							handleDownloadMultipleFiles(props);
						}}
						className={`${styles["simple-button"]} ${styles["btn-background-color"]}`}
					>
						{i18n`Download`}
					</RegularButton>

					<RegularButton
						danger
						icon="success"
						onClick={() => {
							handleDeleteFiles(props);
						}}
						className={`${styles["simple-button"]} ${styles["btn-delete-permanently"]}`}
						style={{ marginLeft: "10px" }}
					>
						{i18n`Delete`}
					</RegularButton>

					<RegularButton
						warning
						icon="success"
						onClick={() => {
							handleReprocessFiles(props);
						}}
						className={`${styles["simple-button"]} ${styles["btn-mark-delete"]}`}
						style={{ marginLeft: "10px" }}
					>
						{i18n`Reprocess`}
					</RegularButton>
				</div>
			}
		</>
	);
};
const settingsProfilesActions = (props) => {
	const { styles, i18n, RegularButton } = props;

	return (
		<div className={styles["actions-user-buttons"]}>
			<RegularButton
				icon="success"
				onClick={() => {
					handleManageProfiles({ active: true }, props);
				}}
				className={`${styles["simple-button"]} ${styles["btn-background-color"]}`}
			>
				{i18n`Activate`}
			</RegularButton>

			<RegularButton
				danger
				icon="success"
				onClick={() => {
					handleManageProfiles({ active: false }, props);
				}}
				className={`${styles["simple-button"]} ${styles["btn-delete-permanently"]}`}
				style={{ marginLeft: "10px" }}
			>
				{i18n`Deactivate`}
			</RegularButton>
		</div>
	);
};
const gstReturnSubmit = (props) => {
	const { styles, i18n, RegularButton } = props;

	return (
		<div className={styles["actions-user-buttons"]}>
			<RegularButton
				primary
				icon="success"
				onClick={() => {
					handleGstReturnSubmission(props);
				}}
				className={`${styles["simple-button"]}`}
			>
				{i18n`GST Return Submit`}
			</RegularButton>
		</div>
	);
};

const gst2aRequestSubmit = (props) => {
	const { styles, i18n, RegularButton } = props;

	return (
		<div className={styles["actions-user-buttons"]}>
			<RegularButton
				primary
				icon="success"
				onClick={() => {
					handleGst2aRequestSubmit(props);
				}}
				className={`${styles["simple-button"]}`}
			>
				{i18n`Fetch GSTR2A Data`}
			</RegularButton>
		</div>
	);
};

export default connect(
	({ fetch, menu, uploads }) => ({
		fetch,
		menu,
		uploads,
	}),
	{
		pushModal,
		pushDialog,
		setDataFileStatus: setDataFileStatusAction,
		deleteFilePermanently: deleteFilePermanentlyAction,
		deleteInvalidFilePermanently: deleteInvalidFilePermanentlyAction,
		hideFile: hideFileAction,
		downloadFiles: handleDownloadFilesAction,
		reprocessRepository: reprocessRepositoryAction,
		uploadFiles: uploadAction,
		download: downloadAction,
		submitAuditNote: auditNoteSubmit,
	}
)(withRouter(UserActions));
