import { date, format } from "../../utils/index";

const alignByType = (type) => {
	const obj = {
		date: "left",
		number: "right",
		string: "left",
		currency: "right",
		unity: 'right'
	};
	return obj[`${type}`] || "left";
};

const formatByType = (type, value) => {
	switch (type) {
		case "date":
			return date(value);
		case "currency":
			return format(value, "normal", null);
		case "number":
			return format(value, "normal", null)
		case "unity":
			return format(value, "normal", null)
		default:
			return value;
	}
};

const mapCard = (json) => {
	const {
		F1,
		F2,
		F3,
		F4,
		F5,
		F6,
		F7,
		F8,
		EntityID,
		EntityName,
		TypeByField,
	} = json;
	const entityType = EntityName.split("::").pop();
	return [
		F1
			? [
				{
					label: "F1",
					value: formatByType(TypeByField["T1"].type, F1),
					type: (TypeByField["T1"].type === 'date' ? "string" : TypeByField["T1"].type) || "string",
				},
			]
			: null,
		F2
			? [
				{
					label: "F2",
					value: formatByType(TypeByField["T2"].type, F2),
					type: (TypeByField["T2"].type === 'date' ? "string" : TypeByField["T2"].type) || "string",
				},
			]
			: null,
		F3
			? [
				{
					label: "F3",
					value: formatByType(TypeByField["T3"].type, F3),
					type: (TypeByField["T3"].type === 'date' ? "string" : TypeByField["T3"].type) || "string",
				},
			]
			: null,
		F4
			? [
				{
					label: "F4",
					value: formatByType(TypeByField["T4"].type, F4),
					type: (TypeByField["T4"].type === 'date' ? "string" : TypeByField["T4"].type) || "string",
				},
			]
			: null,
		F5
			? [
				{
					label: "F5",
					value: formatByType(TypeByField["T5"].type, F5),
					type: (TypeByField["T4"].type === 'date' ? "string" : TypeByField["T4"].type) || "string",
				},
			]
			: null,
		F6
			? [
				{
					label: "F6",
					value: formatByType(TypeByField["T6"].type, F6),
					type: (TypeByField["T6"].type === 'date' ? "string" : TypeByField["T6"].type) || "string",
				},
			]
			: null,
		F7
			? [
				{
					label: "F7",
					value: formatByType(TypeByField["T7"].type, F7),
					type: (TypeByField["T7"].type === 'date' ? "string" : TypeByField["T7"].type) || "string",
				},
			]
			: null,
		F8
			? [
				{
					label: "F8",
					value: formatByType(TypeByField["T8"].type, F8),
					type: (TypeByField["T8"].type === 'date' ? "string" : TypeByField["T8"].type) || "string",
				},
			]
			: null,
		[
			{
				labels: ["Refers Id"],
				value: {
					id: EntityID,
					name: entityType,
					__typename: `Cv${entityType}`,
				},
				type: "object",
				align: "center",
			},
		],
	].filter((elem) => elem !== null);
};

const header = (json) => {
	return [
		json["T1"]
			? { label: [`${json["T1"].name}`], align: alignByType(json["T1"].type) }
			: null,
		json["T2"]
			? { label: [`${json["T2"].name}`], align: alignByType(json["T2"].type) }
			: null,
		json["T3"]
			? { label: [`${json["T3"].name}`], align: alignByType(json["T3"].type) }
			: null,
		json["T4"]
			? { label: [`${json["T4"].name}`], align: alignByType(json["T4"].type) }
			: null,
		json["T5"]
			? { label: [`${json["T5"].name}`], align: alignByType(json["T5"].type) }
			: null,
		json["T6"]
			? { label: [`${json["T6"].name}`], align: alignByType(json["T6"].type) }
			: null,
		json["T7"]
			? { label: [`${json["T7"].name}`], align: alignByType(json["T7"].type) }
			: null,
		json["T8"]
			? { label: [`${json["T8"].name}`], align: alignByType(json["T8"].type) }
			: null,
		{ label: ["Refers ID"], align: "center" },
	].filter((elem) => elem !== null);
};

const mapper = (json, card) => {
	return {
		...json,
		__typename: json.__typename,
		card: card ? mapCard(json).filter((elem) => elem.length !== 0) : [],
	};
};

const ncfindingMapper = {
	map: mapper,
	header
};

export default ncfindingMapper