import { format } from '../../utils'

const mapCard = (json) => {
	const {
        addressCountry,
        debitAmount,
        creditAmount,
        documentTotalsTaxPayable,
        documentTotalsNetTotal,
        documentTotalsGrossTotal,
        totalDocuments
	} = json

	return [
		[
			{ labels: ['addressCountry'], value: addressCountry, type: 'string', basis: "10%" }

		],
		[
			{ labels: ['totalDocuments'], value: format(totalDocuments,null,null,0), type: 'currency' }
		],
		[
			{ labels: ['debitAmount'], value: format(debitAmount), type: 'currency' }

		],
		[
			{ labels: ['creditAmount'], value: format(creditAmount), type: 'currency'}

		],
		[
			{ labels: ['documentTotalsTaxPayable'], value: format(documentTotalsTaxPayable), type: 'currency' }

        ],
        [
			{ labels: ['documentTotalsNetTotal'], value: format(documentTotalsNetTotal), type: 'currency' }

        ],
        [
			{ labels: ['documentTotalsGrossTotal'], value: format(documentTotalsGrossTotal), type: 'currency' }

		],
	]
}

const header = [
	{ label: ['Address Country'], align: 'left', basis: "10%"},
    { label: ['Total Documents'], align: 'right', columnName: "total_documents", sortable: true},
	{ label: ['Debit Amount'], align: 'right', columnName: "debit_amount", sortable: true, totalizerName: "total_debit_amount" },
	{ label: ['Credit Amount'], align: 'right', columnName: "credit_amount", sortable: true, totalizerName: "total_credit_amount" },
	{ label: ['Tax Payable'], align: 'right', columnName: "document_totals_tax_payable", sortable: true, totalizerName: "total_document_totals_tax_payable" },
    { label: ['Net Total'], align: 'right', columnName: "document_totals_net_total", sortable: true, totalizerName: "total_document_totals_net_total" },
    { label: ['Gross Total'], align: 'right', columnName: "document_totals_gross_total", sortable: true, totalizerName: "total_document_totals_gross_total" },
]

const mapper = (json, card) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: {
			to: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/invoices',
			filter: [{ "filters": [{ "value": json.addressCountry }], "column": "customer__billing_address_country", "operator": "equals" }]
		}
	}
}

const salesbycountryMapper = {
    map: mapper,
    header
};

export default salesbycountryMapper