import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router'
import DataLoader from './DataLoader'
import { filteredRoutes, defaultRoutes } from '../routes'
import { ReactComponent as Icons } from 'colbi_web_ui/lib/assets/icons.inline.svg'
import { renderRoutes } from 'react-router-config'

function getProjectIdFromUrl(str) {
    const regex = /\/[-a-z]{2}\/([0-9]+)\//gm;
    const match = regex.exec(str);
    if(match){
      return match[1] || ""
    }
    return ""
}

const CheckPermissionsRouting = ({store, fetchAction, location} ) => {

    const [state, setState] = useState(
        {
            isAppFetching: false,
            routes: [],
            userModules: [],
            location: undefined,
            alreadyLoad: false,
            projectId: undefined
        }
    )
    
    useEffect(() => {
        fetchRoutes(location)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location, location.search]);


    const fetchRoutes = (location) => {
        const {pathname, search} = location

        const atualLocation = `${pathname}${search}`
        const isToLoad = state.location !== atualLocation
        const projectId = getProjectIdFromUrl(pathname)

        setState((prevState) => {
            return{
                ...prevState,
                isAppFetching: true,
                location: atualLocation,
                projectId: projectId
            }
        })

        if( projectId && projectId !== state.projectId){
            if( isToLoad ){
                const queriesBody = {
                    query: `query($p:String!){project_modules(projectId:$p){modules}}`,
                    variables: {
                        p: `${projectId}`,
                    }
                }
                
                fetch('/graphql', {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify(queriesBody)
                })
                .then((res) => res.json().catch(() => ({})))
                .then((res) => {
                    if (res.errors && Array.isArray(res.errors) && res.errors.find((error) => error.message === 'Not Authenticated')) {
                        window.localStorage.clear()
                        return window.location.href = `/login`;
                    }
                    // * We need to resolve this error management
                    if (res.errors && Array.isArray(res.errors) && res.errors.find((error) => error.message === 'Not Found')) {
                        return window.location.href = `/`;
                    }
                    if (res.data) {
                        const modules = (res.data.project_modules || {modules: []}).modules
                        setState({ routes: filteredRoutes(modules), userModules: modules, isAppFetching: false })
                    }else{
                        setState({ routes: [...defaultRoutes], userModules: [], isAppFetching: false})
                    }
                })
                .catch(error => {
                    setState({ routes: [...defaultRoutes], userModules: [], isAppFetching: false})
                    return Promise.reject(Error(error.message))
                })
            }
        }else{
            setState({ routes: [...defaultRoutes], userModules: [], isAppFetching: false})
        }
    }

    return (
        <DataLoader routes={state.routes} dispatch={store.dispatch} fetch={fetchAction} modules={state.userModules} location>
                {
                (reload) => (
                    <>
                        <Icons key={'icons'} />
                        {renderRoutes(state.routes, { reload })}
                    </>
                )
            }
        </DataLoader>
    );
}

export default withRouter(CheckPermissionsRouting)