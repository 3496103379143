import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import I18n from "../../I18n";
import { logout, setLocale } from "../../store/actions";
import styles from "./NotFound.module.sass";
import { ActionButton } from "colbi_web_ui/lib/components/inputs";
import mrcolbi from "../../assets/mrcolbi.png";
import { decryptSession } from 'colbi_web_ui/lib/utils/roleValidator'

const NotFound = (props) => {
	const { user, match, history } = props;

	const locale = match.params.locale || "en";
	const i18n = I18n.use(locale);
	const handleClick = (history) => {
		history.goBack();
	};

	const handleClickGoHome = () => {
		history.push(`/${locale}/`);
	};

	let userInformation = decryptSession(user)

	if (!userInformation) {
		return <Redirect to={"/login"} push />;
	}

	if (userInformation && parseInt(userInformation.mysqlPasswordReset)) {
		return <Redirect to={`/${locale}/reset_password`} />;
	}

	return (
		<div className={styles["notfound"]}>
			<img src={mrcolbi} alt="MrColbi" />
			<div className={styles["notfound-description"]}>
				<h2>{i18n`not_found`}</h2>
				<h1 className={styles["notfound-404"]}>404</h1>
				<p>{i18n`not_found_description`} </p>
				<ActionButton
					primary
					className={styles["action-button"]}
					onClick={() => handleClick(history)}
				>
					{i18n`not_found_redirect`}
				</ActionButton>
				<ActionButton
					primary
					className={styles["go-home"]}
					onClick={() => handleClickGoHome()}
				>
					{i18n`Home`}
				</ActionButton>
			</div>
		</div>
	);
};
export default connect(
	({ user, fetch }) => ({
		user,
		fetch,
	}),
	{
		logout,
		setLocale,
	}
)(NotFound);
