import { date, datetime, format, getValueFromJson } from "../../utils";
import I18n from "../../I18n";

const formatValueByType = (json, value, type, options, defaultValue) => {
	let formatedValue = ""
	switch (type) {
		case "date":
			formatedValue = date(value);
			break;
		case "currency":
			formatedValue = format(value, 'normal', getValueFromJson(options, 'currency'), getValueFromJson(options, 'fractionDigits'), getValueFromJson(options, 'locale'));
			break;
		case "number":
			formatedValue = format(
				value,
				'normal',
				null,
				getValueFromJson(options, 'fractionDigits')
			);
			break;
		case "percentage":
			formatedValue = format(value, "normal", null, getValueFromJson(options, 'fractionDigits'));
			break;
		case "datetime":
			formatedValue = datetime(value);
			break;
		case "object":
			const keyValue = (json[getValueFromJson(options, "id")] || {}).value

			const objToReturn = keyValue ?
				{
					id: (json[getValueFromJson(options, "id")] || {}).value,
					name: (json[getValueFromJson(options, "name")] || {}).value,
					__typename: getValueFromJson(options, "__typename")
				} : ((json[getValueFromJson(options, "name")] || {}).value || defaultValue);

			return { type: keyValue ? type : "string", value: objToReturn }
		case "boolean":
			formatedValue = !!value === true ? I18n.translate(`Yes`) : I18n.translate(`No`)
			break;
		default:
			formatedValue = value || defaultValue;
			break;
	}

	return { type, value: formatedValue }
};

const parseRowData = (json, column) => {
	const { value, metadata, defaultValue } = json[column]
	const { label, options, type, ...restMetadata } = metadata || {}

	return {
		labels: label || column,
		...formatValueByType(json, value, type, options, defaultValue),
		...restMetadata
	}
};

const groupFieldsData = (data) => {
	return (data || []).filter(elem => elem.HideField === 0).reduce((acc, elem) => {
		const unitCampista = parseInt(elem.CampistaOrder)

		if (acc[unitCampista]) {
			acc[unitCampista].push(elem)
		} else {
			acc[unitCampista] = [elem]
		}
		return acc;
	}, {});
}

const createGenericDetailView = (groupByColumn, groupedData) => {
	return Object.keys(groupedData).map((key) => {
		let collapseFieldName = undefined
		const groupedRowFields = (groupedData[key] || []).map(elem => {
			const { value, metadata, defaultValue, column, CollapseFieldName, CampistaOrder } = elem
			collapseFieldName = !collapseFieldName && CollapseFieldName ? CollapseFieldName : collapseFieldName
			const { label, options, type, ...restMetadata } = metadata || {}
			return {
				label: label || column,
				...formatValueByType(groupByColumn, value, type, options, defaultValue),
				...restMetadata,
				CampistaOrder
			}
		})

		if (collapseFieldName) {
			return {
				title: `${collapseFieldName}`,
				rows: [groupedRowFields]
			}
		} else {
			return groupedRowFields
		}

	});
}

const mapCard = (json) => {
	return Object.keys(json).map((key) => {
		return json[key].hideField === 0 ? [parseRowData(json, key)] : null;
	});
};

const header = (json) => {
	return json.map((elem) => {
		const { SortableField, Metadata, CDMName, TotalizedField } = elem
		const { label, ...rest } = JSON.parse(Metadata) || {};

		return { label: [label ? label : CDMName], sortable: SortableField || 0, columnName: CDMName, ...rest, totalizerName: TotalizedField ? `Total_${CDMName.replace(".", "_")}` : "none" };
	});
};

const mapDetails = (json) => {

	const groupedFieldsToShow = groupFieldsData(json.details)

	const groupByColumn = (json.details || []).reduce((acc, it) => {
		const { column, ...rest } = it
		acc[column] = {
			column,
			...rest
		}
		return acc;
	}, {});

	return createGenericDetailView(groupByColumn, groupedFieldsToShow)
};

const mapReports = (reports) => {
	const resReports = ((reports || []).map(report => {
		const { title, tabs, ...rest } = report
		return {
			...rest,
			title: I18n.translate(title || ""),
			tabs: (tabs || []).map(title => I18n.translate(title || ""))
		}
	}))
	return resReports && resReports.length ? resReports : null
}

const mapper = (json, card, details, locale, entity) => {
	return {
		id: json[`${entity}.ID`] ? json[`${entity}.ID`].value : null,
		...json,
		shortName: details ? ((json.details || []).find(elem => elem.column === `${json.cdmEntity}.ShortName`) || {}).value : null,
		card: card ? mapCard(json) : [],
		details: details ? mapDetails(json) : [],
		reports: mapReports(json.reports || [])
	};
};

const genericviewMapper = {
	map: mapper,
	header
};

export default genericviewMapper
