import { format } from "../../utils"
import I18n from '../../I18n'

const mapCard = (json) => {
	const {
		d01,
		l01,
		f07,
		f08,
		n1f07,
		n1f08,
		n2f07,
		n2f08,
		n3f07,
		n3f08
	} = json

	return [
		[
			{ labels: ['taxonomy'], value: `${d01} - ${I18n.translate`${l01}`}`, type: 'string', basis: '33%' }

		],
		[
			{ labels: ['debit_balance'], value: format(f08 || 0), type: 'currency', basis: '10%' }

		],
		[
			{ labels: ['credit_balance'], value: format(f07 || 0), type: 'currency', basis: '10%' }

		],
		[
			{ labels: ['n1f07'], value: format(n1f08 || 0), type: 'currency', basis: '10%' }

		],
		[
			{ labels: ['n1f08'], value: format(n1f07 || 0), type: 'currency', basis: '10%' }

		],
		[
			{ labels: ['n2f07'], value: format(n2f08 || 0), type: 'currency', basis: '10%' }

		],
		[
			{ labels: ['n2f08'], value: format(n2f07 || 0), type: 'currency', basis: '10%' }

		],
		[
			{ labels: ['n3f07'], value: format(n3f08 || 0), type: 'currency', basis: '10%' }

		],
		[
			{ labels: ['n3f08'], value: format(n3f07 || 0), type: 'currency', basis: '10%' }

		]
	]
}

const header = [
	{ label: ['Taxonomy'], align: 'left', basis: '33%' },
	{ label: ['Current - Debit'], align: 'right', basis: '10%' },
	{ label: ['Current - Credit'], align: 'right', basis: '10%' },
	{ label: ['N-1 Debit'], align: 'right', basis: '10%' },
	{ label: ['N-1 Credit'], align: 'right', basis: '10%' },
	{ label: ['N-2 Debit'], align: 'right', basis: '10%' },
	{ label: ['N-2 Credit'], align: 'right', basis: '10%' },
	{ label: ['N-3 Debit'], align: 'right', basis: '10%' },
	{ label: ['N-3 Credit'], align: 'right', basis: '10%' }
]

const mapper = (json, card) => {
	return {
		...json,
		id: json.d01,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: {
			to: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/reports/trial_balance_annual',
			appendFilters: ['a01'],
			filter: [{ "filters": json.accountId.map(elem => ({ "value": elem })), "column": "d01" }, { "filters": [{ "value": json.a02 }], "column": "a02" }]
		}
	}
}

const trialbalancetaxonomyannualMapper = {
	map: mapper,
	header
};

export default trialbalancetaxonomyannualMapper