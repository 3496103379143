import I18n from '../../I18n'
import { toName } from 'colbi_web_ui/lib/utils'

const header = [
    { label: ["Message"], align: 'left' },
    { label: ["Reference"], align: "center" },
    { label: ["Verified"], align: 'center' }
]

const mapCard = (json) => {
    const {
        message, referenceType, referenceId, verified
    } = json

    const type = referenceType.split('::').pop()

    const referenceName = toName(type)

    return [
        [
            { labels: ['message'], value: message || 'N/A', type: 'string' },
        ],
        [
            { labels: ['reference'], value: { id: referenceId, name: referenceName, __typename: type }, type: 'object', align: "center" }
        ],
        [
            { labels: ['verified'], value: [verified ? 'valid' : 'invalid', verified ? I18n.translate`Verified` : I18n.translate`Not Verified`], type: 'audit' }
        ]
    ]
}

const mapper = (json, card) => {
    const name = json.message

    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
        name,
        shortName: name,
        card: card ? mapCard(json) : [],
        link: {
            to: json.type && json.type !== 'RulesFinding' ? `/:locale(en|lt|pt)?/:projectId/:importId/:modulePrefix/audit_findings/${json.id}` : `/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/audit/rules/:id/finding/${json.id}`
        }
    }
}

const findinglinesMapper = {
    map: mapper,
    header
};

export default findinglinesMapper