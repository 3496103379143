import * as components from 'colbi_web_ui/lib/components';

export function changeDeepObject(obj, argsToChange) {
    for (let key in obj) {
        if (typeof obj[key] === "object") {
            //go deep in obj with N deep objects
            changeDeepObject(obj[key], argsToChange)
        }

        if (argsToChange.includes(key)) {
            //manipulate obj 
            switch (typeof obj[key]) {
                case "object":
                    if (Array.isArray(obj[key])){
                        obj[key] = obj[key].map( (elem) => extratedValues(elem, components))
                    }
                    break;
                case "string":
                    obj[key] = extratedValues(obj[key], components)
                    break;
                default:
                    break;
            }
            
        }
    }
}

function extratedValues(valueToChange, evaluatedData) {
    const match = /\((.*)\)/gm.exec(valueToChange);

    if (match && match[1]) {
        return valueToChange.replace(match[1], deepValue(evaluatedData, match[1]))
    } else {
        return deepValue(evaluatedData, valueToChange)
    }
}

function deepValue(obj, path) {
    if (!path)
        return undefined
    return path.split('.').reduce((a, v) =>{
		return a ? a[v] : undefined
	}, obj)
}