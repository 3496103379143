import {
	Grid,
	cards,
	Widget,
	charts,
	AuditSummaryWidget,
} from "colbi_web_ui/lib/components";
import AppByFile from "../controllers/AppByFile/AppByFile";

const queries = (args = {}) => {
	return args.fiscalYear
		? [
				{
					resource: "generic_overview_reports",
					args: {
						projectId: args.projectId,
						fiscalYear: args.fiscalYear,
						importId: args.importId,
						modulePrefix: args.modulePrefix,
						entities: "transactions,work_documents,payments"
					},
					body: "report, setClose",
				},
				{
					resource: "nc_audit_summary",
					args: {
						projectId: args.projectId,
						fiscalYear: args.fiscalYear,
						importId: args.importId,
						modulePrefix: args.modulePrefix,
					},
					body: "severity, count",
				},
		  ]
		: [];
};

const overviewByFile = (
	path,
	navigation,
	exact,
	title,
	navByImportId = true
) => ({
	path,
	navigation,
	component: AppByFile,
	queries,
	exact,
	navByImportId,
	title: [title],
	components: [
		{
			component: AuditSummaryWidget,
			modifiers: ["--root-margin-bottom-normal"],
			props: {
				icon: "audit-icon",
				title: "label(Audit Results)",
				summary: "query(1).list",
				link: {
					to: `audit`,
					name: "label(See All)",
				},
				overviewSummaryWidget: true
			},
		},
		{
			component: Widget,
			modifiers: ['--root-margin-bottom-normal'],
			props: {
				icon: 'sales',
				title: 'label(Transaction per Type)',
				summary: [
					{
						name: 'label(No. Transactions)',
						value: 'query(0).report.0.TransactionsTotal'
					},
					{
						name: 'label(No. Transactions Lines)',
						value: 'query(0).report.0.TransactionLinesTotal'
					}
				],
				link: {
					to: 'transactions',
					name: 'label(Transactions)'
				},
				children: [
					{
						component: Grid,
						modifiers: ['--grid-border-inline'],
						props: {
							columnsTemplate: [1],
							aspect: ['31x10'],
							items: [
								{
									data: "query(0).report.1.0",
									labelsPosition: 'inside',
									stacked: true,
									summarized: true,
									links: [{
										to: ['param(projectId)', 'param(fiscalYear)', 'param(importId)', "param(modulePrefix)", '/transactions'],
										filterColumn: 'Period',
									}],
									modifiers: ['--top-text-small'],
								},
							],
							cards: [charts.BarChart]
						}
					},
					
				]
			}
		},
		{
			component: Grid,
			modifiers: ["--grid-padding-none", "--root-margin-bottom-normal"],
			props: {
				columnsTemplate: [1, 1],
				items: [
					{
						icon: "sales",
						title: "label(Work Documents per Type)",
						summary: [
							{
								name: "label(No. Work Documents)",
								value: "query(0).report.0.WorkDocumentsTotal",
							},
							{
								name: "label(No. Work Documents Lines)",
								value: "query(0).report.0.WorkDocumentLinesTotal",
							},
						],
						link: {
							to: "work_documents",
							name: "label(Work Documents)",
						},
						children: [
							{
								component: Grid,
								modifiers: ["--grid-border-inline"],
								props: {
									columnsTemplate: [1],
									aspect: ["21x10"],
									items: [
										{
											data: "query(0).report.1.1",
											labelsPosition: "top",
											stacked: true,
											summarized: true,
											links: [
												{
													to: [
														"param(projectId)",
														"param(fiscalYear)",
														"param(importId)",
														"param(modulePrefix)",
														"/work_documents",
													],
													filterColumn: "Period",
												},
											],
											modifiers: ['--top-text-small'],
										},
									],
									cards: [charts.BarChart],
								},
							},
						],
					},
					{
						icon: "sales",
						title: "label(Payments per Type)",
						summary: [
							{
								name: "label(No. Payments)",
								value: "query(0).report.0.PaymentsTotal",
							},
							{
								name: "label(No. Payments Lines)",
								value: "query(0).report.0.PaymentLinesTotal",
							},
						],
						link: {
							to: "payments",
							name: "label(Payments)",
						},
						children: [
							{
								component: Grid,
								modifiers: ["--grid-border-inline"],
								props: {
									columnsTemplate: [1],
									aspect: ["21x10"],
									items: [
										{
											data: "query(0).report.1.2",
											labelsPosition: "top",
											stacked: true,
											summarized: true,
											links: [
												{
													to: [
														"param(projectId)",
														"param(fiscalYear)",
														"param(importId)",
														"param(modulePrefix)",
														"/payments",
													],
													filterColumn: "Period",
												},
											],
											modifiers: ['--top-text-small'],
										},
									],
									cards: [charts.BarChart],
								},
							},
						],
					},
				],
				cards: [Widget],
			},
		},
		{
			component: Grid,
			modifiers: [
				"--root-margin-bottom-normal",
				"--root-background-color-widget-background-color",
			],
			props: {
				columnsTemplate: [1, 1, 1, 1],
				items: [
					{
						title: "label(Customers/Suppliers)",
						items: [
							{
								name: "label(Customers)",
								count: "number(query(0).report.0.customersTotal)",
								link: `${path}/master_data/customers`,
							},
							{
								name: "label(Customers Countries)",
								count: "number(query(0).report.0.CustomersCountryTotal)",
							},
							{
								name: "label(Suppliers)",
								count: "number(query(0).report.0.suppliersTotal)",
								link: `${path}/master_data/suppliers`,
							},
							{
								name: "label(Suppliers Countries)",
								count: "number(query(0).report.0.SuppliersCountryTotal)",
							},
						],
					},
					{
						title: "label(Products)",
						items: [
							{
								name: "label(Products)",
								count: "number(query(0).report.0.productsTotal)",
								link: `${path}/master_data/products`,
							},
							{
								name: "label(Products Group)",
								count: "number(query(0).report.0.ProductsGroupTotal)",
							},
						],
					},
					{
						title: "label(Transactions)",
						items: [
							{
								name: "label(Customers on Transactions)",
								count: "number(query(0).report.0.CustomersTransactionTotal)",
							},
							{
								name: "label(GL Transactions)",
								count: "number(query(0).report.0.TransactionsTotal)",
								link: `${path}/transactions`,
							},
							{
								name: "label(GL Transaction Lines)",
								count: "number(query(0).report.0.TransactionLinesTotal)",
							},
						],
					},
					{
						title: "label(Payments)",
						items: [
							{
								name: "label(Payments)",
								count: "number(query(0).report.0.PaymentsTotal)",
								link: `${path}/payments`,
							},
							{
								name: "label(Payment Lines)",
								count: "number(query(0).report.0.PaymentLinesTotal)",
							},
						],
					},
				],
				cards: [cards.LinksCard],
			},
		},
	],
});

export default overviewByFile;
