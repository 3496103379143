import React, { forwardRef, useState, useRef, useImperativeHandle } from 'react'
import { useGlobalValue } from 'colbi_web_ui/lib/state/GlobalProvider'
import { Dropdown, TextInput, TextArea, MultiSelectionInput, Checkbox } from 'colbi_web_ui/lib/components/inputs'
import Icon from 'colbi_web_ui/lib/components/Icon/Icon'
import { FORM_ACTIONS } from '../FormActions'
import {allowedRole} from 'colbi_web_ui/lib/utils/roleValidator'
import CheckPermission from 'colbi_web_ui/lib/components/CheckPermission/CheckPermission'
import styles from './RuleForm.module.sass'
import { UserRoles } from '../../../enums/userRoles'

const RuleForm = (props, ref) => {
	const { data, classifiers, disableForm, status, onChange, onSave } = props
	const { i18n, user } = useGlobalValue()
	const {
		adapter,
		appliesTo,
		appliesToDataType,
		appliesToVersion,
		category,
		level,
		referenceType,
		severity,
		packages
	} = classifiers || {}

	const {
		adapter: ruleAdapter,
		appliesTo: ruleAppliesTo,
		appliesToDataTypes,
		appliesToVersion: ruleAppliesToVersion,
		content,
		dependencies,
		description,
		insuccessMessage,
		level: ruleLevel,
		referenceType: ruleReferenceType,
		ruleId,
		ruleType,
		severity: ruleSeverity,
		successMessage,
		title,
		packages: rulePackages,
		userDefined = 1,
		ruleCategory = '',
		ruleSubgroup = '' 
	} = data || {}

	const disableInputs = allowedRole(user, [UserRoles.OWNER]) && disableForm && userDefined === 0 ? true : false

	const tabs = ['Headers', 'Commands']

	const formRef = useRef()
	const [selected, setSelected] = useState(0)

	useImperativeHandle(ref, () => ({
		submit: () => {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
		}
	}))

	const handleChange = (e, prop, value) => {
		onChange && onChange({
			originalEvent: e,
			target: {
				value: {
					data: {
						...data,
						[prop]: value
					}
				}
			}
		})
	}

	const dependenciesFormat = JSON.parse(dependencies || '[]')
	const dependenciesItems = (Array.isArray(dependenciesFormat) ? dependenciesFormat : []).map((dependencie, index) => {
		return (
			<div key={index} className={styles['dependencie-entry']}>
				<div>
					<TextInput
						className={styles['input']}
						value={dependencie || ''}
						placeholder={i18n`Enter a dependency`}
						onChange={(e) => {
							const nextDependencies = dependenciesFormat
							nextDependencies.splice(index, 1, e.target.value)
							handleChange(e, disableInputs ? 'disabled' : 'dependencies', JSON.stringify(nextDependencies))
						}}
					/>
				</div>
				<div>
					<button
						type="button"
						className={styles['remove-button']}
						onClick={(e) => {
							const nextDependencies = dependenciesFormat
							nextDependencies.splice(index, 1)
							handleChange(e, disableInputs ? 'disabled' : 'dependencies', JSON.stringify(nextDependencies))
						}}
					>
						<Icon name="remove" />
					</button>
				</div>
			</div>
		)
	})


	const contentFormat = JSON.parse(content || '{}').commands
	const contentItems = (Array.isArray(contentFormat) ? contentFormat : []).map((content, index) => {
		return (
			<div key={index} className={styles['content-entry']}>
				<div>
					<TextArea
						className={styles['input']}
						value={content.command || ''}
						name={i18n`Content`}
						placeholder={i18n`Enter a content`}
						required
						onChange={(e) => {
							const nextDependencies = contentFormat
							nextDependencies.splice(index, 1, { command: e.target.value })
							handleChange(e, disableInputs ? 'disabled' : 'content', JSON.stringify({ commands: nextDependencies }))
						}}
					/>
				</div>
				<div>
					<button
						type="button"
						className={styles['remove-button']}
						onClick={(e) => {
							const nextDependencies = contentFormat
							nextDependencies.splice(index, 1)
							handleChange(e, disableInputs ? 'disabled' : 'content', JSON.stringify({ commands: nextDependencies }))
						}}
					>
						<Icon name="remove" />
					</button>
				</div>
			</div>
		)
	})





	return status !== 'loading' ? (
		<form ref={formRef} onSubmit={(e) => {
			e.stopPropagation()
			e.preventDefault()
			if (userDefined === 0 && !(allowedRole(user, [UserRoles.ADMIN, UserRoles.BACKOFFICE]))) {
				data['content'] = JSON.stringify({ commands: [...(Array.isArray(contentFormat) ? contentFormat : [])] })
			}
			if (formRef.current.checkValidity()) {
				onSave && onSave()
			} else {
				formRef.current.reportValidity()
			}
		}}>
			<div className={styles['tabs']}>
				{(tabs || []).map((tabName, index) => (
					<div
						key={index}
						className={`${styles['tab']} ${selected === index ? styles['is-selected'] : ''}`}
						onClick={() => { setSelected(index) }}
					>
						<small>{i18n`${tabName}`}</small>
					</div>
				))}
			</div>
			<div className={`${styles['inputs']} ${styles[`visible-${selected}`]}`}>
				<div className={styles['headers']}>
					<div className={styles['input-field']}>
						<TextInput
							className={styles['input']}
							name={i18n`Identificador`}
							required
							value={ruleId || ''}
							placeholder={i18n`Enter an identificator`}
							onChange={(e) => {
								handleChange(e, disableInputs ? 'disabled' : 'ruleId', e.target.value)
							}}
						/>
					</div>
					<div className={styles['input-field']}>
						<h5 className={styles['label']}>{i18n`Category`}</h5>
						<Dropdown
							value={ruleType && category.length ? category.find(({ id }) => id === ruleType) || category[0] : null}
							placeholder={''}
							options={category || []}
							className={styles['dropdown']}
							onChange={(e) => {
								handleChange(e, disableInputs ? 'disabled' : 'ruleType', e.target.value.id)
							}}
						/>
					</div>
					<div className={styles['input-field']}>
						<h5 className={styles['label']}>{i18n`Severity`}</h5>
						<Dropdown
							value={(ruleSeverity || ruleSeverity === 0) && severity.toString().length ? severity.find(({ id }) => parseInt(id) === ruleSeverity) || severity[0] : null}
							options={severity || []}
							placeholder={''}
							className={styles['dropdown']}
							onChange={(e) => {
								handleChange(e, disableInputs ? 'disabled' : 'severity', parseInt(e.target.value.id))
							}}
						/>
					</div>
					<div className={styles['group-content']}>
						<div className={styles['flex']}>
							<div className={styles['input-field']}>
								<h5 className={styles['label']}>{i18n`Adapter`}</h5>
								<Dropdown
									placeholder={''}
									value={ruleAdapter && adapter.length ? adapter.find(({ id }) => id === ruleAdapter) || adapter[0] : null}
									options={adapter || []}
									className={styles['dropdown']}
									onChange={(e) => {
										handleChange(e, disableInputs ? 'disabled' : 'adapter', e.target.value.id)
									}}
								/>
							</div>
							<div className={styles['input-field']}>
								<h5 className={styles['label']}>{i18n`Level`}</h5>
								<Dropdown
									value={ruleLevel && level.length ? level.find(({ id }) => parseInt(id) === ruleLevel) : null}
									placeholder={''}
									options={level || []}
									className={styles['dropdown']}
									onChange={(e) => {
										handleChange(e, disableInputs ? 'disabled' : 'level', parseInt(e.target.value.id))
									}}
								/>
							</div>
						</div>
					</div>
					<div className={styles['input-field']}>
						<MultiSelectionInput
							className={styles['input']}
							name={i18n`Applies to Data Types`}
							value={(typeof appliesToDataTypes === 'string' ? JSON.parse(appliesToDataTypes || '[]') : appliesToDataTypes || []).map((type) => type).filter(p => p)}
							placeholder={i18n`Select applies to data types`}
							options={
								appliesToDataType && appliesToDataType.length
									? appliesToDataType.map(({ id, name }) => ({ id, name: name }))
									: []
							}
							onChange={(e) => {
								handleChange(e, disableInputs ? 'disabled' : 'appliesToDataTypes', JSON.stringify(e.target.value))
							}}
						/>
					</div>
					<div className={styles['input-field']}>
						<TextInput
							className={styles['input']}
							name={i18n`Group`}
							value={ruleCategory || ''}
							placeholder={i18n`Enter a group`}
							onChange={(e) => {
								handleChange(e, disableInputs ? 'disabled' : 'ruleCategory', e.target.value)
							}}
						/>
					</div>
					<div className={styles['input-field']}>
						<TextInput
							className={styles['input']}
							name={i18n`Subgroup`}
							value={ruleSubgroup || ''}
							placeholder={i18n`Enter a SubGroup`}
							onChange={(e) => {
								handleChange(e, disableInputs ? 'disabled' : 'ruleSubgroup', e.target.value)
							}}
						/>
					</div> 
					<div className={styles['input-field']}>
						<TextInput
							className={styles['input']}
							name={i18n`Title`}
							required
							value={title || ''}
							placeholder={i18n`Enter a title`}
							onChange={(e) => {
								handleChange(e, disableInputs ? 'disabled' : 'title', e.target.value)
							}}
						/>
					</div>
					<div className={styles['input-field']}>
						<TextInput
							className={styles['input']}
							name={i18n`Description`}
							required
							value={description || ''}
							placeholder={i18n`Enter a description`}
							onChange={(e) => {
								handleChange(e, disableInputs ? 'disabled' : 'description', e.target.value)
							}}
						/>
					</div>
					<div className={styles['input-field']}>
						<MultiSelectionInput
							className={styles['input']}
							name={i18n`Package`}
							value={(rulePackages || []).map((type) => type).filter(p => p)}
							placeholder={i18n`Select package`}
							options={
								packages && packages.length
									? packages.map(({ id, name }) => ({ id, name: name }))
									: []}
							onChange={(e) => {
								handleChange(e, disableInputs ? 'disabled' : 'packages', e.target.value)
							}}
						/>
					</div>
				</div>
				<div className={styles['commands']}>
					<div className={styles['group-content']}>
						<div className={styles['flex']}>
							<div className={styles['input-field']}>
								<h5 className={styles['label']}>{i18n`Applies To`}</h5>
								<Dropdown
									value={ruleAppliesTo && appliesTo.length ? appliesTo.find(({ id }) => id === ruleAppliesTo) || appliesTo[0] : null}
									options={appliesTo || []}
									placeholder={''}
									className={styles['dropdown']}
									onChange={(e) => {
										handleChange(e, disableInputs ? 'disabled' : 'appliesTo', e.target.value.id)
									}}
								/>
							</div>
							<div className={styles['input-field']}>
								<h5 className={styles['label']}>{i18n`Reference Type`}</h5>
								<Dropdown
									value={ruleReferenceType && referenceType.length ? referenceType.find(({ id }) => id === ruleReferenceType) || referenceType[0] : null}
									options={referenceType || []}
									placeholder={''}
									className={styles['dropdown']}
									onChange={(e) => {
										handleChange(e, disableInputs ? 'disabled' : 'referenceType', e.target.value.id)
									}}
								/>
							</div>
						</div>
					</div>
					<div className={styles['input-field']}>
						<MultiSelectionInput
							className={styles['input']}
							name={i18n`Applies to Version`}
							value={(typeof ruleAppliesToVersion === 'string' ? JSON.parse(ruleAppliesToVersion || '[]') : ruleAppliesToVersion || []).map((type) => type).filter(p => p)}
							placeholder={i18n`Select applies to version`}
							options={
								appliesToVersion && appliesToVersion.length
									? appliesToVersion.map(({ id, name }) => ({ id, name: name }))
									: []}
							onChange={(e) => {
								handleChange(e, disableInputs ? 'disabled' : 'appliesToVersion', JSON.stringify(e.target.value))
							}}
						/>
					</div>
					<div className={styles['input-field']}>
						<h5 className={styles['label']}>{i18n`Dependencies`}</h5>
						<div>{dependenciesItems.length ? dependenciesItems : <small>{i18n`No dependencies assigned`}</small>}</div>
						<div>
							<button
								className={styles['add-button']}
								type="button"
								onClick={(e) => {
									handleChange(e, disableInputs ? 'disabled' : 'dependencies', JSON.stringify([...(Array.isArray(dependenciesFormat) ? dependenciesFormat : []), []]))
								}}
							><small>{i18n`[+] Add new dependencies`}</small></button>
						</div>
					</div>
					<div className={styles['input-field']}>
						<TextInput
							className={styles['input']}
							name={i18n`Success Message`}
							required
							value={successMessage || ''}
							placeholder={i18n`Enter a success message`}
							onChange={(e) => {
								handleChange(e, disableInputs ? 'disabled' : 'successMessage', e.target.value)
							}}
						/>
					</div>
					<div className={styles['input-field']}>
						<TextInput
							className={styles['input']}
							name={i18n`Insuccess Message`}
							required
							value={insuccessMessage || ''}
							placeholder={i18n`Enter an insuccess message`}
							onChange={(e) => {
								handleChange(e, disableInputs ? 'disabled' : 'insuccessMessage', e.target.value)
							}}
						/>
					</div>
						<CheckPermission roles={[UserRoles.ADMIN, UserRoles.BACKOFFICE]}>
							<Checkbox
								style={{ marginTop: '10px' }}
								value={userDefined}
								onChange={(e) => {
									handleChange(e, disableInputs ? 'disabled' : 'userDefined', e.target.checked ? 1 : 0)
								}}
							>{i18n`User Rule`}</Checkbox>
						</CheckPermission>
					{userDefined === 1 &&
						<CheckPermission roles={[UserRoles.ADMIN, UserRoles.BACKOFFICE]}>
							<div className={styles['input-field']}>
								<h5 className={styles['label']}>{i18n`Content`}</h5>
								<div>{contentItems.length ? contentItems : <small>{i18n`No content assigned`}</small>}</div>
								<div>
									<button
										className={styles['add-button']}
										type="button"
										onClick={(e) => {
											handleChange(e, disableInputs ? 'disabled' : 'content', JSON.stringify({ commands: [...(Array.isArray(contentFormat) ? contentFormat : []), { command: '' }] }))
										}}
									><small>{i18n`[+] Add new content`}</small></button>
								</div>
							</div>
						</CheckPermission>
					}

				</div>
			</div>
		</form>
	) : null
}

const RuleFormRef = forwardRef(RuleForm)

RuleFormRef.queries = (args) => {
	return [
		{
			resource: 'disable_rule_form',
		},
		{
			resource: 'classifiers',
			body: `
				category{id, name}, 
				adapter{id, name},
				level{id, name},
				severity{id, name},
				appliesToDataType{id, name},
				appliesTo{id, name},
				referenceType{id, name},
				appliesToVersion{id, name},
				packages{id, name},
			`
		},
		args && args.id ? {
			resource: 'rule',
			args: { ruleId: args.id },
			body: '__typename,id,ruleId,ruleType,title,appliesTo,description,severity,adapter,level,referenceType,successMessage,insuccessMessage,content,appliesToDataTypes,appliesToVersion,dependencies,packages,userDefined,ruleCategory,ruleSubgroup'
		} : undefined
	].filter(q => q)
}

RuleFormRef.dataMapper = ['disableForm', 'classifiers', 'data']

RuleFormRef.actions = [FORM_ACTIONS.SAVE]

export default RuleFormRef
