import React, { forwardRef, useRef, useImperativeHandle, useState, useEffect } from 'react'
import { TextInput, Dropdown, Checkbox, MultiSelectionInput, DatePicker } from 'colbi_web_ui/lib/components/inputs'
import { useGlobalValue } from 'colbi_web_ui/lib/state/GlobalProvider'
import { FORM_ACTIONS } from '../FormActions'

import styles from './ProjectForm.module.sass'


const ProjectForm = (props, ref) => {
	const { data, organizations, status, onChange, onSave } = props
	const formRef = useRef()

	const {
		description,
		taxRegistrationNumber,
		organizationId,
		active = false,
		projectModule,
		projectExpirationDate : expirationDate
	} = data || {}

	const { i18n } = useGlobalValue()

	const [modulesByOrganization, setModulesByOrganization] = useState([]); 
	const [expirationDateProject, setExpirationDateProject] = useState( expirationDate ? new Date(expirationDate) : '');

	useEffect(() => {
		setExpirationDateProject(expirationDate ? new Date(expirationDate) : '')
	}, [expirationDate])

	useEffect(() => {
		const modules = (organizations || []).find(({ id }) => id === organizationId);
		if(modules){
			setModulesByOrganization([...(modules.organizationsModules || [] )]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organizationId]);

	const handleChange = (e, prop, value) => {
		onChange && onChange({
			originalEvent: e,
			target: {
				value: {
					data: {
						...data,
						[prop]: value
					}
				}
			}
		})
	}

	const handleOrganizationChange = (e, prop, value) => {
		onChange && onChange({
		  originalEvent: e,
		  target: {
			value: {
			  data: { ...data,
				[prop]: value,
				"projectModule": '[]'
			  }
			}
		  }
		});
	  }; 

	useImperativeHandle(ref, () => ({
		submit: () => {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
		}
	}))

	return status !== 'loading' ? (
		<form ref={formRef} onSubmit={(e) => {
			e.preventDefault()
			e.stopPropagation()
			if (formRef.current.checkValidity()) {
				data["projectExpirationDate"] = expirationDateProject
				onSave && onSave()
			} else {
				formRef.current.reportValidity()
			}
		}}>
			<div className={styles['group-content']}>
				<div className={styles['flex']}>
					<TextInput
						className={styles['input']}
						name={i18n`Tax Registration Number`}
						placeholder={i18n`Enter a number`}
						value={taxRegistrationNumber || ''}
						required
						onChange={(e) => {
							handleChange(e, 'taxRegistrationNumber', e.target.value)
						}}
					/>
					<TextInput
						className={styles['input']}
						name={i18n`Description`}
						placeholder={i18n`Enter a description`}
						value={description || ''}
						required
						onChange={(e) => {
							handleChange(e, 'description', e.target.value)
						}}
					/>
				</div>
			</div>
			<div className={styles['input-field']}>
				<h5 className={styles['label']}>{i18n`Organization`}</h5>
				<Dropdown
					value={organizationId && organizations ? organizations.find(({ id }) => id === organizationId) || organizations[0] : null}
					options={organizations || []}
					className={styles['dropdown']}
					placeholder={i18n`-- Select an organization --`}
					required
					onChange={(e) => {
						handleOrganizationChange(e, 'organizationId', e.target.value.id);
					}}
				/>
			</div>
			<div className={styles['input-field']}>
				<MultiSelectionInput
					className={styles['input']}
					name={i18n`Modules to Current Project`}
					value={(typeof projectModule === 'string' ? JSON.parse(projectModule || '[]') : projectModule || []).map((type) => type).filter(p => p)}
					placeholder={i18n`Select modules to active`}
					options={
						modulesByOrganization && modulesByOrganization.length
							? modulesByOrganization.map(({ id, name }) => ({ id, name: name }))
							: []
					}
					onChange={(e) => {
						handleChange(e, 'projectModule', JSON.stringify(e.target.value))
					}}
				/>
				<DatePicker
					className={`${styles['wrap-datepicker']} ${styles['input']}`}
					name={i18n`Expiration Date`}
					value={expirationDateProject ? expirationDateProject : ''}
					onChange={(e) => {
						setExpirationDateProject(e)
					}}
					dateFormat="dd-MM-yyyy"
				/>
			</div>
			<Checkbox
				style={{ marginTop: '10px' }}
				value={active}
				onChange={(e) => {
					handleChange(e, 'active', e.target.checked ? 1 : 0)
				}}
			>{i18n`Active`}</Checkbox>
		</form>
	) : null
}

const ProjectFormRef = forwardRef(ProjectForm)

ProjectFormRef.queries = (args) => {
	return [
		{
			resource: 'user_organizations',
			body: 'id,name,organizationsModules{id, name}'
		},
		args && args.id ? {
			resource: 'project',
			args: { 'targetProjectId': args.id },
			body: '__typename,id,description,taxRegistrationNumber,organizationId,active,projectModule,projectExpirationDate'
		} : undefined,
	].filter(q => q)
}

ProjectFormRef.dataMapper = ['organizations' ,'data']

ProjectFormRef.actions = [FORM_ACTIONS.SAVE]

export default ProjectFormRef