import { StackLayout } from "../layouts";
import {
	List,
	headers,
	Tabs,
	Filters,
	DynamicReportsGrid,
	AuditSummaryWidget,
	rows,
	RawData,
	SectionTitle,
} from "colbi_web_ui/lib/components";
import { parseFilter } from "colbi_web_ui/lib/utils";
import theme from "colbi_web_ui/lib/style/_theme.json";
import FindingButtons from "../components/FindingButtons/FindingButtons";
import AppByFile from "../controllers/AppByFile/AppByFile";
import ExportButton from "../components/ExportButton/ExportButton";
import { checkForCustomFragment, camelizeResource, getEntityNameResource } from "../utils/queryUtils";

const queries = (args = {}) => {
	const entities = (args.entities || "").split("/");
	const entityPairs = (entities || []).reduce((pairs, current) => {
		if (!pairs[pairs.length - 1] || pairs[pairs.length - 1].length === 2) {
			pairs.push([]);
		}
		if (pairs[pairs.length - 1]) {
			pairs[pairs.length - 1].push(current);
		}
		return pairs;
	}, []);
	const entityIndex =
		entities.length % 2 === 0 ? entityPairs.length - 1 : entityPairs.length - 2;
	const entityType = (entityPairs[entityIndex] || [])[0];
	const entityId = (entityPairs[entityIndex] || [])[1];
	const subEntityIndex =
		entities.length % 2 === 0 ? -1 : entityPairs.length - 1;
	const subEntityType = (entityPairs[subEntityIndex] || [])[0];

	const entity = getEntityNameResource(entityType);
	const subEntityTypeWithPrefix =
		args.modulePrefix && subEntityType
			? `${args.modulePrefix}_${subEntityType}`
			: subEntityType;
	const entityWithPrefix =
		args.modulePrefix && entity ? `${args.modulePrefix}_${entity}` : entity;

	const customEntityFragment = checkForCustomFragment(
		args.modulePrefix,
		entityType
	);

	const result = [
		{
			resource: `nc_findings_for_record`,
			args: {
				projectId: args.projectId,
				fiscalYear: args.fiscalYear,
				importId: args.importId,
				referenceId: entityId,
				referenceType: entityType,
				modulePrefix: args.modulePrefix,
			},
			body: `__typename, items{ ...nc_findings_for_record }, summary {...nc_findings_summary}, hasMore`,
		},
		{
			resource: `adhoc_audit_notes_by_entity`,
			args: {
				projectId: args.projectId,
				fiscalYear: args.fiscalYear,
				importId: args.importId,
				referenceId: entityId,
				referenceType: entityType,
				modulePrefix: args.modulePrefix,
			},
			body: `__typename, items{...adhoc_audit_notes_by_entity}, hasMore`,
		},
	];

	if (customEntityFragment) {
		result.push(
			{
				resource: entityWithPrefix,
				args: {
					projectId: args.projectId,
					fiscalYear: args.fiscalYear,
					[`${camelizeResource(entity)}Id`]: entityId,
					modulePrefix: args.modulePrefix,
				},
				body: `...${entityWithPrefix}`,
			},
			{
				resource: subEntityTypeWithPrefix,
				args: {
					projectId: args.projectId,
					fiscalYear: args.fiscalYear,
					modulePrefix: args.modulePrefix,
					filter: args.filter
						? { type: "[FilterInput!]", value: parseFilter(args.filter) }
						: undefined,
					sort: args.sort
						? { type: "[SortInput!]", value: parseFilter(args.sort) }
						: undefined,
					page: parseInt(args.page, 10) || 0,
					parentId: entityId,
					parentType: entityType
						? entityType.substring(0, entityType.length - 1)
						: null,
				},
				downloadable: true,
				xlsxTranslation: subEntityType,
				body: `__typename, items{...${subEntityTypeWithPrefix}}, hasMore, total, totalizers, filters(projectId:$projectId,fiscalYear:$fiscalYear,parentId:$parentId,parentType:$parentType, modulePrefix:$modulePrefix){ prop, name, type, list, disableOperators, mandatory }`,
			}
		);
	} else {
		result.push(
			{
				resource: "generic_view",
				args: {
					projectId: args.projectId,
					fiscalYear: args.fiscalYear,
					importId: args.importId,
					entity: entityType,
					entityId: entityId,
					modulePrefix: args.modulePrefix,
				},
				body: `__typename, details, entity, cdmEntity,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix,importId:$importId,entity:$entity,entityId:$entityId){ entity, count, model }, reports(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix,importId:$importId,entity:$entity,entityId:$entityId)`,
			},
			{
				resource: "generic_views",
				args: {
					projectId: args.projectId,
					fiscalYear: args.fiscalYear,
					importId: args.importId,
					modulePrefix: args.modulePrefix,
					filter: args.filter
						? { type: "[FilterInput!]", value: parseFilter(args.filter) }
						: undefined,
					sort: args.sort
						? { type: "[SortInput!]", value: parseFilter(args.sort) }
						: undefined,
					page: parseInt(args.page, 10) || 0,
					entity: entityType,
					entityId: entityId,
					subEntity: subEntityType ? subEntityType : "",
				},
				body: `__typename, items, headerItems, hasMore, total, totalizers, entity, filters(projectId:$projectId,fiscalYear:$fiscalYear,importId:$importId,entityId:$entityId,subEntity:$subEntity,modulePrefix:$modulePrefix){ prop, name, type, list, disableOperators, mandatory }`,
			},
			{
				resource: "generic_views_export",
				args: {
					projectId: args.projectId,
					fiscalYear: args.fiscalYear,
					importId: args.importId,
					modulePrefix: args.modulePrefix,
					entity: entityType,
					entityId: entityId,
					subEntity: subEntityType ? subEntityType : "",
					filter: args.filter
						? { type: "[FilterInput!]", value: parseFilter(args.filter) }
						: undefined,
					sort: args.sort
						? { type: "[SortInput!]", value: parseFilter(args.sort) }
						: undefined,
					page: parseInt(args.page, 10) || 0,
				},
				body: `__typename, items, headerItems hasMore, total, totalizers, entity, filters(projectId:$projectId,fiscalYear:$fiscalYear,importId:$importId,entityId:$entityId,subEntity:$subEntity,modulePrefix:$modulePrefix){ prop, name, type, list, disableOperators, mandatory }`,
				downloadable: true,
				downloadOnly: true,
				xlsxTranslation: args.entity,
			}
		);
	}

	return result.filter((q) => !!q.resource);
};

const entityByFile = (
	path,
	navigation,
	exact,
	title,
	navByImportId = false
) => ({
	path,
	navigation,
	component: AppByFile,
	queries,
	exact,
	navByImportId,
	title: [title, "label(query(2).entity)", "query(2).shortName"].filter(
		(l) => l
	),
	components: [
		{
			component: RawData,
			props: {
				label: "label(Raw Data)",
				data: "query(2).raw_data",
			},
		},
		{
			component: AuditSummaryWidget,
			modifiers: ["--root-margin-bottom-small"],
			props: {
				icon: "audit-icon",
				title: "label(Related Audit)",
				summary: "query(0).metadata.summary",
				expanded: false,
				children: [
					{
						component: SectionTitle,
						props: {
							title: "Rules Findings",
							modifiers: ["--margin-bottom-top-title"],
						},
					},
					{
						component: List,
						props: {
							basePath:
								"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/audit/rules",
							items: "query(0).list",
							card: rows.EntityCard,
							header: "query(0).header",
						},
					},
					{
						component: SectionTitle,
						props: {
							title: "Audit Notes",
							modifiers: ["--margin-bottom-top-title"],
						},
					},
					{
						component: List,
						props: {
							items: "query(1).list",
							card: rows.EntityCard,
							header: "query(1).header",
						},
					},
				],
			},
		},
		{
			component: FindingButtons,
			props: {
				projectId: "param(projectId)",
				fiscalYear: "param(fiscalYear)",
				importId: "param(importId)",
				modulePrefix: "param(modulePrefix)",
				ruleDescription: "label(New Audit Note)",
				origin: "A",
				entityName: "query(2).__typename",
				entityId: "query(2).id",
			},
		},
		{
			component: headers.EntityHeader,
			modifiers: ["--root-margin-bottom-normal"],
			props: {
				type: "query(2).__typename",
				data: "query(2).details",
			},
		},
		{
			component: DynamicReportsGrid,
			modifiers: [
				"--root-margin-bottom-normal",
				"--cardholder-background-color-widget",
				"--cardholder-aspect-ratio-16x9",
			],
			props: {
				type: "query(2).__typename",
				data: "query(2).reports",
			},
		},
		{
			component: Filters,
			props: {
				filter: "param(filter)",
				availableFilters: "query(3).filters",
				stickyTop: parseInt(theme.layout.$headerHeight, 10) + 40,
				anchor: "results",
			},
		},
		{
			component: Tabs,
			modifiers: ["--root-margin-top-small", "--root-margin-bottom-small"],
			props: {
				basePath: `${path}`,
				showCount: true,
				data: "query(2).entities",
				labelKey: "entity",
				routeKey: "model",
				redirectEnabler: true,
				stickyTop: Math.min(39, parseInt(theme.layout.$headerHeight, 10) || 39),
			},
		},
		{
			component: ExportButton,
			props: {
				data: "query(2).entities",
			},
		},
	],
	routes: [
		{
			path,
			exact: true,
			queries: queries,
			component: StackLayout,
			components: [
				{
					component: List,
					modifiers: ["--row-style-alternate"],
					props: {
						basePath: path,
						header: "query(3).header",
						sort: "param(sort)",
						items: "query(3).list",
						card: rows.EntityCard,
						handleNoResults: true,
						metadata: "query(3).metadata",
						page: "param(page)",
						status: "status(0)",
						pageSize: "param(pageSize)",
						pageDefault: 10,
						footer: "query(3).footer",
						totalizers: "query(3).totalizers",
					},
				},
			],
		},
	],
});

export default entityByFile;
