import AppByFile from "../controllers/AppByFile/AppByFile";
import { List, rows, Filters } from "colbi_web_ui/lib/components";
import { parseFilter } from "colbi_web_ui/lib/utils";
import theme from "colbi_web_ui/lib/style/_theme.json";
import UserActions from "../actions/UserActions/UserActions";
import ExportButton from "../components/ExportButton/ExportButton";

const queries = (args = {}) => {
	const entityResource = args.modulePrefix
		? `${args.modulePrefix}_${args.entity}`
		: args.entity;
	return [
		{
			resource: entityResource,
			args: {
				projectId: args.projectId,
				fiscalYear: args.fiscalYear,
				modulePrefix: args.modulePrefix,
				filter: args.filter
					? { type: "[FilterInput!]", value: parseFilter(args.filter) }
					: undefined,
				sort: args.sort
					? { type: "[SortInput!]", value: parseFilter(args.sort) }
					: undefined,
				page: parseInt(args.page, 10) || 0,
			},
			body: `__typename, items{ ...${entityResource} }, hasMore, total, totalizers,filters(projectId:$projectId,fiscalYear:$fiscalYear){ prop, name, type, list }`,
			downloadable: true,
			xlsxTranslation: entityResource,
		},
	].filter((r) => !!r.resource);
};

const gstr2aRequestSubmit = (path, navigation, exact) => {
	return {
		path,
		navigation,
		component: AppByFile,
		queries,
		exact,
		title: ["label(GSTR2A Comparison)"],
		components: [
			{
				component: Filters,
				props: {
					filter: "param(filter)",
					availableFilters: "query(0).filters",
					stickyTop: parseInt(theme.layout.$headerHeight, 10),
					anchor: "results",
				},
			},
			{
				component: ExportButton,
				props: {
					data: "query(0).list",
				},
			},
			{
				component: List,
				modifiers: ["--row-style-alternate"],
				props: {
					headerListButtonsActions: {
						component: UserActions,
					},
					basePath: path,
					items: "query(0).list",
					sort: "param(sort)",
					cardKey: "param(entity)",
					card: rows.EntityCard,
					header: "query(0).header",
					metadata: "query(0).metadata",
					page: "param(page)",
					status: "status(0)",
					pageSize: "param(pageSize)",
					footer: "query(0).footer",
					totalizers: "query(0).totalizers",
				},
			},
		],
	};
};

export default gstr2aRequestSubmit;
