/*
##########################
##### PT FRAGMENTS ######
#########################
*/

export const SimpleEntity = {
	entity: "SimpleEntity",
	body: "id,name",
	xlsxExport: "id,name",
};

export const TaxInformation = {
	entity: "InlineTaxInformation",
	body: "entity,base,exemptionReason,exemptionCode,type,countryRegion,code,percentage,amount",
	xlsxExport:
		"entity,base,exemptionReason,exemptionCode,type,countryRegion,code,percentage,amount",
};

export const projectSummary = {
	entity: "ProjectSumary",
	body: "invoicesNetTotal,invoicesGrossTotal,invoicesNetAverage,invoicesMaxValue,transactionLinesCount,transactionsCount,leafAccountsCount,generalLedgersCount,invoiceLinesCount,invoicesCount,productsInvoicesCount,customersInvoicesCount,taxCodesCount,productsCodeCount,productsGroupCount,productsCount,suppliersCountriesCount,suppliersNifCount,suppliersCount,customersCountriesCount,customersNifCount,customersCoun,setClose",
	xlsxExport:
		"invoicesNetTotal,invoicesGrossTotal,invoicesNetAverage,invoicesMaxValue,transactionLinesCount,transactionsCount,leafAccountsCount,generalLedgersCount,invoiceLinesCount,invoicesCount,productsInvoicesCount,customersInvoicesCount,taxCodesCount,productsCodeCount,productsGroupCount,productsCount,suppliersCountriesCount,suppliersNifCount,suppliersCount,customersCountriesCount,customersNifCount,customersCoun",
};

export const address = {
	entity: "Address",
	body: "number,street,detail,postalCode,city,region,country",
	xlsxExport: "number,street,detail,postalCode,city,region,country",
};

export const activity = {
	entity: "Activity",
	body: "__typename,id,name,type,status,uploadedAt,startDate,endDate,submitedBy,checked,projectId,fiscalYear,flowStatus,subType,statusCode,fileSizeDecompress",
};

export const activity_detail = {
	entity: "ActivityDetail",
	body: "id,name,version,type,findingType,findingMessage,uploadedAt,uploadedByName,status,fiscalYear",
};

export const folders = {
	entity: "DataFile",
	body: "__typename,id,name,type,status,uploadedAt,startDate,endDate,submitedBy,checked,projectId,fiscalYear,auditStatus,statusCode,fileLocation",
};

export const invalid_files = {
	entity: "InvalidDataFile",
	body: "__typename,id,name,type,status,uploadedAt,startDate,endDate,submitedBy,checked,projectId,fiscalYear,auditStatus",
};

export const deleted_file_logs = {
	entity: "DeleteFileLogs",
	body: "__typename,fileId,userId,projectId,deletedAt,deletedBy,fileName,fileType",
};

export const invalid_files_detail = {
	entity: "InvalidDataFile",
	body: "id,name,uploadedAt,uploadedByName,type,version, statusCode, status, fiscalYear,typeAggregated",
};

export const folders_detail = {
	entity: "FolderDetail",
	body: "id,name,generalLedgerEntries,invoiceEntries,stockMovementEntries,workingDocumentEntries,paymentEntries,purchaseInvoiceEntries,assetTransactionEntries,workingDocumentEntries,uploadedAt,uploadedByName,type,version,totalFindings{severity,count}, statusCode, status, fiscalYear, typeAggregated, entitiesKpis{entity,count}",
};

export const findings = {
	entity: "Finding",
	body: "id,severity,referenceType,referenceId,createdAt",
	xlsxExport: "id,severity,referenceType,referenceId,createdAt",
};

export const saft_pt_finding = {
	entity: "Finding",
	body: "id,message,messageParameter,severity,referenceType,referenceId,createdAt,type,line,column,xmlBlock,ruleTitle, audit_note(projectId:$projectId,modulePrefix:$modulePrefix){id,message,justified}",
};

export const company_repository = {
	entity: "CompanyRepository",
	body: "id,name,type,fiscalYear,auditFileVersion,taxRegistrationNumber,businessName,startDate,endDate,glTransactionsCount,invoicesCount,stockMovementsCount,workingDocumentCount,paymentsCount",
};

export const simpleCustomer = {
	entity: "SimpleEntity",
	body: "id,name",
};

export const trial_balance = {
	entity: "TrialBalance",
	body: "id,name,factType,a01, a03,d01,l01,f01,f02,f05,f06,f07,f08,code,rowKeyGeneralLedgers",
	xlsxExport: "id,d01,l01,f01,f02,f05,f06,f07,f08,a03",
};

export const taxonomy_anomaly = {
	entity: "TaxonomyAnomaly",
	body: "taxonomyCode,taxonomyCodeDescription,suggestedTaxonomyCode,suggestedSourceAccount,status,taxonomyReference,sourceAccount,sourceAccountDescription",
	xlsxExport:
		"taxonomyReference,sourceAccount,sourceAccountDescription,taxonomyCode,taxonomyCodeDescription,suggestedSourceAccount,suggestedTaxonomyCode,status",
};

export const trial_balance_annual = {
	entity: "TrialBalanceAnnual",
	body: "id,name,factType,a01,d01,l01,f01,f02,f05,f06,f07,f08,n1f07,n1f08,n2f07,n2f08,n3f07,n3f08,code,rowKeyGeneralLedgers",
	xlsxExport: "d01,l01,f08,f07,n1f08,n1f07,n2f08,n2f07,n3f08,n3f07",
};
export const audit_finding = {
	entity: "FindingErrorStructure",
	body: "message, originalMessage, line, column, xmlBlock,type,referenceType",
};
export const audit_structure = {
	entity: "FindingStructure",
	body: "message, originalMessage, line, column, xmlBlock,type,referenceType",
};

export const saft_pt_finding_summary = {
	entity: "FindingSummary",
	body: "id, name, description, type, total, severity, verified, rule_identifier, reference_type, dataFiles",
};

export const trial_balance_taxonomy_annual = {
	entity: "TrialBalanceTaxonomyAnnual",
	body: "id,name,factType,d01,l01,f01,f02,f08,n1f08,n2f08,f07,n1f07,n2f07,n3f07,n3f08,accountId,a02",
	xlsxExport: "id,d01,l01,f08,f07,n1f08,n1f07,n2f08,n2f07,n3f08,n3f07",
};

export const trial_balance_taxonomy_annual_details = {
	entity: "TrialBalanceTaxonomyAnnualDetails",
	body: "id,name,factType,d01,l01,f01,f02,f08,n1f09,n2f09,f07,n1f10,n2f10,n3f09,n3f10,accountId,a02,byBalance,f09,f10",
	xlsxExport: "d01,l01,byBalance,f10,f09,n1f09,n1f10,n2f09,n2f10,n3f09,n3f10",
};

export const offsetting_account = {
	entity: "OffsettingAccount",
	body: "transaction_id,transaction__transaction_id,account_id,debit_amount,credit_amount,transaction__description,description,transaction__transaction_date,general_ledger__account_description,transaction__period,transaction__journal_id",
};

export const trial_balance_taxonomy_current_year = {
	entity: "TrialBalanceTaxonomyCurrentYear",
	body: "id,name,factType,d01,l01,f01,f02,f07,f08,accountId,f06,f05",
	xlsxExport: "id,d01,l01,f02,f01,f08,f07",
};

export const balance_taxonomy_annual = {
	entity: "BalanceAnnual",
	body: "__typename,name,currency,currencyN1,currencyN2,currencyN3,taxonomyCode,code,a02,groupingLine",
};

export const profit_and_loss_taxonomy_annual = {
	entity: "ProfitAndLossAnnual",
	body: "id,category,name,currency,currencyN1,currencyN2,currencyN3,code,taxonomyCode,a01,groupingLine,a02",
};

export const customer_sales_report = {
	entity: "CustomerSales",
	body: "customerCountry,customerId,companyName,customerTaxId,totalDocuments,totalTaxPayable,totalNet,totalGross,customerRowKey",
	xlsxExport:
		"customerCountry,customerId,companyName,customerTaxId,totalDocuments,totalTaxPayable,totalNet,totalGross",
};

export const nomenclatureCode = {
	entity: "NomenclatureCodes",
	body: "cnCode",
};

export const onuNumber = {
	entity: "OnuNumbers",
	body: "unNumber",
};

export const simpleJournal = {
	entity: "SimpleEntity",
	body: "id,name",
};

export const file = {
	entity: "File",
	body: `id,name,type,version,uploadedAt`,
};

export const application = {
	entity: "Application",
	body: `name,version,certificateNumber,owner,type`,
};
export const company = {
	entity: "Company",
	body: `name,vat,taxEntity,businessName,registry,phone,fax,email,website`,
};

export const transactionsSummary = {
	entity: "TransactionsSummary",
	body: `entriesNumber,debitTotal,creditTotal`,
};

export const movementsSummary = {
	entity: "MovementsSummary",
	body: `entriesNumber,quantityIssued`,
};

export const paymentsRow = {
	entity: "Payment",
	body: "id,refNumber,number,status,numberOfLines,type,user,period,taxPayable,netTotal,grossTotal,numberOfLines",
};

export const report = {
	entity: "WorkDocument",
	body: "id,name,number,series,serialNumber,status,numberOfLines,type,user,period,postDate,taxPayable,netTotal,grossTotal,numberOfLines,customer{...simpleCustomer}",
};

export const users = {
	entity: "User",
	body: "id, name, username, role, blocked, organizationName",
	xlsxExport: "id, name, username, role",
};

export const organizations = {
	entity: "Organization",
	body: "id,name,packageName,active,expirationDate",
};

export const projects = {
	entity: "Project",
	body: "id, description, taxRegistrationNumber, organization{ name }, fiscalYears, active",
};

export const project = {
	entity: "Project",
	body: "id, description, taxRegistrationNumber, organization{ name }, fiscalYears, active",
};

export const rules = {
	entity: "Rule",
	body: "id, ruleId, ruleType, title, appliesTo, description, severity, adapter, level, referenceType, successMessage, insuccessMessage, content, appliesToDataTypes, appliesToVersion, dependencies, ruleCategory, ruleSubgroup",
	xlsxExport:
		"id, ruleId, ruleType, title, appliesTo, description, severity, adapter, level, referenceType, successMessage, insuccessMessage, content, appliesToDataTypes, appliesToVersion, dependencies",
};

export const audit_execution_summary = {
	entity: "AuditExecutionSummary",
	body: "id, ruleIdentifier, result, createdAt, rulesTitle, dataFilesName",
	xlsxExport: "ruleIdentifier, rulesTitle, dataFilesName, result, createdAt",
};

export const findings_for_record = {
	entity: "AuditRecord",
	body: "rule, severity, category, description, occurrences, validation, findingId, ruleId",
};

export const findings_summary = {
	entity: "AuditSummary",
	body: "severity, count",
};

export const customs_procedures = {
	entity: "CustomsProcedure",
	body: "customsProcedure,authorizationDate,authorizationNumber,authorizationGoodsNomenclatureCode",
};

export const statement_accounts_accumulated = {
	entity: "StatementAccountAccumulated",
	body: "account_id,debit_amount,credit_amount,previous_net,accumulated_net,description,transaction__period,general_ledger__account_description,transaction__transaction_date,transaction_id,transaction__journal_id,transaction__transaction_id",
	xlsxExport:
		"account_id,general_ledger__account_description,description,transaction__transaction_id,transaction__journal_id,transaction__period,debit_amount,credit_amount,accumulated_net",
};

export const product_sales_report = {
	entity: "ProductSales",
	body: "productCode,productDescription,quantityCredit,productType,debitAmount,creditAmount,documentTotal,totalNumberDocuments,productGroup,rowKey",
	xlsxExport:
		"productCode,productDescription,productGroup,productType,totalNumberDocuments,quantityCredit,debitAmount,creditAmount,documentTotal",
};

export const sales_by_country_report = {
	entity: "SalesByCountry",
	body: "addressCountry,invoiceSeries,debitAmount,creditAmount,documentTotalsTaxPayable,documentTotalsNetTotal,documentTotalsGrossTotal,totalDocuments",
	xlsxExport:
		"addressCountry,totalDocuments,debitAmount,creditAmount,documentTotalsTaxPayable,documentTotalsNetTotal,documentTotalsGrossTotal",
};

export const efatura_sales_invoices = {
	entity: "EfaturaSalesInvoice",
	body: "id, invoiceNo, invoiceDate, period, totalValue, totalTaxBase, totalTaxAmount, issuerTaxID, acquirerTaxID, documentID, invoiceType, benefitStatus, acquirerName",
};

export const efatura_sales_invoice = {
	entity: "EfaturaSalesInvoice",
	body: "id, invoiceNo, invoiceDate, period, totalValue, totalTaxBase, totalTaxAmount, issuerTaxID, acquirerTaxID, documentID, issuerActivity, issuerActivityDesc, benefitStatus, benefitStatusDesc, issuerBenefitStatus, issuerBenefitStatusDesc, registrationOrigin, registrationOriginDesc",
};
export const efatura_sales_invoices_kpis = {
	entity: "EfaturaSaleInvoiceSummary",
	body: "invoicesNetTotal, invoicesGrossTotal, invoicesNetAverage, invoicesMaxValue, invoicesTaxAmount",
};

export const efatura_purchases_invoices = {
	entity: "EfaturaPurchasesInvoice",
	body: "id, invoiceNo, invoiceDate, period, totalValue, totalTaxBase, totalTaxAmount, issuerTaxID, acquirerTaxID, documentID, invoiceType, benefitStatus, issuerName",
};

export const efatura_purchases_invoice = {
	entity: "EfaturaPurchasesInvoice",
	body: "id, invoiceNo, invoiceDate, period, totalValue, totalTaxBase, totalTaxAmount, issuerTaxID, acquirerTaxID, documentID, issuerActivity, issuerActivityDesc, benefitStatus, benefitStatusDesc, issuerBenefitStatus, issuerBenefitStatusDesc, registrationOrigin, registrationOriginDesc, issuerName",
};

export const efatura_purchases_invoices_kpis = {
	entity: "EfaturaPurchaseInvoiceSummary",
	body: "invoicesNetTotal, invoicesGrossTotal, invoicesNetAverage, invoicesMaxValue, invoicesTaxAmount",
};

export const efatura_request = {
	entity: "EfaturaGenerator",
	body: "referenceId, requestUser, timestampStart, timestampEnd, outputTable, numberOfRecords, periods, startDate, endDate, informationType, status, hasFindings",
};

export const efatura_deleted_data = {
	entity: "EfaturaDeleted",
	body: "referenceId, requestUser, timestampStart, timestampEnd, outputTable, numberOfRecords, periods, startDate, endDate, informationType, status, hasFindings",
};

export const efatura_customers = {
	entity: "EfaturaCustomer",
	body: "id, acquirerTaxID ,acquirerName, invoicesNetTotal, invoicesTaxAmount, invoicesGrossTotal",
};

export const efatura_suppliers = {
	entity: "EfaturaSupplier",
	body: "id, issuerTaxID, issuerName, invoicesNetTotal, invoicesTaxAmount, invoicesGrossTotal",
};

export const efatura_customer = {
	entity: "EfaturaCustomer",
	body: "id, acquirerTaxID ,acquirerName, invoicesNetTotal, invoicesGrossTotal, invoicesNetAverage, invoicesMaxValue, invoicesTaxAmount, reports(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix), entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }",
};

export const efatura_supplier = {
	entity: "EfaturaSupplier",
	body: "id, issuerTaxID, issuerName, invoicesNetTotal, invoicesGrossTotal, invoicesNetAverage, invoicesMaxValue, invoicesTaxAmount, reports(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix), entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }",
};

export const audit_efatura_finding = {
	entity: "FindingErrorStructure",
	body: "message, originalMessage, line, column, xmlBlock,type,referenceType",
};

/*
##########################
##### PT FRAGMENTS ######
#########################
*/

//************************************************************************************************************************************

/*
##########################
##### PT ERP FRAGMENTS ######
#########################
*/

export const pt_erp_taxes = {
	entity: "PtErpTaxe",
	body: "taxCode,efectiveDate,expirationDate,taxPercentage,country,description,inactiveTax,taxType,transactionCode",
	xlsxExport:
		"taxCode,taxPercentage,country,description,inactiveTax,taxType,transactionCode",
};

export const pt_erp_customers = {
	entity: "PtErpCustomer",
	body: "id,customerId,taxRegistrationNumber,customerName,country",
	xlsxExport:
		"customerId,taxEntity,taxRegistrationNumber,taxRegistrationNumber1,customerName,streetName,city,postalCode,region,telephone,fax,accountid,toRemove,vendorVatNumber",
};

export const pt_erp_customer = {
	entity: "PtErpCustomer",
	body: "id,customerId,taxEntity,taxRegistrationNumber,taxRegistrationNumber1,customerName,streetName,city,postalCode,region,telephone,fax,accountid,toRemove,vendorVatNumber,country",
	xlsxExport:
		"customerId,taxEntity,taxRegistrationNumber,taxRegistrationNumber1,customerName,streetName,city,postalCode,region,telephone,fax,accountid,toRemove,vendorVatNumber",
};

export const pt_erp_suppliers = {
	entity: "PtErpSupplier",
	body: "id,supplierId,taxEntity,taxRegistrationNumber,taxRegistrationNumber1,supplierName,streetName,city,postalCode,region,telephone,fax,accountid,toRemove,tstcd2,country",
	xlsxExport:
		"supplierId,taxEntity,taxRegistrationNumber,taxRegistrationNumber1,supplierName,streetName,city,postalCode,region,telephone,fax,accountid,toRemove,tstcd2,country",
};

export const pt_erp_supplier = {
	entity: "PtErpSupplier",
	body: "id,supplierId,taxEntity,taxRegistrationNumber,taxRegistrationNumber1,supplierName,streetName,city,postalCode,region,telephone,fax,accountid,toRemove,tstcd2,country",
	xlsxExport:
		"supplierId,taxEntity,taxRegistrationNumber,taxRegistrationNumber1,supplierName,streetName,city,postalCode,region,telephone,fax,accountid,toRemove,tstcd2,country",
};

export const pt_erp_sale_invoices = {
	entity: "PtErpSaleInvoice",
	body: "id,documentNumber,period,docYear,transactionDate,description,systemEntryDate,postingDate,documentType,currencyCode,exchangeRate,numberOfLinesLines,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }",
	xlsxExport:
		"documentNumber,period,docYear,transactionDate,description,systemEntryDate,postingDate,documentType,currencyCode,exchangeRate,numberOfLinesLines",
};

export const pt_erp_sale_invoice = {
	entity: "PtErpSaleInvoice",
	body: "id,documentNumber,period,docYear,transactionDate,description,systemEntryDate,postingDate,documentType,currencyCode,exchangeRate,numberOfLinesLines,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }",
	xlsxExport:
		"documentNumber,period,docYear,transactionDate,description,systemEntryDate,postingDate,documentType,currencyCode,exchangeRate,numberOfLinesLines",
};

export const pt_erp_sale_invoices_summary = {
	entity: "PtErpInvoice",
	body: "id,documentNumber,period,docYear,transactionDate,description,systemEntryDate,postingDate,documentType,currencyCode,exchangeRate,numberOfLines",
	xlsxExport:
		"documentNumber,period,docYear,transactionDate,description,systemEntryDate,postingDate,documentType,currencyCode,exchangeRate,numberOfLines",
};

export const pt_erp_purchase_invoices = {
	entity: "PtErpPurchaseInvoice",
	body: "id,documentNumber,period,docYear,transactionDate,description,systemEntryDate,postingDate,documentType,currencyCode,exchangeRate,numberOfLinesLines",
	xlsxExport:
		"documentNumber,period,docYear,transactionDate,description,systemEntryDate,postingDate,documentType,currencyCode,exchangeRate,numberOfLinesLines",
};

export const pt_erp_purchase_invoice = {
	entity: "PtErpPurchaseInvoice",
	body: "id,documentNumber,period,docYear,transactionDate,description,systemEntryDate,postingDate,documentType,currencyCode,exchangeRate,numberOfLinesLines,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }",
	xlsxExport:
		"documentNumber,period,docYear,transactionDate,description,systemEntryDate,postingDate,documentType,currencyCode,exchangeRate,numberOfLinesLines",
};

export const pt_erp_purchase_invoices_summary = {
	entity: "PtErpPurchaseInvoice",
	body: "id,documentNumber,period,docYear,transactionDate,description,systemEntryDate,postingDate,documentType,currencyCode,exchangeRate,numberOfLinesLines",
	xlsxExport:
		"documentNumber,period,docYear,transactionDate,description,systemEntryDate,postingDate,documentType,currencyCode,exchangeRate,numberOfLinesLines",
};

export const pt_erp_purchase_invoice_lines = {
	entity: "PtErpPurchaseInvoiceLine",
	body: "id,documentNumber,documentYear,recordId,accountId,customerId,supplierId,description,debitCreditIndicator,amount,taxCode,taxBase,currencyAmount,lineItemType,postingKey,taxRegistrationNumber,country",
	xlsxExport:
		"documentNumber,documentYear,recordId,accountId,customerId,supplierId,description,debitCreditIndicator,amount,taxCode,taxBase,currencyAmount,lineItemType,postingKey,taxRegistrationNumber,country",
};

export const pt_erp_purchase_invoice_line = {
	entity: "PtErpPurchaseInvoiceLine",
	body: "id,documentNumber,documentYear,recordId,accountId,customerId,supplierId,description,debitCreditIndicator,amount,taxCode,taxBase,currencyAmount,lineItemType,postingKey,taxRegistrationNumber,country",
	xlsxExport:
		"documentNumber,documentYear,recordId,accountId,customerId,supplierId,description,debitCreditIndicator,amount,taxCode,taxBase,currencyAmount,lineItemType,postingKey,taxRegistrationNumber,country",
};

export const pt_erp_sales_invoice_lines = {
	entity: "PtErpSaleInvoiceLine",
	body: "id,documentNumber,documentYear,recordId,accountId,customerId,supplierId,description,debitCreditIndicator,amount,taxCode,taxBase,currencyAmount,lineItemType,postingKey,taxRegistrationNumber,country",
	xlsxExport:
		"documentNumber,documentYear,recordId,accountId,customerId,supplierId,description,debitCreditIndicator,amount,taxCode,taxBase,currencyAmount,lineItemType,postingKey,taxRegistrationNumber,country",
};

export const pt_erp_sales_invoice_line = {
	entity: "PtErpSaleInvoiceLine",
	body: "id,documentNumber,documentYear,recordId,accountId,customerId,supplierId,description,debitCreditIndicator,amount,taxCode,taxBase,currencyAmount,lineItemType,postingKey,taxRegistrationNumber,country",
	xlsxExport:
		"documentNumber,documentYear,recordId,accountId,customerId,supplierId,description,debitCreditIndicator,amount,taxCode,taxBase,currencyAmount,lineItemType,postingKey,taxRegistrationNumber,country",
};

/*
##########################
##### PT ERP FRAGMENTS ######
#########################
*/

//************************************************************************************************************************************

/*
##########################
##### LT FRAGMENTS ######
#########################
*/

export const saft_lt_invoices_with_lines_export = {
	entity: "LtInvoicesWithLines",
	body: "invoiceNo,invoiceType,invoiceCustomerName,invoicePeriod,invoiceTransaction,description,lineNumber,productCode,quantity,unitPrice,debitAmount,creditAmount",
};

export const saft_lt_customers = {
	entity: "LtCustomer",
	body: "id,companyName,taxId,registrationNumber,taxEntity,taxIdCount,accountId,phone,contact,billingAddress{country}, customerId",
	xlsxExport: "customerId,companyName,registrationNumber,taxEntity",
};

export const saft_lt_customer = {
	entity: "LtCustomer",
	body: "id,taxId,taxEntity,registrationNumber,accountId,phone,contact,customerId,companyName,selfBilling,kpiPercentageOnSales,kpiSalesTotalNet,kpiSalesDebitTotalNet,fax,email,website,billingAddress{...saft_lt_address},shipAddresses{...saft_lt_address},entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model },reports(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix),entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model },raw_data",
};

export const saft_lt_address = {
	entity: "LtAddress",
	body: "number,street,detail,postalCode,city,region,country",
	xlsxExport: "number,street,detail,postalCode,city,region,country",
};

export const saft_lt_addresses = {
	entity: "LtAddress",
	body: "street,number,city,postalCode,country,addressType,region,detail",
	xlsxExport: "addressType,street,number,detail,postalCode,city,country,region",
};

export const saft_lt_invoices = {
	entity: "LtInvoice",
	body: "id,refNumber,number,status,country,numberOfLines,type,user,period,invoiceDate,taxPayable,netTotal,grossTotal,numberOfLines,customer{...SimpleEntity},transaction{...SimpleEntity},companyName,datafile",
	xlsxExport:
		"refNumber,type,invoiceDate,customer{...SimpleEntity},country,period,transaction{...SimpleEntity},numberOfLines,taxPayable,grossTotal,netTotal",
};

export const saft_lt_accounts = {
	entity: "LtAccount",
	body: "accountId,openingDebit,openingCredit,closingDebit,closingCredit",
	xlsxExport: "accountId,openingDebit,openingCredit,closingDebit,closingCredit",
};

export const saft_lt_bank_accounts = {
	entity: "LtBankAccount",
	body: "ibanNumber,bankAccountNumber",
	xlsxExport: "ibanNumber,bankAccountNumber",
};

export const saft_lt_contacts = {
	entity: "LtContact",
	body: "id,headerId,taxEntity,firstName,lastName,telephone,email",
	xlsxExport: "firstName,lastName,telephone,email",
};

export const saft_lt_open_sales_invoices = {
	entity: "LtOpenSalesInvoice",
	body: "invoiceNo,invoiceDate,glPostingDate,transactionId,amount,amountCurrency,amountCurrencyCode,unpaidAmount,unpaidAmountCurrency,unpaidAmountCurrencyCode,debitCreditIndicator",
	xlsxExport:
		"invoiceNo,invoiceDate,glPostingDate,transactionId,amount,amountCurrency,amountCurrencyCode,unpaidAmount,unpaidAmountCurrency,unpaidAmountCurrencyCode,debitCreditIndicator",
};

export const saft_lt_tax_registrations = {
	entity: "LtTaxRegistrations",
	body: "id,headerId,taxEntity,taxRegistrationNumber,taxType,country",
	xlsxExport: "taxRegistrationNumber,taxType,country",
};

export const saft_lt_suppliers = {
	entity: "LtSupplier",
	body: "id,taxId,companyName,registrationNumber,taxEntity,taxIdCount,accountId,phone,contact,country,supplierId",
	xlsxExport: "supplierId,companyName,registrationNumber,taxEntity",
};

export const saft_lt_open_purchase_invoices = {
	entity: "LtOpenPurchaseInvoice",
	body: "invoiceNo,invoiceDate,glPostingDate,transactionId,amount,amountCurrency,amountCurrencyCode,unpaidAmount,unpaidAmountCurrency,unpaidAmountCurrencyCode,debitCreditIndicator",
	xlsxExport:
		"invoiceNo,invoiceDate,glPostingDate,transactionId,amount,amountCurrency,amountCurrencyCode,unpaidAmount,unpaidAmountCurrency,unpaidAmountCurrencyCode,debitCreditIndicator",
};

export const saft_lt_supplier = {
	entity: "LtSupplier",
	body: "id,taxId,taxEntity,registrationNumber,accountId,phone,contact,supplierId,companyName,selfBilling,fax,email,website,billingAddress{...saft_lt_address},shipAddresses{...saft_lt_address},entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model },raw_data",
};

export const saft_lt_product = {
	entity: "LtProduct",
	body: "id,description,taxEntity,productCode,barCode,type,group,goodsServicesId,valuationMethod,uomBase,kpiPercentageOnSales,kpiSalesTotalNet,kpiSalesDebitTotalNet,LtnomenclatureCodes{...LtnomenclatureCode},LtonuNumbers{...LtonuNumber},entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model },reports(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix),raw_data",
};

export const saft_lt_products = {
	entity: "LtProduct",
	body: "id,description,descriptionCount,type,productCode,group,goodsServicesId,valuationMethod,uomBase",
	xlsxExport: "description,productCode,group",
};

export const LtnomenclatureCode = {
	entity: "LtNomenclatureCodes",
	body: "cnCode",
};

export const LtonuNumber = {
	entity: "LtOnuNumbers",
	body: "unNumber",
};

export const saft_lt_general_ledger = {
	entity: "LtGeneralLedger",
	body: "id,accountId,taxonomyCode,taxEntity,description,systemEntryDate,openingDebit,closingDebit,openingCredit,closingCredit,groupingCategory,groupingCode,taxonomyReference,level,leafAccount,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model },reports(projectId:$projectId,fiscalYear:$fiscalYear),raw_data",
};

export const saft_lt_general_ledgers = {
	entity: "LtGeneralLedger",
	body: "id,accountId,taxonomyCode,description,openingDebit,closingDebit,openingCredit,closingCredit,groupingCategory,groupingCode",
	xlsxExport:
		"accountId,taxonomyCode,groupingCode,groupingCategory,description,openingDebit,closingDebit,openingCredit,closingCredit",
};

export const saft_lt_general_ledger_accounts = {
	entity: "LtGeneralLedgerAccount",
	body: "id,accountId,accountTableId,description,accountTableDescription,groupingCategory,groupingCode,openingDebitBalance,openingCreditBalance,closingDebitBalance,closingCreditBalance",
	xlsxExport:
		"accountId,accountTableId,description,groupingCode,groupingCategory,openingDebitBalance,openingCreditBalance,closingDebitBalance,closingCreditBalance",
};

export const saft_lt_general_ledger_account = {
	entity: "LtGeneralLedgerAccount",
	body: "accountId,accountTableId,accountType,taxEntity,description,groupingCategory,groupingCode,openingDebitBalance,openingCreditBalance,closingDebitBalance,closingCreditBalance,raw_data",
};

export const saft_lt_journal = {
	entity: "LtJournal",
	body: "id,journalId,description,taxEntity,raw_data",
};

export const saft_lt_journals = {
	entity: "LtJournal",
	body: "id,journalId,description",
	xlsxExport: "journalId,description",
};

export const saft_lt_tax_table_entries = {
	entity: "LtTaxTable",
	body: "id,code,country,taxType,stiTaxCode,description,flatTaxRateCurrencyCode,taxPercentage,flatTaxRateAmount,flatTaxRateCurrencyAmount",
	xlsxExport:
		"code,country,taxType,stiTaxCode,description,flatTaxRateCurrencyCode,taxPercentage,flatTaxRateAmount,flatTaxRateCurrencyAmount",
};

export const saft_lt_tax_table_entrie = {
	entity: "LtTaxTable",
	body: "id,taxType,code,flatTaxRateCurrencyCode,effectiveDate,expirationDate,taxPercentage,flatTaxRateAmount,flatTaxRateCurrencyAmount,country,description,stiTaxCode,raw_data",
};

export const Ltapplication = {
	entity: "LtApplication",
	body: `name,version,certificateNumber,owner,type`,
};

export const CvApplication = {
	entity: "CvApplication",
	body: `name,version,certificateNumber,owner,type`,
};

export const saft_lt_header = {
	entity: "LtHeader",
	body: `
		id,
		description,
		fiscalYear,
		auditFileVersion,
		taxRegistrationNumber,
		businessName,
		startDate,
		endDate,
		createdDate,
		glTransactionsCount,
		invoicesCount,
		stockMovementsCount,
		workingDocumentCount,
		paymentsCount,
		application{...Ltapplication},
		company{...Ltcompany},
		salesInvoices{...LttransactionsSummary},
		purchasesInvoices{...LttransactionsSummary},
		payments{...LttransactionsSummary},
		glTransactions{...LttransactionsSummary},
		workingDocuments{...LttransactionsSummary},
		stockMovements{...LtmovementsSummary},
		file{...Ltfile},
		entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model },
        raw_data
	`,
};

export const saft_lt_headers = {
	entity: "LtHeader",
	body: "id,fiscalYear,auditFileVersion,assetTransactionCount,taxRegistrationNumber,businessName,startDate,endDate,glTransactionsCount,invoicesCount,stockMovementsCount,workingDocumentCount,paymentsCount,purchaseCount,fileName,fileId,fileType",
	xlsxExport:
		"taxRegistrationNumber,businessName,auditFileVersion,fiscalYear,startDate,endDate,glTransactionsCount,invoicesCount,purchaseCount,stockMovementsCount,assetTransactionCount,paymentsCount,fileName",
};

export const saft_lt_finding = {
	entity: "LtFinding",
	body: "id,message,messageParameter,severity,referenceType,referenceId,createdAt,type,line,column,xmlBlock,ruleTitle, audit_note(projectId:$projectId,modulePrefix:$modulePrefix){id,message,justified}",
};

export const saft_lt_finding_summary = {
	entity: "LtFindingSummary",
	body: "id, name, description, type, total, severity, verified, rule_identifier, reference_type, dataFiles",
};

export const Ltfile = {
	entity: "LtFile",
	body: `id,name,type,version,uploadedAt`,
};

export const Ltcompany = {
	entity: "LtCompany",
	body: `name,vat,taxEntity,businessName,registry,phone,fax,email,website`,
};

export const LttransactionsSummary = {
	entity: "LtTransactionsSummary",
	body: `entriesNumber,debitTotal,creditTotal`,
};

export const LtmovementsSummary = {
	entity: "LtMovementsSummary",
	body: `entriesNumber,quantityIssued`,
};

export const saft_lt_header_addresses = {
	entity: "LtHeaderAddress",
	body: "number,street,country,city,postalCode,address_type,region,full_address",
};

export const saft_lt_transaction_line = {
	entity: "LtTransactionLine",
	body: `
		id,
		recordId,
		accountId,
		credit,
		glPostingDate,
		debit,
		description,
		type,
		transactionId,
		transactionDate,
		transactionPeriod,
		journalId,
		docArchivalNumber,
		taxEntity,
		generalLedger{
			id,
			name
		},
		customer{...SimpleEntity},
		supplier{...SimpleEntity},
		entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model },
		raw_data
	`,
};

export const saft_lt_transaction_lines = {
	entity: "LtTransactionLine",
	body: "id,credit,debit,description,recordId,accountId,generalLedger{id,name},customerId,supplierId,customer{...SimpleEntity},supplier{...SimpleEntity}",
	xlsxExport:
		"recordId,accountId,description,debit,credit,customerId,supplierId",
};

export const saft_lt_transaction = {
	entity: "LtTransaction",
	body: `id,transactionId,period,description,transactionDate,customerId,supplierId,systemEntryDate,credit,debit,user,glPostingDate,taxEntity,importId,archivalNumber,customer{...SimpleEntity},supplier{...SimpleEntity},journal{ ...SimpleEntity }, entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model },raw_data`,
};

export const saft_lt_transactions = {
	entity: "LtTransaction",
	body: "id,transactionId,journalId,transactionDate,customerId,supplierId,description,user,period,glPostingDate,debit,credit,numberOfLines,journal{ ...SimpleEntity },customer{...SimpleEntity},supplier{...SimpleEntity}",
	xlsxExport:
		"transactionId,numberOfLines,description,glPostingDate,transactionDate,period,debit,credit,customerId,supplierId,datafile",
};

export const saft_lt_invoice_line = {
	entity: "LtInvoiceLine",
	body: `
		id,
		description,
		unit,
		unitPrice,
		quantity,
		taxDate,
		lineNumber,
		productCode,
		debitAmount,
		creditAmount,
		invoiceNo,
		accountId,
		amountCurrencyAmount,
		amountCurrencyCode
		debitCreditIndicator,
		settlementAmount,
		customer{
			id,
			name,
		},
		shipTo{
			city,
			postalCode,
			region,
			country
		},
		tax{...LtTaxInformation},
		product{
			id,
			name
		},
		raw_data,
		entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }
	`,
};

export const saft_lt_invoice_lines = {
	entity: "LtInvoiceLine",
	body: "id,description,taxPercentage,accountId,debitCreditIndicator,unit,unitPrice,quantity,lineNumber,productCode,debitAmount,creditAmount",
	xlsxExport:
		"lineNumber,productCode,accountId,description,quantity,unitPrice,debitAmount,creditAmount,debitCreditIndicator",
};

export const saft_lt_invoice = {
	entity: "LtInvoice",
	body: `id,refNumber,type,period,taxEntity,accountId,status,statusUser,statusDate,taxPayable,netTotal,grossTotal,user,registryDate,invoiceDate,taxEntity,importId,transaction{__typename,id,name},customer{...simpleCustomer},entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model },raw_data`,
};

export const saft_lt_ship_froms = {
	entity: "LtShipFrom",
	body: "importId,taxEntity,deliveryId,deliveryDate,warehouseId,streetName,number,city,postalCode,country,addressType,region,fullAddress,finalVersion",
	xlsxExport:
		"warehouseId,deliveryId,deliveryDate,addressType,number,fullAddress,postalCode,city,country,region",
};

export const saft_lt_ship_tos = {
	entity: "LtShipTo",
	body: "importId,taxEntity,deliveryId,deliveryDate,warehouseId,streetName,number,city,postalCode,country,addressType,region,fullAddress,finalVersion",
	xlsxExport:
		"warehouseId,deliveryId,deliveryDate,addressType,number,fullAddress,postalCode,city,country,region",
};

export const saft_lt_purchase_invoice_lines = {
	entity: "LtPurchaseInvoiceLine",
	body: "id,lineNumber,description,productCode,debitAmount,quantity,creditAmount,accountId,unitPrice,debitCreditIndicator",
	xlsxExport:
		"lineNumber,description,productCode,debitAmount,quantity,creditAmount,accountId,unitPrice,debitCreditIndicator",
};

export const saft_lt_purchase_invoice_line = {
	entity: "LtPurchaseInvoiceLine",
	body: "id,lineNumber,purchaseInvoiceId,invoiceType,quantity,invoiceUOM,debitCreditIndicator,debitAmount,creditAmount,invoiceLineAmount,invoiceLineCurrencyAmount,shippingCostsAmount,shippingCostsCurrencyAmount,invoiceDate,taxPointDate,description,accountId,productRowKey,productCode,entities(projectId:$projectId,fiscalYear:$fiscalYear){ entity, count, model },raw_data",
};

export const saft_lt_uom_table_entries = {
	entity: "LtUomTableEntry",
	body: "id,unitOfMeasure,description",
	xlsxExport: "unitOfMeasure,description",
};

export const saft_lt_movement_types = {
	entity: "LtMovementType",
	body: "movementType,taxEntity,description",
	xlsxExport: "movementType,taxEntity,description",
};

export const saft_lt_physical_stocks = {
	entity: "LtPhysicalStock",
	body: "id,type,status,unitMeasure,productId,warehouseId,unitPrice,openingValue,closingValue,openingQuantity,closingQuantity",
	xlsxExport:
		"productId,warehouseId,unitMeasure,openingQuantity,closingQuantity,unitPrice,openingValue,closingValue",
};

export const saft_lt_physical_stock = {
	entity: "LtPhysicalStock",
	body: "id,type,status,taxEntity,unitMeasure,unitPrice,openingValue,closingValue,openingQuantity,closingQuantity,productId,warehouseId,stockOwner,stockOwnerId,numberOfCustomsProcedures,uomBaseConversionFactor,numberOfStockCharacteristics,numberOfPhysicalStockAcquisitions,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model },raw_data",
};

export const saft_lt_acquisitions = {
	entity: "LtAcquisition",
	body: "supplierRegistrationNumber,supplierName,dateOfAcquisition,invoiceNo,invoiceDate,glPostingDate,acquiredQuantity,stockRemainderQuantity,stockRemainderAmount",
	xlsxExport:
		"supplierName,supplierRegistrationNumber,invoiceNo,dateOfAcquisition,invoiceDate,glPostingDate,acquiredQuantity,stockRemainderQuantity,stockRemainderAmount",
};

export const saft_lt_characteristics = {
	entity: "LtCharacteristic",
	body: "characteristic,characteristicValue",
	xlsxExport: "characteristic,characteristicValue",
};

export const saft_lt_customs_procedures = {
	entity: "LtCustomsProcedure",
	body: "customsProcedure,authorizationDate,authorizationNumber,authorizationGoodsNomenclatureCode",
	xlsxExport:
		"customsProcedure,authorizationDate,authorizationNumber,authorizationGoodsNomenclatureCode",
};

export const saft_lt_owners = {
	entity: "LtOwner",
	body: "id,name,ownerId,country,sharesAcquisition,sharesTransfersDate,sharesQuantity,sharesAmount",
	xlsxExport:
		"ownerId,name,country,sharesAcquisition,sharesTransfersDate,sharesQuantity,sharesAmount",
};

export const saft_lt_owner = {
	entity: "LtOwner",
	body: "name,country,sharesTransfersDate,sharesAcquisition,sharesQuantity,sharesAmount,sharesType,accountId,raw_data",
};

export const saft_lt_analysis_type_table_entries = {
	entity: "LtAnalysisTypes",
	body: "id,analysisId,type,stiAnalysis,description,analysisTypeDescription",
	xlsxExport: "analysisId,description,type,stiAnalysis",
};

export const saft_lt_valuations = {
	entity: "LtAssetValuation",
	body: "id,assetId,valuationType,valuationClass,accountId,transfersDate,assetDisposalDate,depreciationDate,transfers,investmentSupport,acquisitionAndProductionCostsStart,acquisitionAndProductionCostsEnd,assetDisposal,assetLifeYears,assetLifeMonths,depreciationForPeriod,bookValueBegin,bookValueEnd,depreciationPercentage,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }",
	xlsxExport:
		"valuationType,valuationClass,accountId,transfersDate,assetDisposalDate,depreciationDate,depreciationPercentage,depreciationForPeriod,acquisitionAndProductionCostsStart,acquisitionAndProductionCostsEnd,bookValueBegin,bookValueEnd",
};

export const saft_lt_valuation = {
	entity: "LtAssetValuation",
	body: "id,assetId,valuationType,valuationClass,accountId,transfersDate,assetDisposalDate,depreciationDate,transfers,investmentSupport,acquisitionAndProductionCostsStart,acquisitionAndProductionCostsEnd,assetDisposal,assetLifeYears,assetLifeMonths,depreciationForPeriod,bookValueBegin,bookValueEnd,accumulatedDepreciation,accumulatedDepreciationAppreciation,depreciationPercentage,numberOfImpairments,depreciationMethod,numberOfAppreciations,numberOfExtraDepreciations,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model },raw_data",
};

export const saft_lt_asset_suppliers = {
	entity: "LtAssetSupplier",
	body: "id,supplierName,supplierId,addressType,streetName,addressNumber,fullAddress,postalCode,city,country,region",
	xlsxExport:
		"supplierName,supplierId,addressType,addressNumber,fullAddress,postalCode,city,country,region",
};

export const saft_lt_asset_transactions = {
	entity: "LtAssetTransaction",
	body: "id,assetTransactionId,assetId,transactionType,description,period,date,supplierID,transactionID,transactionAmount,bookValueOnTransaction,acquisitionAndProductionCostsOnTransaction,supplier{...SimpleEntity},transaction{...SimpleEntity},asset{...SimpleEntity}",
	xlsxExport:
		"assetTransactionId,assetId,transactionType,description,period,date,supplierID,transactionID,transactionAmount,bookValueOnTransaction,acquisitionAndProductionCostsOnTransaction,datafile",
};

export const saft_lt_valuation_appreciations = {
	entity: "LtAssetValuationAppreciation",
	body: "depreciationMethod,debitCreditIndicator,accountId,appreciationDate,depreciationPercentage,appreciation,depreciationPeriod",
	xlsxExport:
		"depreciationMethod,debitCreditIndicator,accountId,appreciationDate,depreciationPercentage,appreciation,depreciationPeriod",
};

export const saft_lt_valuation_extra_deprecations = {
	entity: "LtAssetValuationExtraDepreciation",
	body: "depreciationMethod,depreciation",
};

export const saft_lt_valuation_impairments = {
	entity: "LtAssetValuationImpairment",
	body: "accountId,impairmentDate,eliminationDate,impairment,elimination",
};

export const saft_lt_assets = {
	entity: "LtAsset",
	body: "id,assetId,assetsRegistrationId,acquisitionDate,startUpDate,description,accountId",
	xlsxExport:
		"accountId,description,assetId,assetsRegistrationId,acquisitionDate,startUpDate",
};

export const saft_lt_asset = {
	entity: "LtAsset",
	body: "id,assetId,assetsRegistrationId,taxEntity,accountId,acquisitionDate,startUpDate,description,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model },raw_data",
};

export const saft_lt_purchase_invoices = {
	entity: "LtPurchaseInvoice",
	body: "id,refNumber,numberOfLines,transactionId,type,invoiceDate,taxPayable,grossTotal,netTotal,country,period,supplierId,supplierRowKey,transaction{...SimpleEntity}",
	xlsxExport:
		"refNumber,type,invoiceDate,supplierId,country,,period,numberOfLines,netTotal,taxPayable,grossTotal,datafile",
};

export const saft_lt_purchase_invoice = {
	entity: "LtPurchaseInvoice",
	body: `id,refNumber,type,period,taxEntity,transaction{...SimpleEntity},glPostingDate,accountId,taxPayable,netTotal,grossTotal,user,invoiceDate,taxEntity,importId,supplierId,supplierRowKey,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model },raw_data`,
};

export const saft_lt_tax_informations = {
	entity: "LtTaxInformation",
	body: "purchaseInvoiceRowKey,importId,taxEntity,purchaseInvoiceId,taxType,taxCode,taxPercentage,taxBase,taxBaseDescription,taxAmount,taxAmountCurrencyCode,taxCurrencyAmount,taxExemptionReason,taxDeclarationPeriod",
	xlsxExport:
		"taxType,taxCode,taxPercentage,taxBase,taxBaseDescription,taxAmount,taxAmountCurrencyCode,taxCurrencyAmount,taxExemptionReason,taxDeclarationPeriod",
};

export const saft_lt_payments = {
	entity: "LtPayment",
	body: "id,refNumber,number,status,transactionId,numberOfLines,numberOfSettlements,description,transaction{__typename,id,name},type,user,period,transactionDate,taxPayable,netTotal,grossTotal,numberOfLines,customer{...simpleCustomer},settlementAmount",
	xlsxExport:
		"refNumber,numberOfLines,type,period,transactionDate,grossTotal,netTotal,numberOfSettlements,transactionId,datafile",
};

export const saft_lt_payment = {
	entity: "LtPayment",
	body: `id,type,period,taxEntity,status,statusUser,periodYear,refNumber,transactionId,statusDate,taxPayable,netTotal,grossTotal,user,registryDate,transactionDate,transaction{__typename,id,name},entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model },raw_data`,
};

export const saft_lt_payment_line = {
	entity: "LtPaymentLine",
	body: "id,refNumber,description,accountId,debitCreditIndicator,paymentLineAmount,paymentLineAmountCurrencyCode,paymentLineCurrencyAmount,type,supplierId,customerId,lineNumber,series,serialNumber,paymentStatus,transactionDate,settlementAmount,creditAmount,debitAmount,paymentType,customer{ ...SimpleEntity }, tax{...LtTaxInformation},entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model },raw_data",
};

export const saft_lt_payment_lines = {
	entity: "LtPaymentLine",
	body: "id,refNumber,lineNumber,accountId,description,series,serialNumber,paymentStatus,transactionDate,settlementAmount,debitAmount,creditAmount,debitCreditIndicator",
	xlsxExport:
		"lineNumber,accountId,description,debitAmount,creditAmount,debitCreditIndicator",
};

export const saft_lt_settlements = {
	entity: "LtSettlement",
	body: "settlementDiscount,settlementAmount,settlementAmountCurrencyCode,settlementCurrencyAmount,settlementDueDate,settlementDate,paymentMechanism,debitCreditIndicator",
};

export const saft_lt_project_summary = {
	entity: "LtProjectSummary",
	body: "invoicesNetTotal,invoicesGrossTotal,invoicesNetAverage,invoicesMaxValue,transactionLinesCount,transactionsCount,leafAccountsCount,generalLedgersCount,invoiceLinesCount,invoicesCount,productsInvoicesCount,customersInvoicesCount,taxCodesCount,productsCodeCount,productsGroupCount,productsCount,suppliersCountriesCount,suppliersNifCount,suppliersCount,customersCountriesCount,customersNifCount,customersCoun,purchasesCount,purchaseLinesCount,purchasesGrossTotal,purchasesNetTotal,purchasesNetAverage,purchasesMaxValue,erpInvoiceLinesCount,erpInvoicesCount,erpInvoicesGrossTotal,erpInvoicesNetTotal,erpInvoicesNetAverage,erpInvoicesMaxValue,erpPurchasesCount,erpPurchaseLinesCount,erpPurchasesGrossTotal,erpPurchasesNetAverage,erpPurchasesMaxValue,erpPurchasesNetTotal,setClose",
};

export const LtTaxInformation = {
	entity: "LtInlineTaxInformation",
	body: "entity,base,exemptionReason,exemptionCode,type,countryRegion,code,percentage,amount",
};

export const saft_lt_analysis = {
	entity: "LtAnalysis",
	body: "analysisId,analysisType,analysisAmountCurrencyCode,analysisAmount,analysisCurrencyAmount",
};

export const saft_lt_credit_notes = {
	entity: "LtCreditNote",
	body: "reference,reason",
};

export const saft_lt_order_reference = {
	entity: "LtOrderReference",
	body: "originatingOn,orderDate",
};

export const saft_lt_asset_transaction_valuations = {
	entity: "LtAssetTransactionValuation",
	body: "assetValuationType,taxEntity,debitCreditIndicator,assetTransactionAmount,bookValueOnTransaction,acquisitionAndProductionCostsOnTransaction",
};

export const saft_lt_asset_transaction = {
	entity: "LtAssetTransaction",
	body: "assetTransactionId,assetId,taxEntity,transactionType,description,period,date,supplierID,supplier{...SimpleEntity},transaction{...SimpleEntity},asset{...SimpleEntity},transactionID,transactionAmount,bookValueOnTransaction,acquisitionAndProductionCostsOnTransaction,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model },raw_data",
};

export const saft_lt_project_dimension_master_data = {
	entity: "ProjectDimensionMasterData",
	body: "customers,suppliers,products,general_ledgers,journals,tax_table_entries,headers,tax_table_entry_details,uom_table_entries,movement_types,physical_stocks,owners,analysis_type_table_entries,assets,general_ledger_accounts",
};

export const lt_erp_project_dimension_master_data = {
	entity: "ProjectDimensionMasterData",
	body: "customers,suppliers,products,general_ledgers,journals,tax_table_entries,headers,tax_table_entry_details,uom_table_entries,movement_types,physical_stocks,owners,analysis_type_table_entries,assets,general_ledger_accounts",
};

export const saft_lt_project_dimension_transaction = {
	entity: "ProjectDimensionTransaction",
	body: "invoices,invoice_lines,purchase_invoices,purchase_invoice_lines,payments,payment_lines,stock_movements,stock_movement_lines,transactions,transaction_lines,asset_transactions,asset_transaction_lines,general_ledgers_transactions,general_ledgers_transaction_lines,movement_of_goods,movement_of_good_lines",
};

export const lt_erp_project_dimension_transaction = {
	entity: "ProjectDimensionTransaction",
	body: "invoices,invoice_lines,purchase_invoices,purchase_invoice_lines,payments,payment_lines,stock_movements,stock_movement_lines,transactions,transaction_lines,asset_transactions,asset_transaction_lines,general_ledgers_transactions,general_ledgers_transaction_lines,movement_of_goods,movement_of_good_lines",
};

export const saft_lt_project_dimension = {
	entity: "ProjectDimension",
	body: "customerCountries,supplierCountries,productsGroup,productsCode,customersOnSales,supplierOnPurchases,productsOnTransaction",
};

export const saft_lt_stock_movements = {
	entity: "LtStockMovement",
	body: "id,number,series,serialNumber,movementReference,documentReferenceDocumentType,gltransactionId,movementDate,status,numberOfLines,type,user,period,taxPayable,netTotal,grossTotal,numberOfLines,customer{...SimpleEntity},transaction{...SimpleEntity}",
	xlsxExport:
		"movementReference,numberOfLines,documentReferenceDocumentType,gltransactionId,movementDate,type,period,datafile",
};

export const saft_lt_stock_movement = {
	entity: "LtStockMovement",
	body: `
		id
		number
		type
		period
		status
		statusUser
		movementReference
		statusDate
		taxPayable
		netTotal
		grossTotal
		user
		registryDate
		movementDate
		taxEntity
		importId
		unicCode
		gltransactionId
		customer{...SimpleEntity}
		entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }
		shipFrom{
			number,
			street,
			detail,
			city,
			postalCode,
			region,
			country
		},
		startTime,
		endTime,
		eacCode,
		atDocId,
		movementComments,
		currencyCode,
		currencyAmount,
		statusReason,
		exchangeRate,
		shipTo{
			number,
			street,
			detail,
			city,
			postalCode,
			region,
			country
		},
		transaction{...SimpleEntity}
		raw_data
	`,
};

export const saft_lt_stock_movement_line = {
	entity: "LtStockMovementLine",
	body: `
		id,
		description,
		productCode,
		lineNumber,
		transactionId,
		movementReference,
		series,
		serialNumber,
		taxType,
		type,
		movementDate,
		taxCountry,
		taxCode,
		quantity,
		taxPercentage,
		debitAmount,
		bookValue,
		accountId,
		debitCreditIndicator,
		unitOfMeasure
		creditAmount,
		settlementAmount,
		iecAmount,
		documentType,
		documentNumber,
		shipTo{
			city,
			postalCode,
			region,
			country
		},
		transaction{ ...SimpleEntity },
		supplier{ ...SimpleEntity },
		customer{ ...SimpleEntity },
		product{ ...SimpleEntity },
		entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model },
		raw_data
	`,
};

export const saft_lt_stock_movement_lines = {
	entity: "LtStockMovementLine",
	body: `
		id,
		description,
		productCode,
		lineNumber,
		series,
		serialNumber,
		accountId,
		unitOfMeasure,
		bookValue,
		debitCreditIndicator,
		taxType,
		taxCountry,
		taxCode,
		taxPercentage,
		quantity,
		debitAmount,
		creditAmount
	`,
	xlsxExport: `
		lineNumber,
		accountId,
		productCode,
		quantity,
		unitOfMeasure,
		bookValue,
		debitCreditIndicator
	`,
};

export const saft_lt_product_uoms = {
	entity: "LtProductUoms",
	body: "uomStandard,uomToUomBaseConversionFactor",
};

export const saft_lt_taxes = {
	entity: "LtTaxes",
	body: "productId,taxType,taxCode",
};
/*
##########################
##### LT FRAGMENTS ######
#########################
*/

//************************************************************************************************************************************

/*
##########################
#### LT ERP FRAGMENTS ####
##########################
*/

export const lt_erp_addresses = {
	entity: "ErpAddress",
	body: "street,number,city,postalCode,country,addressType,region,detail,addressType",
	xlsxExport:
		"street,number,city,postalCode,country,addressType,region,detail,addressType",
};

export const lt_erp_address = {
	entity: "ErpAddress",
	body: "number,street,detail,postalCode,city,region,country",
};

export const lt_erp_open_sales_invoices = {
	entity: "ErpOpenSalesInvoice",
	body: "invoiceNo,invoiceDate,glPostingDate,transactionId,amount,amountCurrency,amountCurrencyCode,unpaidAmount,unpaidAmountCurrency,unpaidAmountCurrencyCode,debitCreditIndicator",
	xlsxExport:
		"invoiceNo,invoiceDate,glPostingDate,transactionId,amount,amountCurrency,amountCurrencyCode,unpaidAmount,unpaidAmountCurrency,unpaidAmountCurrencyCode,debitCreditIndicator",
};

export const lt_erp_open_purchase_invoices = {
	entity: "ErpOpenPurchaseInvoice",
	body: "invoiceNo,invoiceDate,glPostingDate,transactionId,amount,amountCurrency,amountCurrencyCode,unpaidAmount,unpaidAmountCurrency,unpaidAmountCurrencyCode,debitCreditIndicator",
};

export const lt_erp_customer = {
	entity: "ErpCustomer",
	body: "id,taxId,taxEntity,accountId,phone,contact,customerId,companyName,selfBilling,kpiPercentageOnSales,kpiSalesTotalNet,kpiSalesDebitTotalNet,fax,email,website,billingAddress{...lt_erp_address},shipAddresses{...lt_erp_address},entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model },reports(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix)",
};

export const lt_erp_customers = {
	entity: "ErpCustomer",
	body: "id,companyName,taxId,taxEntity,taxIdCount,accountId,phone,contact,billingAddress{country}, customerId",
	xlsxExport: "companyName,taxId,taxEntity,customerId",
};

export const lt_erp_supplier = {
	entity: "ErpSupplier",
	body: "id,taxId,taxEntity,accountId,phone,contact,supplierId,companyName,selfBilling,fax,email,website,billingAddress{...lt_erp_address},shipAddresses{...lt_erp_address},entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }",
};

export const lt_erp_suppliers = {
	entity: "ErpSupplier",
	body: "id,taxId,companyName,taxEntity,taxIdCount,accountId,phone,contact,country,supplierId",
	xlsxExport: "companyName,taxId,taxEntity,supplierId",
};

export const lt_erp_product = {
	entity: "ErpProduct",
	body: "id,productCode,goodsServicesId,productGroup,description,valuationMethod,uomBase,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }",
};

export const lt_erp_products = {
	entity: "ErpProduct",
	body: "id,productCode,goodsServicesId,productGroup,description,valuationMethod,uomBase",
	xlsxExport:
		"productCode, productGroup, description, goodsServicesId, uomBase",
};

export const lt_erp_product_taxes = {
	entity: "ErpProductTaxe",
	body: "productCode,taxType,taxCode",
	xlsxExport: "taxType, taxCode",
};

export const lt_erp_products_uoms = {
	entity: "ErpProductsUom",
	body: "productCode,uomStandard,uomToUomBaseConversionFactor",
	xlsxExport: "productCode, uomStandard, uomToUomBaseConversionFactor",
};

export const lt_erp_general_ledger = {
	entity: "ErpGeneralLedger",
	body: "id,accountId,taxonomyCode,taxEntity,description,groupingCode,taxonomyReference,transactionDate,systemId,transactionId,systemEntryDate,customerId,supplierId,period,periodYear,journalType,glPostingDate,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model },reports(projectId:$projectId,fiscalYear:$fiscalYear)",
};

export const lt_erp_general_ledgers = {
	entity: "ErpGeneralLedger",
	body: "id,accountId,taxonomyCode,description,groupingCategory,groupingCode,journalId,journalDescription,transactionId,glPostingDate,period,journalType,",
	xlsxExport:
		"journalId,journalDescription,transactionId,glPostingDate,period,journalType,description",
};

export const lt_erp_general_ledger_entry_lines = {
	entity: "ErpGeneralLedgerEntryLine",
	body: "id,accountId,description,recordId,debitCreditIndicator,debitCreditAmount,debitCreditCurrencyAmount,debitCreditCurrencyCode",
	xlsxExport:
		"accountId,description,recordId,debitCreditIndicator,debitCreditAmount,debitCreditCurrencyAmount,debitCreditCurrencyCode",
};

export const lt_erp_general_ledger_entry_line = {
	entity: "ErpGeneralLedgerEntryLine",
	body: "id,accountId,description,debitCreditCurrencyAmount,debitCreditCurrencyCode,recordId,debitCreditIndicator,debitCreditAmount,transactionId,sourceDocumentId,customerId,supplierId,taxPercentage,taxBase,taxCode,taxAmount,taxCurrencyAmount,period,periodYear,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }",
};

export const lt_erp_general_ledger_accounts = {
	entity: "ErpGeneralLedgerAccount",
	body: "id,accountId,accountTableId,description,accountTableDescription,groupingCategory,groupingCode,openingDebitBalance,openingCreditBalance,closingDebitBalance,closingCreditBalance",
	xlsxExport:
		"accountId,accountTableId,description,openingDebitBalance,openingCreditBalance,closingDebitBalance,closingCreditBalance",
};

export const lt_erp_general_ledger_account = {
	entity: "ErpGeneralLedgerAccount",
	body: "accountId,accountTableId,accountType,description,groupingCategory,groupingCode,openingDebitBalance,openingCreditBalance,closingDebitBalance,closingCreditBalance",
};

export const lt_erp_tax_table_entries = {
	entity: "ErpTaxTableEntry",
	body: "code,country,taxType,stiTaxCode,description,taxCodeDescription,flatTaxRateCurrencyCode,taxPercentage,flatTaxRateAmount,flatTaxRateCurrencyAmount",
	xlsxExport:
		"code,country,taxType,stiTaxCode,description,taxPercentage,taxCodeDescription",
};

export const lt_erp_headers = {
	entity: "ErpHeader",
	body: "id,taxRegistrationNumber,businessName,startDate,endDate,fileName,fileId",
	xlsxExport: "taxRegistrationNumber,businessName,startDate,endDate",
};

export const lt_erp_header = {
	entity: "ErpHeader",
	body: "id,taxRegistrationNumber,startDate,endDate,taxAccountingBasis,accountId,defaultCurrencyCode,softwareCompanyName, softwareId, softwareVersion, taxEntity, entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }",
};

export const lt_erp_bank_accounts = {
	entity: "ErpBankAccount",
	body: "ibanNumber,bankAccountNumber",
	xlsxExport: "ibanNumber,bankAccountNumber",
};

export const lt_erp_contacts = {
	entity: "ErpContact",
	body: "id,headerId,taxEntity,firstName,lastName,telephone,email",
	xlsxExport: "taxEntity,firstName,lastName,telephone,email",
};

export const lt_erp_tax_registrations = {
	entity: "ErpTaxRegistrations",
	body: "id,headerId,taxEntity,taxRegistrationNumber,taxType,country",
	xlsxExport: "headerId,taxEntity,taxRegistrationNumber,taxType,country",
};

export const lt_erp_invoice_line = {
	entity: "ErpInvoiceLine",
	body: `
		id,
		productCode,
		lineNumber,
		invoiceNo,
		taxDate,
		taxEntity,
		accountId,
		quantity,
		unit,
		invoiceLineAmount,
		invoiceLineAmountCurrencyCode,
		invoiceLineCurrencyAmount,
		debitCreditIndicator,
		description,
		entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }
	`,
};

export const lt_erp_invoice_lines = {
	entity: "ErpInvoiceLine",
	body: "id,lineNumber,productCode,invoiceLineAmount,taxDate,invoiceLineAmountCurrencyCode,debitCreditIndicator,quantity,invoiceLineCurrencyAmount,invoiceLineAmountCurrencyCode",
	xlsxExport:
		"refNumber,type,invoiceDate,grossTotal,netTotal,country,period,customerId,gltransactionId,companyName",
};

export const lt_erp_sales_invoice = {
	entity: "ErpSalesInvoice",
	body: `id,refNumber,companyName,type,period,customerId,transactionId,status,statusUser,statusDate,taxPayable,netTotal,grossTotal,user,registryDate,invoiceDate,taxEntity,importId,accountId,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }`, // archivalNumber,shipFrom{deliveryId,locationId,address{detail,city,country}},shipTo{deliveryId,locationId,address{detail,city,country}},
};

export const lt_erp_sales_invoices = {
	entity: "ErpSalesInvoice",
	body: "id,refNumber,number,status,country,numberOfLines,type,user,period,invoiceDate,taxPayable,netTotal,grossTotal,numberOfLines,customerId,gltransactionId,companyName",
	xlsxExport:
		"refNumber,type,invoiceDate,grossTotal,netTotal,country,period,customerId,gltransactionId,companyName",
};

export const lt_erp_purchase_invoice = {
	entity: "ErpPurchaseInvoice",
	body: `id,refNumber,type,period,taxPayable,systemId,netTotal,grossTotal,user,invoiceDate,taxEntity,importId,supplierId,supplierRowKey,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }`,
};

export const lt_erp_purchase_invoices = {
	entity: "ErpPurchaseInvoice",
	body: "id,refNumber,number,type,user,period,systemId,invoiceDate,taxPayable,netTotal,grossTotal,supplierId,supplierRowKey",
	xlsxExport:
		"refNumber,type,period,invoiceDate,grossTotal,netTotal,supplierId",
};

export const lt_erp_tax_informations = {
	entity: "ErpTaxInformation",
	body: "purchaseInvoiceRowKey,importId,taxEntity,purchaseInvoiceId,taxType,taxCode,taxPercentage,taxBase,taxBaseDescription,taxAmount,taxAmountCurrencyCode,taxBase,taxCurrencyAmount,taxExemptionReason,taxDeclarationPeriod",
	xlsxExport:
		"taxCode,taxBaseDescription,taxCurrencyAmount,taxAmountCurrencyCode,taxAmount,taxPercentage,taxBase",
};

export const lt_erp_accounts = {
	entity: "ErpAccount",
	body: "accountId,openingDebit,openingCredit,closingDebit,closingCredit",
	xlsxExport: "id, name, username, role",
};

export const lt_erp_ship_froms = {
	entity: "ErpShipFrom",
	body: "importId,taxEntity,deliveryId,deliveryDate,warehouseId,streetName,number,city,postalCode,country,addressType,region,fullAddress,finalVersion",
	xlsxExport:
		"warehouseId,deliveryId,deliveryDate,streetName,number,postalCode,city,country",
};

export const lt_erp_ship_tos = {
	entity: "ErpShipTo",
	body: "importId,taxEntity,deliveryId,deliveryDate,warehouseId,streetName,number,city,postalCode,country,addressType,region,fullAddress,finalVersion",
	xlsxExport:
		"warehouseId,deliveryId,deliveryDate,streetName,number,postalCode,city,country",
};

export const lt_erp_purchase_invoice_line = {
	entity: "ErpPurchaseInvoiceLine",
	body: "id,lineNumber,invoiceType,quantity,unitPrice,shippingCostsAmountCurrencyCode,invoiceLineAmountCurrencyCode,invoiceUOM,debitCreditIndicator,debitAmount,creditAmount,invoiceLineAmount,invoiceLineCurrencyAmount,shippingCostsAmount,shippingCostsCurrencyAmount,invoiceDate,taxPointDate,description,accountId,productRowKey,productCode,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model },uomBaseConversionFactor",
};

export const lt_erp_purchase_invoice_lines = {
	entity: "ErpPurchaseInvoiceLine",
	body: "id,lineNumber,description,quantity,invoiceLineCurrencyAmount,shippingCostsCurrencyAmount,invoiceUOM,unitPrice,invoiceLineAmount,taxAmount,shippingCostsAmount,productCode,accountId,debitCreditIndicator",
	xlsxExport:
		"description,lineNumber,quantity,invoiceUOM,unitPrice,invoiceLineAmount,productCode,accountId,debitCreditIndicator,invoiceLineCurrencyAmount",
};

export const lt_erp_payments = {
	entity: "ErpPayment",
	body: "id,paymentRefNo,transactionId,transactionDate,paymentMethod,grossTotal,bankAccountIbanNumber,bankAccountNumber,description,systemId",
	xlsxExport:
		"paymentRefNo, transactionId, transactionDate, paymentMethod,grossTotal",
};

export const lt_erp_payment = {
	entity: "ErpPayment",
	body: "id,paymentRefNo,transactionId,grossTotal,transactionDate,paymentMethod,bankAccountIbanNumber,bankAccountNumber,description,systemId,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }",
};

export const lt_erp_payment_lines = {
	entity: "ErpPaymentLine",
	body: "id,paymentRefNo,transactionId,lineNumber,paymentLineAmount,paymentLineCurrencyAmount,paymentLineAmountCurrencyCode,sourceDocumentId,accountId,customerId,supplierId,description,debitCreditIndicator,paymentLineAmount,paymentLineAmountCurrencyCode,paymentLineCurrencyAmount",
	xlsxExport:
		"paymentRefNo, lineNumber, sourceDocumentId, accountId,paymentLineAmount,paymentLineCurrencyAmount,paymentLineAmountCurrencyCode,debitCreditIndicator",
};

export const lt_erp_payment_line = {
	entity: "ErpPaymentLine",
	body: "id,paymentRefNo,transactionId,lineNumber,sourceDocumentId,accountId,customerId,supplierId,description,debitCreditIndicator,paymentLineAmount,paymentLineAmountCurrencyCode,paymentLineCurrencyAmount,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }",
};

export const lt_erp_payment_line_analyses = {
	entity: "ErpPaymentLineAnalyse",
	body: "paymentRefNo,transactionId,lineNumber,analysisType,analysisId,analysisAmount,analysisAmountCurrencyCode,analysisCurrencyAmount",
	xlsxExport:
		"paymentRefNo,transactionId,lineNumber,analysisType,analysisId,analysisAmount,analysisAmountCurrencyCode,analysisCurrencyAmount",
};

export const lt_erp_payment_settlements = {
	entity: "ErpPaymentSettlement",
	body: "paymentRefNo,transactionId,settlementDiscount,settlementAmount,settlementAmountCurrencyCode,settlementAmountCurrencyAmount,settlementDate,paymentMechanism,debitCreditIndicator,grossTotal",
	xlsxExport:
		"paymentRefNo, analysisType, analysisId, analysisAmount, analysisAmountCurrencyCode, analysisCurrencyAmount",
};

export const lt_erp_uom_table_entries = {
	entity: "ErpUomTableEntry",
	body: "id,unitOfMeasure,description",
	xlsxExport: "unitOfMeasure,description",
};

export const lt_erp_physical_stocks = {
	entity: "ErpPhysicalStock",
	body: "id,type,status,unitMeasure,productStatus,unitPrice,openingValue,closingValue,openingQuantity,closingQuantity,productId,warehouseId",
	xlsxExport:
		"type,unitMeasure,unitPrice,openingValue,closingValue,openingQuantity,closingQuantity,productId,warehouseId,productStatus",
};

export const lt_erp_physical_stock = {
	entity: "ErpPhysicalStock",
	body: "id,type,status,unitMeasure,unitPrice,openingValue,productStatus,closingValue,openingQuantity,closingQuantity,productId,warehouseId,stockOwner,stockOwnerId,numberOfCustomsProcedures,uomBaseConversionFactor,numberOfStockCharacteristics,numberOfPhysicalStockAcquisitions,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }",
};

export const lt_erp_assets = {
	entity: "ErpAsset",
	body: "id,assetId,accountId,acquisitionDate,startUpDate,description",
	xlsxExport: "assetId,accountId,acquisitionDate,startUpDate,description",
};

export const lt_erp_asset = {
	entity: "ErpAsset",
	body: "id,assetId,accountId,acquisitionDate,startUpDate,description,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }",
};

export const lt_erp_analyses_type_entries = {
	entity: "ErpAnalysisType",
	body: "analysisId,analysisIdDescription,analysisType,analysisTypeDescription,stiAnalysisId",
	xlsxExport:
		"analysisId,analysisIdDescription,analysisType,analysisTypeDescription",
};

export const lt_erp_valuations = {
	entity: "ErpAssetValuation",
	body: "id,assetId,valuationClass,accountId,assetLifeYears,assetLifeMonths",
	xlsxExport: "assetId,valuationClass,accountId,assetLifeYears,assetLifeMonths",
};

export const lt_erp_asset_suppliers = {
	entity: "ErpAssetSupplier",
	body: "id,supplierName,supplierId,addressType,streetName,addressNumber,fullAddress,postalCode,city,country,region",
	xlsxExport:
		"supplierName,supplierId,streetName,postalCode,city,country,region",
};

export const lt_erp_movement_types = {
	entity: "ErpMovementType",
	body: "movementType,entity,description",
	xlsxExport: "movementType,description",
};

export const lt_erp_valuation = {
	entity: "ErpAssetValuation",
	body: "assetId,valuationType,valuationClass,accountId,aquisitionAndProductionCost,aquisitionAndProductionCostSe,assetLifeYears,assetLifeMonths,bookValueBegin,depreciationForPeriod,bookValueEnd,acucumulatedDepreciation",
};

export const lt_erp_asset_transactions = {
	entity: "ErpAssetTransaction",
	body: "id,transactionType,assetTransactionId,description,period,assetTransactionDate,supplierId,supplierName,transactionId,transactionAmount,assetId,debitCreditIndicator",
	xlsxExport:
		"transactionType,assetTransactionId,period,assetTransactionDate,transactionId",
};

export const lt_erp_asset_valuation_appreciations = {
	entity: "ErpAssetValuationAppreciation",
	body: "depreciationMethod,debitCreditIndicator,accountId,appreciationDate,depreciationPercentage,appreciation,depreciationPeriod",
};

export const lt_erp_asset_valuation_extra_depreciations = {
	entity: "ErpAssetValuationExtraDepreciation",
	body: "depreciationMethod,accountId,depreciation",
};

export const lt_erp_asset_valuation_impairments = {
	entity: "ErpAssetValuationImpairment",
	body: "accountId,impairmentDate,eliminationDate,impairment,elimination",
};

export const lt_erp_asset_transaction = {
	entity: "ErpAssetTransaction",
	body: "id,transactionType,assetTransactionId,description,period,assetTransactionDate,supplierId,supplierName,transactionId,transactionAmount,assetId,debitCreditIndicator,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }",
};

export const lt_erp_asset_transaction_valuations = {
	entity: "ErpAssetTransactionValuation",
	body: "transactionType,assetTransactionId,period,assetTransactionDate,transactionId,transactionAmount,bookValueOnTransaction,acquisitionAndProductionCostSo,debitCreditIndicator",
	xlsxExport:
		"transactionType,assetTransactionId,period,assetTransactionDate,transactionId,transactionAmount,debitCreditIndicator",
};

export const lt_erp_settlements = {
	entity: "ErpSettlement",
	body: "settlementDiscount,invoiceType,settlementAmount,settlementAmountCurrencyCode,settlementCurrencyAmount,settlementDueDate,settlementDate,paymentMechanism,debitCreditIndicator",
	xlsxExport:
		"settlementAmountCurrencyCode,settlementDiscount,settlementDueDate,settlementDate,paymentMechanism,debitCreditIndicator,settlementAmount,settlementCurrencyAmount,invoiceType",
};

export const lt_erp_credit_notes = {
	entity: "ErpCreditNote",
	body: "reference,reason",
	xlsxExport: "reference,reason",
};

export const lt_erp_order_reference = {
	entity: "ErpOrderReference",
	body: "originatingOn,orderDate",
};

export const lt_erp_delivery = {
	entity: "ErpDelivery",
	body: "movementReference,deliveryDate,deliveryPeriodFromDate,deliveryPeriodToDate",
};

export const lt_erp_analysis = {
	entity: "ErpAnalysis",
	body: "analysisId,analysisType,analysisAmountCurrencyCode,analysisAmount,analysisCurrencyAmount",
	xlsxExport:
		"analysisId,analysisType,analysisAmountCurrencyCode,analysisAmount,analysisCurrencyAmount",
};

export const lt_erp_general_ledger_entry_analyses = {
	entity: "ErpGeneralLedgerEntryAnalyses",
	body: "analysisId,analysisType,currencyCode,amount,currencyAmount",
	xlsxExport: "analysisId,analysisType,currencyCode,amount,currencyAmount",
};

export const lt_erp_acquisitions = {
	entity: "ErpAcquisition",
	body: "supplierRegistrationNumber,supplierName,dateOfAcquisition,invoiceNo,invoiceDate,glPostingDate,acquiredQuantity,stockRemainderQuantity,stockRemainderAmount",
	xlsxExport:
		"supplierName,supplierRegistrationNumber,invoiceNo,dateOfAcquisition,invoiceDate,glPostingDate,acquiredQuantity,stockRemainderQuantity,stockRemainderAmount",
};

export const lt_erp_characteristics = {
	entity: "ErpCharacteristic",
	body: "characteristic,characteristicValue",
	xlsxExport: "characteristic,characteristicValue",
};

export const lt_erp_customs_procedures = {
	entity: "ErpCustomsProcedure",
	body: "customsProcedure,authorizationDate,authorizationNumber,authorizationGoodsNomenclatureCode",
	xlsxExport:
		"customsProcedure,authorizationDate,authorizationNumber,authorizationGoodsNomenclatureCode",
};

export const lt_erp_movement_of_goods = {
	entity: "ErpMovementOfGood",
	body: "id,movementReference ,movementDate ,movementPostingDate ,taxPointDate,movementType,systemId,documentType,documentNumber,documentLine,rowKey,period",
	xlsxExport: "movementReference ,movementDate ,movementType,period",
};

export const lt_erp_movement_of_good = {
	entity: "ErpMovementOfGood",
	body: "id,movementReference ,movementDate ,movementPostingDate ,taxPointDate,movementType,systemId,documentType,documentNumber,documentLine,rowKey,period,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }",
};

export const lt_erp_movement_of_good_lines = {
	entity: "ErpMovementOfGoodLine",
	body: "id,movementReference,movementDate,movementType,lineNumber,accountId,transactionId,customerId,supplierIdd,productCode,quantity,unitOfMeasure,uomtoUomphysicalStockConversionFactor,bookValue,movementSubType,movementComments,movementsType,debitCreditIndicator,rowKey",
	xlsxExport:
		"lineNumber, transactionId, quantity, bookValue,debitCreditIndicator",
};

export const lt_erp_movement_of_good_line = {
	entity: "ErpMovementOfGoodLine",
	body: "id,movementReference,movementDate,movementType,lineNumber,accountId,transactionId,customerId,supplierIdd,productCode,quantity,unitOfMeasure,uomtoUomphysicalStockConversionFactor,bookValue,movementSubType,movementComments,movementsType,debitCreditIndicator,rowKey,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }",
};

export const lt_erp_movement_of_good_line_ship_tos = {
	entity: "ErpMovementOfGoodLineShipTo",
	body: "id,movementReference,movementDate,movementType,lineNumber,deliveryId,deliveryDate,warehouseId,streetName,number,city,postalCode,country,addressType,region,fullAddress",
	xlsxExport:
		"movementDate, deliveryId, deliveryDate, warehouseId, fullAddress, streetName, number, city, postalCode, country",
};

export const lt_erp_movement_of_good_line_ship_to = {
	entity: "ErpMovementOfGoodLineShipTo",
	body: "id,movementReference,movementDate,movementType,lineNumber,deliveryId,deliveryDate,warehouseId,streetName,number,city,postalCode,country,addressType,region,fullAddress",
};

export const lt_erp_movement_of_good_line_ship_froms = {
	entity: "ErpMovementOfGoodLineShipFrom",
	body: "id,movementReference,movementDate,movementType,lineNumber,deliveryId,deliveryDate,warehouseId,streetName,number,city,postalCode,country,addressType,region,fullAddress",
	xlsxExport:
		"movementDate, deliveryId, deliveryDate, warehouseId, fullAddress, streetName, number, city, postalCode, country",
};

export const lt_erp_movement_of_good_line_ship_from = {
	entity: "ErpMovementOfGoodLineShipFrom",
	body: "id,movementReference,movementDate,movementType,lineNumber,deliveryId,deliveryDate,warehouseId,streetName,number,city,postalCode,country,addressType,region,fullAddress",
};

export const lt_erp_movement_of_good_line_tax_informations = {
	entity: "ErpMovementOfGoodLineTaxInformation",
	body: "id,movementReference,movementDate,movementType,lineNumber,taxType,taxCode,taxPercentage,taxBase,taxBaseDescription,taxAmount,taxAmountCurrencyCode,taxCurrencyAmount,taxExemptionReason,taxDeclarationPeriod",
	xlsxExport:
		"taxType,taxCode,taxPercentage,taxBase,taxBaseDescription,taxAmount,taxAmountCurrencyCode,taxCurrencyAmount",
};

export const lt_erp_movement_of_good_line_tax_information = {
	entity: "ErpMovementOfGoodLineTaxInformation",
	body: "id,movementReference,movementDate,movementType,lineNumber,taxType,taxCode,taxPercentage,taxBase,taxBaseDescription,taxAmount,taxAmountCurrencyCode,taxCurrencyAmount,taxExemptionReason,taxDeclarationPeriod",
};

export const lt_erp_owners = {
	entity: "ErpOwner",
	body: "id,ownerId,ownerName,accountId,streetName,number,city,PostalCode,country,addressType,region,fullAddress,sharesQuantity,sharesAmount,sharesType,sharesAcquisitionDate,sharesTransfersDate",
	xlsxExport:
		"ownerId, ownerName, country, sharesQuantity, sharesAmount, sharesAcquisitionDate, sharesTransfersDate",
};

export const lt_erp_owner = {
	entity: "ErpOwner",
	body: "id,ownerId,ownerName,accountId,streetName,number,city,PostalCode,country,addressType,region,fullAddress,sharesQuantity,sharesAmount,sharesType,sharesAcquisitionDate,sharesTransfersDate",
};

/*
##########################
#### LT ERP FRAGMENTS ####
##########################
*/

//************************************************************************************************************************************

/*
##########################
##### ISAFLt LT FRAGMENTS ######
#########################
*/
export const i_saf_lt_customers = {
	entity: "ISafLtCustomer",
	body: "id,customerId,name,registrationNumber,taxEntity",
};

export const i_saf_lt_customer = {
	entity: "ISafLtCustomer",
	body: "id,customerId,name,country,registrationNumber,vatRegistrationNumber,taxEntity",
};

export const i_saf_lt_suppliers = {
	entity: "ISafLtSupplier",
	body: "id,supplierId,name,registrationNumber,taxEntity",
};

export const i_saf_lt_supplier = {
	entity: "ISafLtSupplier",
	body: "id,supplierId,name,country,registrationNumber,vatRegistrationNumber,taxEntity",
};

export const i_saf_lt_headers = {
	entity: "ISafLtHeader",
	body: "id,companyRegistrationNumber,softwareCompanyName,auditFileVersion,selectionCriteriaStartDate,selectionCriteriaEndDate,numberOfMasterFilesCustomers,numberOfMasterFilesSuppliers,numberOfSourceDocumentsPurchaseInvoices,numberOfSourceDocumentsSalesInvoices,numberOfSettlementAndPayments,fileName,fileId",
};

export const i_saf_lt_header = {
	entity: "ISafLtHeader",
	body: "taxEntity,auditFileVersion,auditFileDateCreated,dataType,softwareCompanyName,softwareName,softwareVersion,companyRegistrationNumber,numberOfParts,partNumber,selectionCriteriaStartDate,selectionCriteriaEndDate,numberOfMasterFilesCustomers,numberOfMasterFilesSuppliers,numberOfSourceDocumentsPurchaseInvoices,numberOfSourceDocumentsSalesInvoices,numberOfSettlementAndPayments,",
};

export const i_saf_lt_sales_invoices = {
	entity: "ISafLtSalesInvoice",
	body: "id,invoiceNo,invoiceType,invoiceDate,customerId,customerName,customerInfoCountry,vatpointDate,specialTaxation",
};

export const i_saf_lt_sales_invoice = {
	entity: "ISafLtSalesInvoice",
	body: "taxEntity,invoiceNo,invoiceType,invoiceDate,customerId,customerName,customerInfoCountry,vatpointDate,specialTaxation,customerInfoVatRegistrationNumber,customerInfoRegistrationNumber,numberOfReferences,numberOfDocumentTotals,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }",
};

export const i_saf_lt_document_totals = {
	entity: "ISafLtDocumentTotal",
	body: "taxableValue,taxCode,taxPercentage,amount,vatPointDate2",
};

export const i_saf_lt_purchase_document_totals = {
	entity: "ISafLtPurchaseDocumentTotal",
	body: "taxableValue,taxCode,taxPercentage,amount",
};

export const i_saf_lt_references = {
	entity: "ISafLtReference",
	body: "referenceNo,referenceDate",
};

export const i_saf_lt_purchase_invoices = {
	entity: "ISafLtPurchaseInvoice",
	body: "id,invoiceNo,invoiceType,invoiceDate,supplierInfoSupplierId,supplierName,supplierInfoCountry,vatpointDate,specialTaxation",
};

export const i_saf_lt_purchase_invoice = {
	entity: "ISafLtPurchaseInvoice",
	body: "taxEntity,invoiceNo,invoiceType,invoiceDate,supplierInfoSupplierId,supplierName,supplierInfoCountry,vatpointDate,specialTaxation,supplierInfoVatRegistrationNumber,supplierInfoRegistrationNumber,numberOfReferences,numberOfDocumentTotals,registrationAccountDate,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }",
};

export const i_saf_lt_settlement_and_payments = {
	entity: "ISafLtSettlementAndPayment",
	body: "id,settlementRefNo,settlementType,settlementDate,paymentSupplierCustomerId,paymentName",
};

export const i_saf_lt_settlement_and_payment = {
	entity: "ISafLtSettlementAndPayment",
	body: "settlementRefNo,settlementType,settlementDate,paymentSupplierCustomerId,paymentVatRegistrationNumber,paymentRegistrationNumber,paymentCountry,paymentName,numberOfReferencesToInvoices,entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }",
};

export const i_saf_lt_s_p_reference_to_invoices = {
	entity: "ISafLtSPReferenceToInvoice",
	body: "invoiceNo,invoiceDate,amount,vatAmount",
};

/*
##########################
##### ISAFLt LT FRAGMENTS ######
#########################
*/

//************************************************************************************************************************************

/*
##########################
##### MOD 30 FRAGMENTS ######
#########################
*/

export const pt_mod_30_file = {
	entity: "HeaderMod30",
	body: `
		taxRegistrationNumber,
		tocTaxRegistrationNumber,
		periodYear,
		sfCode,
		typeOfDeclaration,
		periodMonth,
		socialParticipationWithheldTaxAmount,
		capitalApplicationWithheldTaxAmount,
		royaltiesWithheldTaxAmount,
		employmentWithheldTaxAmount,
		selfEmploymentWithheldTaxAmount,
		commissionsWithheldTaxAmount,
		propertiesWithheldTaxAmount,
		pensionsWithheldTaxAmount,
		servicesProvisioningWithheldTaxAmount,
		publicRenumerationsWithheldTaxAmount,
		publicPensionsWithheldTaxAmount,
		othersWithheldTaxAmount,
		numberOfTaxPaymentSlip,
		numberOfIncomeBeneficiaries,
		entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }`,
};

export const pt_mod_30_income_beneficiaries = {
	entity: "IncomeBeneficiariesMod30",
	body: `
		headerId,
		ptTaxRegistrationNumber,
		taxRegistrationNumber,
		residencyCountryCode,
		shareCapitalPercentage,
		taxablePersonShareCapitalPercentage,
		incomeType,
		incomeAmount,
		taxCode,
		taxPercentage,
		withheldTaxAmount,
		issuerTaxRegistrationNumber
	`,
};

export const pt_mod_30_tax_payment_slip = {
	entity: "TaxPaymentSlipMod30",
	body: `
	id,
	headerId,
	taxPaymentSlipId,
	taxPaymentSlipAmount`,
};

/*
##########################
##### MOD 30 FRAGMENTS ######
#########################
*/

// **********************************************************************************

/*
##########################
##### MOD 22 FRAGMENTS ######
#########################
*/

export const pt_mod_22_cover_page = {
	entity: "CoverPageMod22",
	body: `
		declarationType,
		recordType,
		nif,
		periodFrom,
		periodTo,
		exercise,
		currencyIndicator,
		board003CDigoDoServiODeFinanAs,
		board003TipoDeSujeitoPassivo,
		board003Geral,
		board003NifDaSociedadeDominanteResponsVelArt69ANS3E4,
		board003PretendeExercerAOpOPelasTaxasDoArt87N1,
		board003OcorreuAlgumaDasSituaEsReferidasNoArt87N7,
		board003DataDeclaraODeSubstituiOArt122N3,
		board003TipoDeDeclaraO,
		board003DeclaracaoDoGrupo,
		board003DataDaTransmissOAquisiOEntidadesNOResidentesSemEstabEstVel,
		board003DataDaCessaO,
		board003AnexoARegimeSimplificadoIndicadorDeAnexoAnteriorA2010,
		board003AnexoBRegimeSimplificadoIndicadorDeAnexoAnteriorA2010,
		board003AnexoCRegiEsAutNomasIndicadorDeAnexo,
		board003AnexoDBenefCiosFiscais,
		board003NifDoRepresentanteLegal,
		board003NifDoContabilistaCertificado,
		board003QualificaODaEmpresaNosTermos,
		board003TransfDeResidCessaOActivArtS8384E54AN11,
		board003AnexoERegimeSimplificadoIndicadorDeAnexoPosteriorA2013,
		board003IndiqueQueSeTrataDeUmOrgDeInvColTributadoNosTermosDoArt22DoEbf,
		board003Artigo36ADoEbf,
		board003AnexoFOic,
		board003AntesDissolucao,
		board003DataDaDissoluO,
		board003ConsideradoUmEstabelecimentoEstVelParaEfeitosDeImputaO,
		board003RegimeEspecialAtividadesDeTransporteMarTimoDecLeiN,
		board003AnexoGTransporteMarTimo,
		board003IsencaoDefinitiva,
		board003IsencaoTemporaria,
		board003ReducaoTaxa,
		board003Simplificado,
		board003TransparenciaFiscal,
		board003GruposDeSociedades,
		board003DeclaracaoDoPeriodoDeLiquidacao,
		board003DeclaracaoDoPeriodoDeCessacao,
		board003AposAAlteracao,
		board003DeclaracaoDoPeriodoDoInicioDeAtividade,
		board003AntesDaAlteracao,
		board003AposDissolucao,
		board008EstabelecimentosDeEnsinoParticularExArt56DoEbf,
		board008BenefCiosRelativosInterioridadeArt41BEExArt43DoEbf,
		board008AntigoEstatutoFiscalCooperativoArt7N3DaLei859816Dez,
		board008EntidadesLicenciadasNaZonaFrancaDaMadeiraExArt35DoEbf,
		board008RegiOAutNomaAOresDecLegRegionalN21999A201,
		board008RegiOAutNomaMadeiraDecLegRegionalN22001M202Nova,
		board008RendimentosPrediaisDeEntidadesArt87N4,
		board008MaisValiasImobiliRiasIncrementosPatrimoniaisObtidosPorEntidadesNOResid,
		board008OutrosRendimentosObtidosPorEntidadesNOResidentesSem,
		board008EntidadesLicenciadasNaZonaFrancaDaMadeiraArt36E36ADo,
		board0081PrejuZoFiscalRegimeGeral,
		board0082LucroTributVelRegimeGeral,
		board008PrejuZosFiscaisDedutVeisRegimeGeral,
		board008BenefCiosFiscaisRegimeGeral,
		board0084MatRiaColectVel23RegimeGeral,
		board0082LucroTributVelReduOTaxa,
		board0081PrejuZoFiscalReduOTaxa,
		board008MaisValiasMobiliRiasObtidasPorEntidadesNOResidentesSemEstab,
		board008RendDecorrentesDaAlienDeUnidadesArt22AN1AlCDo,
		board008AjustamentoReaidArt5N1BAnexoLeiN6120142608,
		board008MatRiaColetVelDoRegimeEspecialC11DeQ04DoAnexoG,
		board009PrejuZosFiscaisDedutVeisRedTaxa,
		board009BenefCiosFiscaisRedTaxa,
		board0094MatRiaColectVel23RedTaxa,
		board0091PrejuZoFiscalComIsenO,
		board0092LucroTributVelComIsenO,
		board009PrejuZosFiscaisDedutVeisComIsenO,
		board009ZfmMatColetVelQueExcedeArtS36N3E36AN4Do,
		board0132LucroTributVelRegimesSimplificadoEmVigorAt2010,
		board0133DeduEsPrejFiscaisDeduzidosRegSimpEmVigorAt2010,
		board013BenefCiosFiscaisRegSimpEmVigorAt2010,
		board013TotalDoValorUtilizadoNoPerOdo397A397B,
		board0134MatRiaColectVel23RegSimpEmVigorAt2010,
		board010BenefCiosFiscaisComIsenO,
		board0103DeduEsPrejFiscaisDeduzidosComIsenO,
		board0104MatRiaColectVel23ComIsenO,
		board010MatRiaColectVelTotal311399322336Ou409Ou,
		board010SomaAlgBricaDosResultFinaisRegEspGrupSoc,
		board010LucrosDistribuDosExArt70N2RegEspGrupSoc,
		board010ResultadoFiscalDoGrupo,
		board010TotalDeRendimentosDoPerOdo,
		board010VolumeDeNegCiosDoPerOdoARepartirNoQuadro11BSeForCaso,
		board010ResultadosInternosEliminadosAoAbrigoDoAnteriorRtlcAIncluirNo,
		board028ColetividadesDesportivasDeduODasImportNcias,
		board028GastosDeFinanciamentoLQuidosOpOPrevistaNoArt67N5,
		board028ModalidadeDePagamentoDoImpostoCorrespondenteArt83N2,
		board028IrcDerramaEstadualValorDoPagamentoDiferidoOuFracionado,
		board028TotalDePagamentosDiferidosOuFracionados377A377B,
		board028DerramaMunicipalValorDoPagamentoDiferidoOuFracionado,
		board028TotalAPagar3673770,
		board028TotalARecuperar367Ou3683770,
		board015PrejFiscaisAutorizadosTransmitidosArt75NS1E3RegGeral,
		board015PrejuZosFiscaisNODedutVeisRegGeral,
		board015PrejuZosFiscaisNODedutVeisRegRedTx,
		board015PrejFiscaisAutorizadosTransmitidosArt75NS1E3RegRedTx,
		board015PrejFiscaisAutorizadosTransmitidosArt75NS1E3ComIsenO,
		board015PrejFiscaisAutorizadosTransmitidosArt75NS1E3RegSimp,
		board015PrejuZosFiscaisNODedutVeisRegSimpEmVigorAt2010,
		board015PrejuZosFiscaisNODedutVeisComIsenO,
		board011DiferenAPositivaEntreOValorConsideradoParaEfeitosDeLiquidaO,
		board011JurosCompensatRiosDeclaradosPorAtrasoNaEntregaDaDeclaraO,
		board011JurosCompensatRiosPorOutrosMotivos,
		board022TratandoSeDeMicroentidadeIndiqueSeOptaPelaAplNormasContabE,
		board022OcorreuNoPerOdoDaTributaOUmaOperaODeFusOComEficCia,
		board014AidDePerdasPorParidadeEmCrDitos,
		board014AidDeBenefCiosPSEmpregoOuALongoPrazoDeEmpregados,
		board014OutrosAid,
		board014CapitalPrPrio,
		board014CrDitoTributRio,
		board014DataDeEntradaEmLiquidaO,
		board022VolumeGlobalDeNegCiosNOIsento,
		board022VolumeDeNegCiosNOIsentoRam,
		board022VolumeDeNegCiosNOIsentoRaa,
		board022RCio1RamCampo2Campo1,
		board022RCio2RaaCampo3Campo1,
		board022RCio3Cont1RCio1RCio2,
		board030TotalDoCidtjiComCdtSaldoNODeduzido,
		board030TotalDoCidtjiComCdtCrDitoDoImpostoNoPerOdo,
		board030TotalDoCidtjiComCdtDeduOEfetuadaNoPerOdo,
		board030TotalDoCidtjiComCdtSaldoQueTransita,
		board030TotalDoCidtjiSemCdtSaldoNODeduzido,
		board030TotalDoCidtjiSemCdtCrDitoDoImpostoNoPerOdo,
		board030TotalDoCidtjiSemCdtDeduOEfetuadaNoPerOdo,
		board030TotalDoCidtjiSemCdtSaldoQueTransita,
		board030TotalDoCidtjiSaldoNODeduzido,
		board030TotalDoCidtjiCrDitoDoImpostoNoPerOdo,
		board030TotalDoCidtjiDeduOEfetuadaNoPerOdo,
		board030TotalDoCidtjiSaldoQueTransita,
		entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }
	`,
};

export const pt_mod_22_taxable_income_calculation = {
	entity: "KeyValueMod22",
	body: `
		value,
		description
	`,
};

export const pt_mod_22_autonomous_taxes = {
	entity: "KeyValueMod22",
	body: `
		value,
		description
	`,
};

export const pt_mod_22_tax_calculation_10 = {
	entity: "KeyValueMod22",
	body: `
		value,
		description
	`,
};

export const pt_mod_22_autonomous_taxes_madeira = {
	entity: "KeyValueMod22",
	body: `
		value,
		description
	`,
};

export const pt_mod_22_board_024_396_expenses = {
	entity: "Expenses",
	body: `
		nif,
		value,
		period
	`,
};

export const pt_mod_22_board_029_398_expenses = {
	entity: "Expenses",
	body: `
		nif,
		value,
		period
	`,
};

export const pt_mod_22_board_025_397_a_expenses = {
	entity: "Expenses",
	body: `
		nif,
		value,
		period
	`,
};

export const pt_mod_22_board_026_397_b_expenses = {
	entity: "Expenses",
	body: `
		nif,
		value,
		period
	`,
};

export const pt_mod_22_board_034_309_1_expenses = {
	entity: "Expenses",
	body: `
		nif,
		value,
		period
	`,
};

export const pt_mod_22_board_035_320_1_expenses = {
	entity: "Expenses",
	body: `
		nif,
		value,
		period
	`,
};

export const pt_mod_22_board_036_331_1_expenses = {
	entity: "Expenses",
	body: `
		nif,
		value,
		period
	`,
};

export const pt_mod_22_retencao_na_fonte = {
	entity: "Expenses",
	body: `
		nif,
		value,
		period
	`,
};

export const pt_mod_22_credit_table_014 = {
	entity: "TaxCreditMod22",
	body: `
		country_code
		income_type
		balance_not_deferred
		foreign_paid_tax
		low_tax_obtained_abroad
		period_imposed_credit
		deduce_in_period
		balance_which_transits
	`,
};

export const pt_mod_22_annex_a = {
	entity: "AnnexAHeaderMod22",
	body: `
		nif,
		period,
		board602LucroTributVelTotalCampo302313DoQ09Art36ADoEbf4,
		board602LucroTributVelNaZonaFrancaDaMadeiraCampo313DoQ0916,
		board602MassaSalarialTotal28,
		board602O1AnoDeAplicaODoRegime40,
		board602TotalDaReaDeInstalaOOuExploraOHa241,
		board602TotalDaPotNciaInstaladaMw53,
		board602TotalDaEletricidadeProduzidaGwh65,
		board602TotalDaProduOBocaDaMinaEmEuros77,
		board602TotalDaMassaSalarialPrestaEsDeServiOs89,
		board602SomaAlgBricaMatRiaColetVelRegEspecialELucroTributVelRegGeral101,
		board699DerramaCalculadaATransportarParaC364DoQ10DaDeclaraO10,
		board699DerramaCalculadaATransportarParaC364DoQ10DaDeclaraO22,
		board699DerramaCalculadaATransportarParaC364DoQ10DaDeclaraO34,
		board699DerramaCalculadaATransportarParaC364DoQ10DaDeclaraO46,
		board699CritRioGeralMassaSalarialTotal58,
		board699CritRioGeralZfmMassaSalarialTotal70,
		entities(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ entity, count, model }
	`,
};

export const pt_mod_22_annex_c = {
	entity: "AnnexCHeaderMod22",
	body: `
		nif,
		period,
		board302MatColectC311C366ExetoC300DoQ09DaDeclOuC42DoAnxE4,
		board304MatRiaColetVelRegimeEspecial4,
		board302ColetaSePmeAt1500000X1728,
		board303ColetaSePme15000RostoQ11BC61500021OuGeRostoQ1164,
		board304ColetaDoRegimeEspecial16,
		board302ColetaDaRamSePmeAt15000RostoQ11BC4XC7A67,
		board303ColetaDaRamSePme15000RostoQ11BC4C7BOu76,
		board304ColetaEspecialDaRam28,
		board302ColetaDaRaaSePmeAt15000RostoQ11BC5MontRostoQ1155,
		board303ColetaDaRaaSePme15000RostoQ11BC5xRostoQ11BC688,
		board304ColetaEspecialDaRaa40,
		board302ColetaDoContSePmeAt15000RostoQ11BC22XC7A79,
		board303ColetaDoContSePme15000RostoQ11BC22xc7BOuGe100,
		board304ColetaEspecialDoContinente52,
		board302MatRiaColetVelC311C399OuC322OuC409DoQ09DaDecl16,
		board302TaxaColetaC11xTaxa40,
		board302ColetaC11xTaxa43,
		board302ColetaDaRamRostoQ11BC4XC12ATranspPC37091,
		board302ColetaDaRaaRostoQ11BC5XC12X08ATranspPC350103,
		board302ColetaDoContRostoQ11BC22XC12ATranspPC349115,
		board303MatRiaColetVelC311DoQ09DaDecl4,
		board303ColetaSePmeAt15000x1716,
		board303ColetaSePme15000C1615000X21OuGeC16x21112,
		board303ColectaDaRamSePmeAt15000RostoQ11BC4xc17A28,
		board303ColetaDaRamSePme15000RostoQ11BC4C17BOu124,
		board303ColetaDaRaaSePmeAt15000RostoQ11BC5xc17A40,
		board303ColetaDaRaaSePme15000RostoQ11BC5xc17BOu136,
		board303ColetaDoContSePmeAt15000RostoQ11BC22xc17A52,
		board303ColetaDoContSePmeAt15000RostoQ11BC22xc17BOu148,
	`,
};

export const pt_mod_22_general_criterion_4a = {
	entity: "MunicipalSurtaxGeneral",
	body: `
		record_type,
		district_code,
		salary_mass,
		taxa_da_derrama,
		municipality_ratio,
		municipal_surtax
	`,
};

export const pt_mod_22_general_criterion_4b = {
	entity: "MunicipalSurtaxGeneral",
	body: `
		record_type,
		district_code,
		salary_mass,
		taxa_da_derrama,
		municipality_ratio,
		municipal_surtax
	`,
};

export const pt_mod_22_specific_criterion_4c = {
	entity: "MunicipalSurtaxSpecific",
	body: `
		isFirstYearOfApplication,
		record_type,
		district_code,
		salary_mass,
		taxa_da_derrama,
		product,
		municipality_ratio,
		municipal_surtax,
		municipal_area_of_operation,
		installed_power,
		total_produced_electricity
	`,
};

export const pt_mod_22_specific_criterion_4d = {
	entity: "MunicipalSurtaxSpecific",
	body: `
		isFirstYearOfApplication,
		record_type,
		district_code,
		salary_mass,
		taxa_da_derrama,
		product,
		municipality_ratio,
		municipal_surtax,
		municipal_area_of_operation,
		installed_power,
		total_produced_electricity
	`,
};

export const pt_mod_22_annex_d = {
	entity: "AnnexDHeaderMod22",
	body: `
		nif,
		period,
		board404MaisValiasNOTributadasExArt32N2EExArt32AN1Do4,
		board404MenosValiasFiscaisNODedutVeisExArt32N2EExArt32AN16,
		board404DataDoLicenciamento28,
		board404NDePostosDeTrabalhoCriadosNos1S6MesesEMantidosNoPer36,
		board404InvestimentoEfetuadoNaAquisiODeAtivosFixosTangEIntang41,
		board404ProjetosDeInvInternacionalizaOSaldoNODeduzido55,
		board404ProjetosDeInvInternacionalizaODotaODoPerOdo67,
		board404ProjetosDeInvInternacionalizaODeduODoPerOdo79,
		board404ProjetosDeInvInternacionalizaOSaldoQueTransita91,
		board404DeduEsMatColetSaldoNODeduzido115,
		board404DeduEsMatColectDeduODoPerOdo139,
		board404DeduEsMatColectSaldoQueTransitaParaPerOdosSeg151,
		board404DeduEsMatColectDotaODoPerOdo127,
		board405EntidadeLicenciadasNaZonaFrancaDaMadeira36AN6DoEbf16,
		board405IncentivosFiscaisAosLucrosReinvestidosNaRam4,
		board405SocDeCapitalDeRiscoScrEInvestidoresDeCapitalDeRiscoIcr28,
		board405TotalDeDeduEs52,
		board40540,
		board407N2IncentivosDeAnosAnterioresAno4,
		board407N2IncentivosDeAnosAnterioresValor8,
		board407N1IncentivosDeAnosAnterioresAno20,
		board407IncentivoDeNaturezaNOFiscal36,
		board407N1IncentivosDeAnosAnterioresValor24,
		board407TotalDeIncentivosDoTriNio90190290390460,
		board407IrcARegularizar73,
		board407TotalDosIncentivosDoAnoDeNatFiscal904A904B904C904D48,
		board407InvestimentosElegVeisIntangVel97,
		board407InvestimentosElegVeisTangVel85,
		board407InvestimentosElegVeisTotal109,
		board407ReduODeEncargosComASegSocX1TxDoIrc121,
		board407MajoraODasDepreciaEsMajoraO133,
		board407MajoraODasDepreciaEsTaxaDoIrc145,
		board407MajoraODasDepreciaEsValorDoAuxLio149,
		board408MajoraOComOsEncargosComASegSocialMajoraO4,
		board408MajoraOComOsEncargosComASegSocialTaxaDoIrc16,
		board408MajoraODoCrDitoFiscalAoInvestimento32,
		board408TotalDosAuxLios1004100710101011101256,
		board408TaxaMXimaLegalAplicVel73,
		board408ExcessoARegularizar77,
		board408IncentivosFiscAosLucrosReinvestidosNaRaaArt6DeDl89,
		board408DeduOPorLucrosRetidosEReinvestidosPelasPmeEArtS27A34Do101,
		board408TaxaDeAuxLio69,
		board408MajoraOComOsEncargosComASegSocialValorDoAuxLio20,
		board408Outros44,
		board409CrDitoFiscalExtraordinRioAoInvestimentoSaldoNODeduzido16,
		board409CrDitoFiscalExtraordinRioAoInvestimentoSaldoNODeduzido28,
		board409CrDitoFiscalExtraordinRioAoInvestimentoDeduODoPerOdo40,
		board409RemuneraOConvencionalDoCapitalSocial64,
		board409ReduODeTaxaDoIrcAplicVelSPmeAos1S15000DeMat76,
		board409ReduODeTaxaBenefCiosInterioridade88,
		board409DespesasComProjetoDeInvestimentoProdutivo100,
		board409DerramaMunicipal112,
		board409CrDitoFiscalExtraordinRioAoInvestimentoSaldoQueTransita52,
		board419NDePostosDeTrabCriadosMantNoInCioDoPerOdoDeTributaO64,
		board419NDePostosDeTrabCriadosMantNoFinalDoPerOdoDeTributaO69,
		board420DeduODe50ColetaPelasEntidadesArt36AN6DoEbf40,
		board420IndiqueSeSeQualificaComoMicroentidadeNosTermos52,
		board427BenefCiosFiscaisContratuaisAoInvestimentoTotalSaldoNO4,
		board427BenefCiosFiscaisContratuaisAoInvestimentoTotalDotaODo18,
		board427BenefCiosFiscaisContratuaisAoInvestimentoTotalDeduODo32,
		board427BenefCiosFiscaisContratuaisAoInvestimentoTotalSaldoQue46,
		board427SifideTotalSaldoNODeduzidoNoPerOdoAnterior60,
		board427SifideTotalDotaODoPerOdo74,
		board427SifideTotalDeduODoPerOdo88,
		board427SifideTotalSaldoQueTransitaParaPerOdoSSeguinteS102,
		board427RegimeFiscalDeApoioAoInvestimentoTotalSaldoNODeduzidoNo116,
		board427RegimeFiscalDeApoioAoInvestimentoTotalDotaODoPerOdo130,
		board427RegimeFiscalDeApoioAoInvestimentoTotalDeduODoPerOdo144,
		board427RegimeFiscalDeApoioAoInvestimentoTotalSaldoQueTransitaPara158,
		board429IfpcTotalSaldoNODeduzidoNoPerOdoAnterior4,
		board429IfpcTotalValorDoIncentivoNoPerOdo18,
		board429IfpcTotalDeduODoPerOdo32,
		board429IfpcTotalSaldoQueTransitaParaPerOdoSSeguinteS46,
		board429IfpcTotalValorAReembolsar60,
		board429IfpcTotalValorARepRATransportarParaOC372DoQ10Da74,
		board434BeneficiosFiscaisContratAoInvTotalSaldoCaducado4,
		board434RetgsInfAdicionalTotalSaldoCaducadoRealDeclGrupo18,
		board434RetgsInfAdicionalTotalSaldoNODeduzidoColGrupo32,
		board434RetgsInfAdicionalTotalDotaODoPerOdoDeclGrupo46,
		board434RetgsInfAdicionalTotalDeduODeclDeGrupo60,
		board434RetgsInfAdicionalTotalSaldoQueTransitaDeclGrupo74,
		board436RetgsInfAdicionalTotalSaldoCaducadoRealDeclGrupo18,
		board436RetgsInfAdicionalTotalSaldoNODeduzidoColGrupo32,
		board436RetgsInfAdicionalTotalDotaODoPerOdoDeclGrupo46,
		board436RetgsInfAdicionalTotalDeduODeclGrupo60,
		board436RetgsInfAdicionalTotalSaldoQueTransitaDeclGrupo74,
		board438RegimeFiscalDeApoioAoInvestimentoTotalSaldoCaducado4,
		board438RetgsInfAdicionalTotalSaldoCaducadoRealDeclGrupo18,
		board438RetgsInfAdicionalTotalSaldoNODeduzidoColGrupo32,
		board438RetgsInfAdicionalTotalDotaODoPerOdoDeclGrupo46,
		board438RetgsInfAdicionalTotalDeduODeclDeGrupo60,
		board438RetgsInfAdicionalTotalSaldoQueTransitaDeclGrupo74,
		board438TransmBenefFiscSocFundidaTotalSaldoBenefTransmitidos88,
		board438TransmBenefFiscSocFundidaTotalDotaODoPerOdo102,
		entities(projectId:$projectId,fiscalYear:$fiscalYear){ entity, count, model }
	`,
};

export const pt_mod_22_definitive_exemption_03_1 = {
	entity: "KeyValueMod22",
	body: `
		value,
		description
	`,
};

export const pt_mod_22_field_414_031_a = {
	entity: "Expenses",
	body: `
		nif,
		value,
		period
	`,
};

export const pt_mod_22_field_411_031_b = {
	entity: "Expenses",
	body: `
		nif,
		value,
		period
	`,
};

export const pt_mod_22_temporary_exemption_03_2 = {
	entity: "KeyValueMod22",
	body: `
		value,
		description
	`,
};

export const pt_mod_22_field_412_032_a = {
	entity: "Expenses",
	body: `
		nif,
		value,
		period
	`,
};

export const pt_mod_22_income_deductions_04 = {
	entity: "KeyValueMod22",
	body: `
		value,
		description
	`,
};

export const pt_mod_22_field_413_032_b = {
	entity: "Expenses",
	body: `
		nif,
		value,
		period
	`,
};

export const pt_mod_22_field_414_04_a = {
	entity: "Expenses",
	body: `
		nif,
		value,
		period
	`,
};

export const pt_mod_22_field_415_041 = {
	entity: "Expenses",
	body: `
		nif,
		value,
		period
	`,
};

export const pt_mod_22_maximum_clearance_tax_benefits_061 = {
	entity: "KeyValueMod22",
	body: `
		value,
		description
	`,
};

export const pt_mod_22_investment_tax_benefits_071 = {
	entity: "ColletionDeductionsMod22",
	body: `
		diploma,
		balance_not_deducted,
		period_allocation,
		period_deduction,
		periods_balance,
		balance_lapsed,
		benefit_period,
		nif,
		balance_of_transmitted_profit
	`,
};

export const pt_mod_22_tax_breaks_09 = {
	entity: "AssociatedCompanyMod22",
	body: `
		nif
	`,
};

export const pt_mod_22_retgs_additional_information_071_A = {
	entity: "ColletionDeductionsMod22",
	body: `
		record_type,
		diploma,
		balance_not_deducted,
		period_allocation,
		period_deduction,
		periods_balance,
		balance_lapsed,
		benefit_period,
		nif,
		balance_of_transmitted_profit
	`,
};

export const pt_mod_22_sifide_fiscal_incentive_system_073 = {
	entity: "ColletionDeductionsMod22",
	body: `
			record_type,
			diploma,
			balance_not_deducted,
			period_allocation,
			period_deduction,
			periods_balance,
			balance_lapsed,
			benefit_period,
			nif,
			balance_of_transmitted_profit
		`,
};

export const pt_mod_22_retgs_additional_information_073_A = {
	entity: "ColletionDeductionsMod22",
	body: `
			record_type,
			diploma,
			balance_not_deducted,
			period_allocation,
			period_deduction,
			periods_balance,
			balance_lapsed,
			benefit_period,
			nif,
			balance_of_transmitted_profit
		`,
};

export const pt_mod_22_fiscal_investiment_support_074 = {
	entity: "ColletionDeductionsMod22",
	body: `
			record_type,
			diploma,
			balance_not_deducted,
			period_allocation,
			period_deduction,
			periods_balance,
			balance_lapsed,
			benefit_period,
			nif,
			balance_of_transmitted_profit
		`,
};

export const pt_mod_22_retgs_additional_information_074_A = {
	entity: "ColletionDeductionsMod22",
	body: `
			record_type,
			diploma,
			balance_not_deducted,
			period_allocation,
			period_deduction,
			periods_balance,
			balance_lapsed,
			benefit_period,
			nif,
			balance_of_transmitted_profit
		`,
};

export const pt_mod_22_transmission_fiscal_benefits_077 = {
	entity: "ColletionDeductionsBoard077",
	body: `
			diploma,
			period_allocation,
			benefit_period,
			nif,
			balance_of_transmitted_profit
		`,
};

export const pt_mod_22_ifpc_fiscal_incentive_079 = {
	entity: "TaxIncentiveMod22",
	body: `
			importId,
			workIdentificationNumber,
			startDateOfWork,
			dateOfCompletionOfWork,
			balanceNotDeducted,
			incentiveAmountInThePeriod,
			deductionInPeriod,
			balanceCarried,
			repaymentValue,
			valueToReplace,
		`,
};

export const pt_mod_22_incentives_subject_to_fees_078 = {
	entity: "DetailAnnexD22",
	body: `
			benefitCode,
			eligibleRegion,
			caeCode,
			amountOfRelevantApplications,
			taxIncentivesIrc,
			taxIncentivesOthers,
			nonTaxIncentives,
			total,
		`,
};

export const pt_mod_22_adicitional_informations_011_A = {
	entity: "AssociatedCompanyMod22",
	body: `
		nif
	`,
};

export const pt_mod_22_donations_08 = {
	entity: "DonationsMod22",
	body: `
		type_of_donation,
		nif,
		donation_amount
	`,
};

export const pt_mod_22_financial_incentives_078_a_3 = {
	entity: "FinancialIncentivesMod22",
	body: `
		line_no,
		updated_cumulative_amount,
		updated_amount_enjoyed_financ,
		updated_amount_irc,
		updated_amount_imi,
		amount_enjoyed_imt,
		amount_enjoyed_selo,
		total_amount,
		cumulative_aid_intensity,
		amount_to_be_registered
	`,
};

export const pt_mod_22_financial_incentives_078_a_2 = {
	entity: "FinancialIncentivesMod22",
	body: `
		line_no,
		updated_cumulative_amount,
		updated_amount_enjoyed_financ,
		updated_amount_irc,
		updated_amount_imi,
		amount_enjoyed_imt,
		amount_enjoyed_selo,
		total_amount,
		cumulative_aid_intensity,
		amount_to_be_registered
	`,
};

export const pt_mod_22_tax_max_incentives_078_a_1 = {
	entity: "DetailsAnnexD29Mod22",
	body: `
		line_no,
		type,
		incentive_code,
		investment_start_date,
		investment_end_date,
		type_of_investment,
		official_identification,
		eligible_region,
		cae_code,
		total_amount,
		updated_total_amount,
	`,
};

export const statements = {
	entity: "Statement",
	body: "__typename,id,name,type,status,uploadedAt,startDate,endDate,submitedBy,checked,projectId,fiscalYear,auditStatus,period",
};

export const statements_detail = {
	entity: "StatementDetail",
	body: "id,name,uploadedAt,uploadedByName,type,version",
};

export const attachments = {
	entity: "Attachment",
	body: "__typename,id,fileName,type,size,uploadedAt,updatedAt,year,uploadedBy,uploadedByName,projectId",
};

export const attachments_detail = {
	entity: "AttachmentDetail",
	body: "id,fileName,type,size,uploadedAt,updatedAt,year,uploadedBy,uploadedByName,projectId",
};
/*
##########################
##### CV FRAGMENTS ######
#########################
*/

//************************************************************************************************************************************

/*
##########################
####CV SAFT FRAGMENTS ####
##########################
*/

export const nc_rules_summary = {
	entity: "NCRuleFinding",
	body: "id,DescriptionPreview,Severity,RuleType,Entity",
};

export const saft_cv_statement_accounts_accumulated = {
	entity: "CVStatementAccountAccumulated",
	body: "AccountID,Description,CreditAmount,DebitAmount,TransactionID,JournalID,Period,AccumulatedNet,ID",
};

export const saft_cv_offsetting_account = {
	entity: "CVOffsettingAccount",
	body: "AccountID,Description,CreditAmount,DebitAmount,TransactionID,JournalID,Period,ID",
};

export const saft_cv_project_summary = {
	entity: "CVProjectSumary",
	body: "customersTotal,suppliersTotal,productsTotal,TransactionsTotal,TransactionLinesTotal,CustomersCountryTotal,SuppliersCountryTotal,ProductsGroupTotal,CustomersTransactionTotal,PaymentsTotal,PaymentLinesTotal,WorkDocumentsTotal,WorkDocumentLinesTotal",
};

export const nc_findings_for_record = {
	entity: "NCRuleFinding",
	body: "id,DescriptionPreview,Severity,RuleType,Entity",
};

export const nc_audit_notes = {
	entity: "AuditNote",
	body: "id,AuditStatus,AuditNote,CreatedBy,CreatedAt,ModifiedBy,UpdatedAt,Source,Origin,RowID,EntityName",
};

export const adhoc_audit_notes_by_entity = {
	entity: "AuditNoteRecord",
	body: "AuditStatus,AuditNote,CreatedBy,CreationDate",
};

export const nc_findings_summary = {
	entity: "NCAuditSummary",
	body: "severity, count",
};

export const nc_rules = {
	entity: "NcRule",
	body: "id,RuleID,RuleType,Severity,Description,AppliesTo",
};

/*
##########################
##### CV FRAGMENTS ######
#########################
*/

/*
##########################
##### INDIA FRAGMENTS ######
#########################
*/

export const in_gstr2a_purchases = {
	entity: "GST2APurchase",
	body: "id,SapDocNo,SapDocType,InvoiceNo,InvoiceDate,InvoicePostingDate,SupplierGSTIN, SupplierName,SupplierId, SupplierCountry,TaxableValue,TotalTaxAmount,TotalInvoiceValue",
};

export const in_gstr2a_purchase = {
	entity: "GST2APurchase",
	body: "id,SapDocNo,SapDocType,InvoiceNo,InvoiceDate,InvoicePostingDate,TotalInvoiceValue,TotalTaxAmount,TaxableValue,SupplierGSTIN, SupplierName,SupplierId, SupplierCountry,entities(projectId:$projectId,fiscalYear:$fiscalYear){ entity, count, model }",
};

export const in_gstr2a_report = {
	entity: "GST2AReport",
	body: "id,SapDocNumber,SapDocType,InvoiceNumber,InvoiceDate,SupplierGSTIN,DescriptionReport,SapTaxableValue,SupplierTaxableValue,TaxableValueDiff,SapTaxAmount,SupplierTaxAmount,TaxAmountDiff,SapInvoiceValue,SupplierInvoiceValue,InvoiceValueDiff",
};

export const in_gstr2a_repor = {
	entity: "GST2AReport",
	body: "InvoiceNumber,InvoiceDate,DescriptionReport,InvoiceValueDiff,TaxableValueDiff,SupplierGSTIN,,SupplierInvoiceValue,SapInvoiceValue,SapTaxableValue,SupplierTaxableValue,SapDocNumber, TaxAmountDiff,SapTaxAmount,SupplierTaxAmount",
};

export const in_gstr2a_gstpuschase_lines = {
	entity: "GSTR2APurchaseLine",
	body: "SapDocNo,SapDocType,TaxCode,TaxCondition,TaxType,TaxPercentage,Quantity,TaxableValue",
};

export const in_ewaybill_e_way_bills = {
	entity: "EWayBill",
	body: "SapDocNo, name,id, sapDocType, docNo, docType, docDate, fromGstin, toGstin, ewbNo, ewbStatus, ewbValidity",
};
export const in_ewaybill_e_way_bill = {
	entity: "EWayBill",
	body: "SapDocNo, id, sapDocType, docNo, docType, docDate, fromGstin, fromTrdName, fromAddr1, fromAddr2, actFromStateCode, fromStateCode, fromPincode, fromPlace, toGstin, totrdName, toAddr1, toAddr2, actToStateCode, toStateCode, toPincode, toPlace, transactionType, totInvValue, sgstValue, igstValue, cgstValue, transporterId, transMode, transDocDate, transDocNo, vehicleNo, vehicleType, ewbNo, ewbStatus, ewbValidity, entities(projectId:$projectId,fiscalYear:$fiscalYear){ entity, count, model }",
};
export const in_ewaybill_ewaybill_lines = {
	entity: "EWayBillLine",
	body: "productName, productDesc, hsnCode, quantity, quantityUnit, taxableAmount, sgstRate, cgstRate, igstRate",
};
export const dashboardbill = {
	entity: "DashboardBill",
	body: "totalBill,totalBillCancel,totalBillInvalid,totalBillSubmit,totalBillCancelRequest,totalBillDoc",
};
export const in_ewaybill_e_way_bill_cancel = {
	entity: "EWayBillCancel",
	body: "id,sapDocType,userName,cancelRsnCode,cancelRmrk,created_at",
};
export const in_ewaybill_e_way_bill_cance = {
	entity: "EWayBill",
	body: "SapDocNo, id, sapDocType, docNo, docType, docDate, fromGstin, fromTrdName, fromAddr1, fromAddr2, actFromStateCode, fromStateCode, fromPincode, fromPlace, toGstin, totrdName, toAddr1, toAddr2, actToStateCode, toStateCode, toPincode, toPlace, transactionType, totInvValue, sgstValue, igstValue, cgstValue, transporterId, transMode, transDocDate, transDocNo, vehicleNo, vehicleType, ewbNo, ewbStatus, ewbValidity, entities(projectId:$projectId,fiscalYear:$fiscalYear){ entity, count, model }",
};
export const in_gstr_gst_return_submit = {
	entity: "GstReturn",
	body: "id, sapDocNo, sapDocType, invoiceNo, invoiceDate, gstin, taxableValue, invoiceValue, checked",
};
export const in_gstr_gst_invoices = {
	entity: "GSTInvoice",
	body: "id,SapDocNo,FromGstin,sapDocType,invoice_no,invoice_date,taxable_value,invoice_value,ctin,customer_name,pos,sgst_amount,cgst_amount,igst_amount",
};
export const in_gstr_gst_invoice = {
	entity: "GSTInvoice",
	body: "SapDocNo,sapDocType,FromGstin,invoice_no,invoice_date,taxable_value,invoice_value,pos,ctin,customer_name,customer_country,plant_region,shipping_bill_port_code,shipping_bill_number,shipping_bill_date ,gross_advance_received ,cgst_rate ,cgst_amount ,igst_rate,igst_amount ,sgst_rate ,sgst_amount,gstr1_submission_date,tax_class,entities(projectId:$projectId,fiscalYear:$fiscalYear){ entity, count, model }",
};
export const in_gstr_gstinvoice_lines = {
	entity: "GSTInvoiceLine",
	body: "SapDocNo,SapDocType,quantity,hsn_code,UnitOfMeasure,taxable_value,cgst_tax_code,cgst_rate,cgst_amount,igst_tax_code,igst_rate,igst_amount,sgst_tax_code,sgst_rate,sgst_amount",
};
export const in_gstr_all_gstinvoice_lines = {
	entity: "GSTInvoiceLine",
	body: "SapDocNo, SapDocType,quantity,hsn_code,UnitOfMeasure,taxable_value,cgst_tax_code,cgst_rate,cgst_amount,igst_tax_code,igst_rate,igst_amount,sgst_tax_code,sgst_rate,sgst_amount,invoice_date",
};
export const in_einvoice_einvoices = {
	entity: "EInvoice",
	body: "id,SapDocNo,SapDocType,UserName,UserEmail,TaxScheme,TypeSupply,ReverseCharge,InvoiceNo,InvoiceDate,GstinName,Ctin,CustomerName,Pos,CustomerPinCode,CustomerAddress1,CustomerAddress2,CustomerLocation,CustomerStateCode,CustomerCountry,CgstAmount,IgstAmount,SgstAmount,Jtc1Amount,DiscountAmount,UtilityCostAmount,ShipToGstin,ShipToLegalName,ShipToAddress1,ShipToAddress2,ShipToPlace,ShipToPincode,ShipToStateCode,TaxClass,Currency,ExchangeRate,EwbTransporterID,EwbModeTransport,EwbTransportDocumentNo,EwbTranportDocumentDate,EwbVehicleNumber,EwbVehicleType,AckNo,AckDt,Irn,IrnStatus,EwbNo,EwbDt,EwbValidTill,EwbRemarks,SignedInvoice,SignedQRCode,CancelEWB,CancelIRN,CancelDateEWB,CancelDateIRN,CreatedAt,UpdatedAt,Status,TaxableValue,InvoiceValue",
};
export const in_einvoice_einvoice = {
	entity: "EInvoice",
	body: "SapDocNo,SapDocType,UserName,UserEmail,TaxScheme,TypeSupply,ReverseCharge,InvoiceNo,InvoiceDate,GstinName,Ctin,CustomerName,Pos,CustomerPinCode,CustomerAddress1,CustomerAddress2,CustomerLocation,CustomerStateCode,CustomerCountry,CgstAmount,IgstAmount,SgstAmount,Jtc1Amount,DiscountAmount,UtilityCostAmount,ShipToGstin,ShipToLegalName,ShipToAddress1,ShipToAddress2,ShipToPlace,ShipToPincode,ShipToStateCode,TaxClass,Currency,ExchangeRate,EwbTransporterID,EwbModeTransport,EwbTransportDocumentNo,EwbTranportDocumentDate,EwbVehicleNumber,EwbVehicleType,AckNo,AckDt,Irn,IrnStatus,EwbNo,EwbDt,EwbValidTill,EwbRemarks,SignedInvoice,SignedQRCode,CancelEWB,CancelIRN,CancelDateEWB,CancelDateIRN,CreatedAt,UpdatedAt,Status,ResponseGenIRN,entities(projectId:$projectId,fiscalYear:$fiscalYear){ entity, count, model }",
};
export const in_einvoice_einvoice_lines = {
	entity: "EInvoiceLine",
	body: "CLB_ImportID,SapDocNo,SapDocType,Quantity,HsnCode,UnitOfMeasure,UnitPrice,TaxableAmount,CgstRate,CgstAmount,IgstRate,IgstAmount,SgstRate,SgstAmount",
};
export const in_einvoice_all_einvoice_lines = {
	entity: "EInvoiceLine",
	body: "SapDocNo,SapDocType,Quantity,HsnCode,UnitOfMeasure,UnitPrice,TaxableAmount,CgstRate,CgstAmount,IgstRate,IgstAmount,SgstRate,SgstAmount",
};
export const in_einvoice_einvoice_cancel = {
	entity: "EInvoiceCancel",
	body: "SapDocNo,SapDocType,UserName,CancelRsnCode,CancelRmrk,CreatedAt,Status,InvoiceNo",
};
export const gst_return_gstin = {
	entity: "GstReturnGstin",
	body: "id, name, __typename",
};
export const dashboardeinvoice = {
	entity: "DashboardEInvoice",
	body: "totalEInvoiceSubmitted,totalEInvoiceCancellactions,totalEInvoiceError,totalSubmittedWithEWB,totalSubmittedWithoutEWB",
};

export const saft_ao_finding_summary = {
	entity: "FindingSummary",
	body: "id, name, description, type, total, severity, verified, rule_identifier, reference_type, dataFiles",
};

export const saft_ao_finding = {
	entity: "Finding",
	body: "id,message,messageParameter,severity,referenceType,referenceId,createdAt,type,line,column,xmlBlock,ruleTitle, audit_note(projectId:$projectId,modulePrefix:$modulePrefix){id,message,justified}",
};
