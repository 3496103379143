import React, { useEffect } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { parse } from "query-string";
import {
	GlobalProvider as ColbiUIProvider,
	useGlobalValue,
} from "colbi_web_ui/lib/state/GlobalProvider";
import AsideMenu from "colbi_web_ui/lib/components/layout/AsideMenu/AsideMenu";
import Header from "colbi_web_ui/lib/components/layout/Header/Header";

import { GlobalProvider } from "../../state/globalProvider";
import I18n from "../../I18n";
import { mapProperties, stringifyFilter } from "colbi_web_ui/lib/utils";
import {
	logout,
	pushModal,
	pushDialog,
	popModal,
	uploadAction,
	toggleMenu,
	setLocale,
	auditNoteSubmit,
	downloadAction,
	removeModal,
} from "../../store/actions";

import {
	LoadingIndicator,
	ImageRender,
	List,
} from "colbi_web_ui/lib/components";

import locales from "../../I18n/locales";
import theme from "../../style/_theme.json";
import proxy from "../../routes/proxy";

import styles from "./Projects.module.sass";

import { UserRoles } from "../../enums/userRoles";

import ModalController from "../App/ModalController/ModalController";
import DialogController from "../App/DialogController/DialogController";
import {
	decryptSession,
	checkRoles,
} from "colbi_web_ui/lib/utils/roleValidator";
import { getFirstModuleOfArray } from "../../routes/manageRouting";

const Provider = ({ children, ...props }) => {
	return (
		<GlobalProvider {...props}>
			<ColbiUIProvider {...props}>{children}</ColbiUIProvider>
		</GlobalProvider>
	);
};

const localesByAppTheme = locales();

const ProjectRow = ({ data }) => {
	const { i18n, uploadFiles } = useGlobalValue();

	return (
		<div className={styles["project"]} onClick={() => uploadFiles()}>
			<div className={styles["project-row"]}>
				<h5>{`${data.taxRegistrationNumber} - ${data.name} `}</h5>
				<div className={styles["status-container"]}>
					<small
						className={`${styles["status"]} ${
							styles[`status-${data.projectExpired}`]
						}`}
					>
						{i18n(`status-project-${data.projectExpired}`)}
					</small>
				</div>
				<small className="align-right">{` ${Object.keys(data.fiscalYears).join(
					", "
				)} `}</small>
			</div>
		</div>
	);
};

const logo = () => {
	return (
		process &&
		process.env && (
			<ImageRender
				icon={`${
					process.env.REACT_APP_COLBI_THEME
						? `${process.env.REACT_APP_COLBI_THEME}-logo`
						: "logo"
				}`}
			/>
		)
	);
};

const renderOrganization = (organization, i18n, params) => {
	const numberOfProjects = (organization.projects || []).length;
	const listFooter = i18n`${numberOfProjects} of ${numberOfProjects}`;
	const projects = (organization.projects || [])
		.sort(({ name: a }, { name: b }) => a.localeCompare(b))
		.map((project) => {
			let projectObj;
			if (
				project &&
				project.fiscalYears &&
				Object.keys(project.fiscalYears).length > 0
			) {
				const firstFiscalYear = Math.max(...Object.keys(project.fiscalYears));
				let firstImportId = "full";
				const projectModules = project && project.modules;
				const firstMenuModule = getFirstModuleOfArray(
					(projectModules || []).map((elem) => elem.name)
				);
				const hasNavigationByImportId = projectModules.find(
					(elem) => elem.name === firstMenuModule
				);
				if (
					hasNavigationByImportId &&
					hasNavigationByImportId.navigation_by_import_id
				) {
					firstImportId =
						project && project.fiscalYears && firstFiscalYear
							? Math.max(...project.fiscalYears[firstFiscalYear])
							: "full";
				}
				projectObj =
					organization &&
					organization.organizationExpired === 0 &&
					project.projectExpired === 0
						? {
								...project,
								to: `/${project.id}${
									firstFiscalYear ? `/${firstFiscalYear}` : ""
								}/${firstImportId}/${firstMenuModule}`,
						  }
						: {
								...project,
						  };
			} else
				projectObj =
					organization &&
					organization.organizationExpired === 0 &&
					project.projectExpired === 0
						? {
								...project,
								to: `/${project.id}/repository/activity/status`,
						  }
						: {
								...project,
						  };
			return projectObj;
		});
	return (
		<List
			modifiers={["--row-style-alternate"]}
			header={[
				{ label: [`${i18n`TRN`} - ${i18n`Project`}`] },
				{ label: [i18n`Status`], columnName: "period", align: "center" },
				{ label: [i18n`Fiscal Years`], columnName: "period", align: "right" },
			]}
			items={projects}
			card={ProjectRow}
			footer={[listFooter]}
		/>
	);
};

const ProjectsByFile = (props) => {
	const {
		user,
		fetch,
		match,
		setLocale,
		logout,
		history,
		reload,
		location,
		pushModal,
		pushDialog,
		uploads,
		uploadFiles,
		submitAuditNote,
		download,
	} = props;

	const locale = match.params.locale || "en";
	const i18n = I18n.use(locale);

	useEffect(() => {
		setLocale(locale);
	}, [locale, setLocale]);

	useEffect(() => {
		if (
			pushModal &&
			userInformation &&
			checkRoles(userInformation, [UserRoles.PP_SUPPORT, UserRoles.SUPPORT])
		) {
			pushModal({
				title: "Ticket Submission",
				data: {},
				args: "",
				view: "TicketForm",
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let userInformation = decryptSession(user);

	if (!userInformation) {
		return <Redirect to={"/login"} push />;
	}

	if (userInformation && parseInt(userInformation.mysqlPasswordReset)) {
		return <Redirect to={`/${locale}/reset_password`} />;
	}

	if (userInformation && checkRoles(userInformation, [UserRoles.BACKOFFICE])) {
		return <Redirect to={`/${locale}/settings/`} />;
	}

	const queryParams = parse(location.search) || {};
	const routeParams = match.params || {};
	const params = {
		...queryParams,
		...routeParams,
	};

	const goto = (page, filters, anchor) => {
		const pageUrl = page
			? (typeof page.join === "function" ? page.join("/") : page).replace(
					/\/+/g,
					"/"
			  )
			: null;
		const urlFilters = filters ? stringifyFilter(filters) : "";
		const localePrefixed = pageUrl
			? `${locale ? `/${locale}${!pageUrl.match(/^\//) ? "/" : ""}` : ""}`
			: null;
		const gotoPage = pageUrl
			? `${localePrefixed}${pageUrl}${
					urlFilters.length ? `?filter=${urlFilters}` : ""
			  }`
			: `${location.pathname}?filter=${urlFilters}${
					anchor ? `#${anchor}` : ""
			  }`;
		history.push(gotoPage);
	};

	const results = mapProperties(
		{ organizations: "query(0).list" },
		fetch.results,
		fetch.status,
		ProjectsByFile.queries(),
		{},
		{},
		i18n,
		locale
	);
	const organizations = (results.organizations || []).sort(
		({ name: a }, { name: b }) => a.localeCompare(b)
	);

	const objectHeader = organizations.reduce((res, elem) => {
		if (elem && elem.organizationExpired && elem.organizationExpired === 1) {
			res[elem.id] = {
				message:
					"Your organization has already reached the expiration date, please contact the administrator.",
			};
		}
		return res;
	}, {});

	const version = (fetch.results[`${locale}_application_version`] || "")
		.version;

	if (
		!match.params.organizationId &&
		organizations &&
		organizations.length &&
		!checkRoles(userInformation, [UserRoles.PP_SUPPORT, UserRoles.SUPPORT])
	) {
		return <Redirect to={`/${locale}/organizations/${organizations[0].id}`} />;
	}

	const currentOrganization =
		organizations.filter(({ id }) => id === params.organizationId)[0] || {};
	const children =
		match.params.organizationId &&
		renderOrganization(currentOrganization, i18n, params);

	const navigation = (organizations || []).map((organization) => ({
		name: organization.name,
		to: `/organizations/${organization.id}`,
		exact: true,
	}));

	return (
		<Provider
			user={userInformation}
			proxy={proxy}
			theme={theme}
			uploads={uploads}
			actions={{
				pushModal,
				pushDialog,
				popModal,
				uploadFiles,
				download,
				submitAuditNote,
				removeModal,
				logout,
				setLocale: (locale) => {
					history.push(`/${locale}${history.location.pathname.substring(3)}`);
				},
				goto,
			}}
			i18n={i18n}
			locale={locale || localesByAppTheme[0].id}
			locales={localesByAppTheme}
			reload={reload}
			params={params}
			history={history}
			location={location}
		>
			<aside className={styles["aside"]}>
				<AsideMenu
					logo={logo}
					title={userInformation.name}
					subtitle={i18n`Organizations`}
					navigation={navigation}
					organizations={organizations}
					version={version}
					expanded={true}
					showCollapseIcon={false}
					settingsAction={checkRoles(userInformation, [
						UserRoles.ADMIN,
						UserRoles.OWNER,
						UserRoles.PP_SUPPORT,
						UserRoles.SUPPORT,
					])}
				/>
			</aside>
			<main className={styles["main"]}>
				<Header
					className={styles["header"]}
					title={
						currentOrganization.name && [
							currentOrganization.name,
							i18n`Projects`,
						]
					}
					settingsAction={checkRoles(userInformation, [
						UserRoles.ADMIN,
						UserRoles.OWNER,
						UserRoles.BACKOFFICE,
					])}
					navigation={navigation}
					organizations={organizations}
					menuLogo={logo}
					menuTitle={userInformation.name}
					menuSubtitle={i18n`Organizations`}
					headerMessage={
						match.params.organizationId
							? objectHeader[match.params.organizationId]
							: {}
					}
				/>
				{fetch.activity && (
					<LoadingIndicator className={styles["loading-indicator"]} />
				)}
				<div className={styles["content"]}>{children}</div>
			</main>
			<ModalController />
			<DialogController i18n={i18n} />
		</Provider>
	);
};

ProjectsByFile.queries = () => [
	{
		resource: "user_organizations",
		body: "id,name,projects{id,name,fiscalYears,status,taxRegistrationNumber,projectExpired,modules{navigation_by_import_id,name}},organizationExpired",
	},
	{
		resource: "application_version",
		body: "version",
	},
];

export default connect(
	({ user, fetch, menu, uploads }) => ({
		user,
		fetch,
		uploads,
	}),
	{
		logout,
		pushDialog,
		pushModal,
		uploadFiles: uploadAction,
		download: downloadAction,
		submitAuditNote: auditNoteSubmit,
		popModal,
		toggleMenu,
		setLocale,
		removeModal,
	}
)(withRouter(ProjectsByFile));
