import audit from "./audit";
import auditSumary from "./auditSumary";
import auditNoteByFile from "./auditNoteByFile";
import auditByFile from "./auditByFile";
import errorFinding from "./errorFinding";
import findingRule from "./findingRule";
import finding from "./finding";
import repository from "./repository";
import generationRepository from "./generationRepository";
import entityWithoutHeader from "./entityWithoutHeader";
import defaultNavigation from "./navigation";
import overviewByFile from "./overviewByFile";
import masterDataByFile from "./masterDataByFile";
import entityByFile from "./entityByFile";
import transactionsByFile from "./transactionsByFile";
import reportByFile from "./reportByFile";
import findingRuleByFile from "./findingRuleByFile";
import saftHuOverview from "./saftHuOverview";
import reportSaftAo from "./reportSaftAo";
import detailEntity from "./detailEntity";
import genericOverview from "./genericOverview";
import entitiesDataWithoutFindings from "./entitiesDataWithoutFindings";
import entityWithoutFindings from "./entityWithoutFindings";
import dashboardEInvoice from "./dashboardEInvoice";
import gstReturnToSubmit from "./gstReturnToSubmit";
import gstr2aRequestSubmit from "./gstr2aRequestSubmit";

let navigation = [];

const menuRouting = {
	saft_pt: [
		{
			level: 0,
			priority: 1,
			name: "label(Overview)",
			to: "/:projectId/:fiscalYear([0-9]{4})/:importId([0-9]*|full)/saft_pt",
			exact: true,
			icon: "dashboard",
			routing: [
				overviewByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(saft_pt)?/",
					navigation,
					true,
					"label(Overview)",
					true
				),
			],
			queries: [],
		},
		{
			level: 0,
			priority: 2,
			name: "label(Audit)",
			icon: "audit-icon",
			badge: "prop(saftPtNcAuditSummaryTotal)",
			isToBuildMenu: true,
			navigation: [
				{
					name: [
						"label(Results)",
						" (",
						"prop(saftPtNcAuditSummaryTotal)",
						")",
					],
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId/saft_pt/audit",
				},
				{
					name: "label(Audit Notes)",
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId/saft_pt/audit_notes",
				},
			],
			routing: [
				auditByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_pt)/audit/",
					navigation,
					true,
					"label(Audit)",
					true
				),
				auditNoteByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_pt)/audit_notes",
					navigation,
					true,
					"label(Audit Notes)",
					true
				),
				findingRuleByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_pt)/audit/rules/:id",
					navigation,
					true,
					"label(Rules)",
					true
				),
			],
			queries: [
				{
					resource: "nc_audit_summary_total",
					args: {
						projectId: "",
						fiscalYear: "",
						genericPrefix: "saft_pt",
						importId: "",
					},
					body: "",
				},
			],
		},
		{
			name: "label(SAFT_PT)",
			icon: "reports",
			priority: 5,
			level: 0,
			isToBuildMenu: true,
			navigation: [
				{
					level: 1,
					name: "label(Master Data)",
					icon: "master-data",
					navigation: "prop(saftPtGenericMenuMasterData)",
				},
				{
					level: 1,
					name: "label(TransactionsMenu)",
					icon: "transactions",
					navigation: "prop(saftPtGenericMenuTransactionalData)",
				},
			],
			routing: [
				masterDataByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_pt)/master_data/:entity?",
					navigation,
					true,
					"label(Master Data)",
					true
				),
				entityByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_pt)/master_data/:entities*",
					navigation,
					true,
					"label(Master Data)",
					true
				),
				transactionsByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_pt)/:entity",
					navigation,
					true,
					"label(TransactionsMenu)",
					true
				),
				entityByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_pt)/:entities*",
					navigation,
					true,
					"label(TransactionsMenu)",
					true
				),
			],
			queries: [
				{
					resource: "generic_menu_master_data",
					subEntity: "master_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						genericPrefix: "saft_pt",
					},
					body: "entity, count",
				},
				{
					resource: "generic_menu_transactional_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						genericPrefix: "saft_pt",
					},
					body: "entity, count",
				},
			],
		},
	],
	saft_ao: [
		{
			level: 0,
			priority: 1,
			name: "label(Overview)",
			to: "/:projectId/:fiscalYear([0-9]{4})/:importId([0-9]*|full)/saft_ao",
			exact: true,
			icon: "dashboard",
			routing: [
				genericOverview(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(saft_ao)?/",
					navigation,
					true,
					"label(Overview)"
				),
			],
			queries: [],
		},
		{
			level: 0,
			priority: 2,
			name: "label(Audit)",
			icon: "audit-icon",
			badge: "prop(saftAoAuditSummaryTotal)",
			isToBuildMenu: true,
			navigation: [
				{
					name: ["label(Results)", " (", "prop(saftAoAuditSummaryTotal)", ")"],
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId(full)/saft_ao/audit",
				},
				{
					name: "label(Execution Summary)",
					to:
						"/:projectId/:fiscalYear([0-9]{4})/:importId(full)/saft_ao/audit_execution_summary",
				},
			],
			routing: [
				audit(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saft_ao)/audit/",
					navigation,
					true,
					"label(Audit)"
				),
				auditSumary(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saft_ao)/audit_execution_summary",
					navigation,
					true,
					"label(Execution Summary)"
				),
				errorFinding(
					"/:locale(pt|en|lt)?/:projectId/:modulePrefix/audit_findings",
					navigation,
					true,
					"label(Findings)"
				),
				finding(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saft_ao)/audit/rules/:entityId/finding/:id",
					navigation,
					true,
					"label(Finding)"
				),
				findingRule(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saft_ao)/audit/rules/:id",
					navigation,
					true,
					"label(Rules)"
				),
			],
			queries: [
				{
					resource: "saft_ao_audit_summary_total",
					args: {
						projectId: "",
						fiscalYear: "",
						modulePrefix: "saft_ao",
						importId: "full",
					},
					body: "",
				},
			],
		},
		{
			priority: 2,
			name: "label(Reports)",
			icon: "reports",
			level: 0,
			navigation: [
				{
					name: "label(Financial Reports)",
					exact: false,
					icon: "invoices",
					level: 1,
					navigation: [
						{
							name: "label(Trial Balance)",
							to:
								"/:projectId/:fiscalYear([0-9]{4})/:importId/saft_ao/reports/trial_balance",
							level: 2,
						},
						{
							name: "label(trial_balance_annual)",
							to:
								"/:projectId/:fiscalYear([0-9]{4})/:importId/saft_ao/reports/trial_balance_annual",
							level: 2,
						},
						{
							name: "label(statement_accounts_accumulated)",
							to: `/:projectId/:fiscalYear([0-9]{4})/:importId/saft_ao/reports/statement_accounts_accumulated`,
							level: 2,
						},
						{
							name: "label(offsetting_account)",
							to: `/:projectId/:fiscalYear([0-9]{4})/:importId/saft_ao/reports/offsetting_account`,
							level: 2,
						},
					],
				},
				{
					name: "label(Sales Reports)",
					exact: false,
					icon: "reports",
					level: 1,
					navigation: [
						{
							name: "label(customers_report)",
							to: `/:projectId/:fiscalYear([0-9]{4})/:importId/saft_ao/accounting_reports/customer_sales_report`,
							level: 2,
						},
						{
							name: "label(products_report)",
							to: `/:projectId/:fiscalYear([0-9]{4})/:importId/saft_ao/accounting_reports/product_sales_report`,
							level: 2,
						},
						{
							name: "label(products_by_country_report)",
							to: `/:projectId/:fiscalYear([0-9]{4})/:importId/saft_ao/accounting_reports/sales_by_country_report`,
							level: 2,
						},
					],
				},
			],
			routing: [
				reportSaftAo(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saft_ao)/reports/:id",
					navigation,
					true,
					"label(Reports)"
				),
				reportSaftAo(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId(full)/:modulePrefix(saft_ao)/accounting_reports/:id",
					navigation,
					true,
					"label(Sales Reports)"
				),
			],
		},
		{
			name: "label(SAFT_AO)",
			icon: "reports",
			priority: 5,
			level: 0,
			isToBuildMenu: true,
			navigation: [
				{
					level: 1,
					name: "label(Master Data)",
					icon: "master-data",
					navigation: "prop(saftAoGenericMenuMasterData)",
				},
				{
					level: 1,
					name: "label(TransactionsMenu)",
					icon: "transactions",
					navigation: "prop(saftAoGenericMenuTransactionalData)",
				},
			],
			routing: [
				masterDataByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_ao)/master_data/:entity?",
					navigation,
					true,
					"label(Master Data)",
					false
				),
				detailEntity(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_ao)/master_data/:entities*",
					navigation,
					true,
					"label(Master Data)",
					false
				),
				transactionsByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_ao)/:entity",
					navigation,
					true,
					"label(TransactionsMenu)",
					false
				),
				detailEntity(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_ao)/:entities*",
					navigation,
					true,
					"label(TransactionsMenu)",
					false
				),
			],
			queries: [
				{
					resource: "generic_menu_master_data",
					subEntity: "master_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						genericPrefix: "saft_ao",
					},
					body: "entity, count",
				},
				{
					resource: "generic_menu_transactional_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						genericPrefix: "saft_ao",
					},
					body: "entity, count",
				},
			],
		},
	],
	saft_cv: [
		{
			level: 0,
			priority: 1,
			name: "label(Overview)",
			to: "/:projectId/:fiscalYear([0-9]{4})/:importId([0-9]*|full)/saft_cv",
			exact: true,
			icon: "dashboard",
			routing: [
				overviewByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(saft_cv)?/",
					navigation,
					true,
					"label(Overview)",
					true
				),
			],
			queries: [],
		},
		{
			level: 0,
			priority: 2,
			name: "label(Audit)",
			icon: "audit-icon",
			badge: "prop(saftCvNcAuditSummaryTotal)",
			isToBuildMenu: true,
			navigation: [
				{
					name: [
						"label(Results)",
						" (",
						"prop(saftCvNcAuditSummaryTotal)",
						")",
					],
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId/saft_cv/audit",
				},
				{
					name: "label(Audit Notes)",
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId/saft_cv/audit_notes",
				},
			],
			routing: [
				auditByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_cv)/audit/",
					navigation,
					true,
					"label(Audit)",
					true
				),
				auditNoteByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_cv)/audit_notes",
					navigation,
					true,
					"label(Audit Notes)",
					true
				),
				findingRuleByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_cv)/audit/rules/:id",
					navigation,
					true,
					"label(Rules)",
					true
				),
			],
			queries: [
				{
					resource: "nc_audit_summary_total",
					args: {
						projectId: "",
						fiscalYear: "",
						genericPrefix: "saft_cv",
						importId: "",
					},
					body: "",
				},
			],
		},
		{
			priority: 2,
			name: "Reports",
			icon: "reports",
			level: 0,
			navigation: [
				{
					name: "label(Financial Reports)",
					exact: false,
					icon: "invoices",
					level: 1,
					navigation: [
						{
							name: "label(Accounting Statement)",
							to:
								"/:projectId/:fiscalYear([0-9]{4})/:importId/saft_cv/reports/statement_accounts_accumulated",
							level: 2,
						},
						{
							name: "label(Offsetting Account)",
							to:
								"/:projectId/:fiscalYear([0-9]{4})/:importId/saft_cv/reports/offsetting_account",
							level: 2,
						},
					],
				},
			],
			routing: [
				reportByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_cv)/reports/:id",
					navigation,
					true,
					"label(Reports)",
					true
				),
			],
		},
		{
			name: "label(SAFT_CV)",
			icon: "reports",
			priority: 5,
			level: 0,
			isToBuildMenu: true,
			navigation: [
				{
					level: 1,
					name: "label(Master Data)",
					icon: "master-data",
					navigation: "prop(saftCvGenericMenuMasterData)",
				},
				{
					level: 1,
					name: "label(TransactionsMenu)",
					icon: "transactions",
					navigation: "prop(saftCvGenericMenuTransactionalData)",
				},
			],
			routing: [
				masterDataByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_cv)/master_data/:entity?",
					navigation,
					true,
					"label(Master Data)",
					true
				),
				entityByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_cv)/master_data/:entities*",
					navigation,
					true,
					"label(Master Data)",
					true
				),
				transactionsByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_cv)/:entity",
					navigation,
					true,
					"label(TransactionsMenu)",
					true
				),
				entityByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_cv)/:entities*",
					navigation,
					true,
					"label(TransactionsMenu)",
					true
				),
			],
			queries: [
				{
					resource: "generic_menu_master_data",
					subEntity: "master_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						genericPrefix: "saft_cv",
					},
					body: "entity, count",
				},
				{
					resource: "generic_menu_transactional_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						genericPrefix: "saft_cv",
					},
					body: "entity, count",
				},
			],
		},
	],
	saft_hu: [
		{
			level: 0,
			priority: 1,
			name: "label(Overview)",
			to: "/:projectId/:fiscalYear([0-9]{4})/:importId([0-9]*|full)/saft_hu",
			exact: true,
			icon: "dashboard",
			routing: [
				saftHuOverview(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(saft_hu)?/",
					navigation,
					true,
					"label(Overview)",
					true
				),
			],
			queries: [],
		},
		{
			level: 0,
			priority: 2,
			name: "label(Audit)",
			icon: "audit-icon",
			badge: "prop(saftHuNcAuditSummaryTotal)",
			isToBuildMenu: true,
			navigation: [
				{
					name: [
						"label(Results)",
						" (",
						"prop(saftHuNcAuditSummaryTotal)",
						")",
					],
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId/saft_hu/audit",
				},
				{
					name: "label(Audit Notes)",
					to: "/:projectId/:fiscalYear([0-9]{4})/:importId/saft_hu/audit_notes",
				},
			],
			routing: [
				auditByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_hu)/audit/",
					navigation,
					true,
					"label(Audit)",
					true
				),
				auditNoteByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_hu)/audit_notes",
					navigation,
					true,
					"label(Audit Notes)",
					true
				),
				findingRuleByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_hu)/audit/rules/:id",
					navigation,
					true,
					"label(Rules)",
					true
				),
			],
			queries: [
				{
					resource: "nc_audit_summary_total",
					args: {
						projectId: "",
						fiscalYear: "",
						genericPrefix: "saft_hu",
						importId: "",
					},
					body: "",
				},
			],
		},
		{
			name: "label(SAFT-HU)",
			icon: "reports",
			priority: 5,
			level: 0,
			isToBuildMenu: true,
			navigation: [
				{
					level: 1,
					name: "label(Master Data)",
					icon: "master-data",
					navigation: "prop(saftHuGenericMenuMasterData)",
				},
				{
					level: 1,
					name: "label(TransactionsMenu)",
					icon: "transactions",
					navigation: "prop(saftHuGenericMenuTransactionalData)",
				},
			],
			routing: [
				masterDataByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_hu)/master_data/:entity?",
					navigation,
					true,
					"label(Master Data)",
					true
				),
				entityByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_hu)/master_data/:entities*",
					navigation,
					true,
					"label(Master Data)",
					true
				),
				transactionsByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_hu)/:entity",
					navigation,
					true,
					"label(TransactionsMenu)",
					true
				),
				entityByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(saft_hu)/:entities*",
					navigation,
					true,
					"label(TransactionsMenu)",
					true
				),
			],
			queries: [
				{
					resource: "generic_menu_master_data",
					subEntity: "master_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						genericPrefix: "saft_hu",
					},
					body: "entity, count",
				},
				{
					resource: "generic_menu_transactional_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						genericPrefix: "saft_hu",
					},
					body: "entity, count",
				},
			],
		},
	],
	in_einvoice: [
		{
			level: 0,
			priority: 1,
			name: "label(Dashboard E-Invoice)",
			to:
				"/:projectId/:fiscalYear([0-9]{4})/:importId([0-9]*|full)/in_einvoice",
			exact: true,
			icon: "dashboard",
			routing: [
				dashboardEInvoice(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(in_einvoice)?/",
					navigation,
					true,
					"label(Dashboard)"
				),
			],
		},
		{
			name: "label(E-Invoices)",
			icon: "master-data",
			priority: 2,
			level: 0,
			isToBuildMenu: true,
			navigation: "prop(einvoiceData)",
			routing: [
				entitiesDataWithoutFindings(
					"/:locale(en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(in_einvoice)/:entity?",
					navigation,
					true,
					["label(E-Invoices Data)", "label(param(entity))"]
				),
				entityWithoutFindings(
					"/:locale(en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(in_einvoice)/:entities*",
					navigation,
					true,
					"label(E-Invoices Data)"
				),
			],
			queries: [
				{
					resource: "einvoice_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						modulePrefixInEinvoice: "in_einvoice",
					},
					body: "entity, count",
				},
			],
		},
	],
	in_gstr: [
		{
			name: "label(GSTR1)",
			icon: "master-data",
			priority: 3,
			level: 0,
			isToBuildMenu: true,
			navigation: "prop(gstData)",
			routing: [
				gstReturnToSubmit(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId([0-9]*|full)?/:modulePrefix(in_gstr)/:entity(gst_return_submit)",
					navigation,
					true
				),

				entitiesDataWithoutFindings(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(in_gstr)/:entity?",
					navigation,
					true,
					["label(GST Returns)", "label(param(entity))"]
				),
				entityWithoutFindings(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(in_gstr)/:entities*",
					navigation,
					true,
					"label(GST Data)"
				),
			],
			queries: [
				{
					resource: "gst_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						modulePrefixInGstr: "in_gstr",
					},
					body: "entity, count",
				},
			],
		},
	],
	in_gstr2a: [
		{
			name: "label(GSTR2A)",
			icon: "reports",
			priority: 4,
			level: 0,
			isToBuildMenu: true,
			navigation: [
				{
					level: 1,
					name: "label(GSTR Purchase Invoices)",
					icon: "master-data",
					navigation: "prop(inGstr2aGenericMenuMasterData)",
				},
				{
					level: 1,
					name: "label(SAP Purchase Invoices)",
					icon: "master-data",
					navigation: "prop(gst2aData)",
				},
			],
			routing: [
				gstr2aRequestSubmit(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId([0-9]*|full)?/:modulePrefix(in_gstr2a)/:entity(report)",
					navigation,
					true
				),
				masterDataByFile(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix(in_gstr2a)/master_data/:entity?",
					navigation,
					true,
					"label(GST Purchase Invoices)",
					false
				),
				entitiesDataWithoutFindings(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(in_gstr2a)/:entity?",
					navigation,
					true,
					["", "label(param(entity))"]
				),
				entityWithoutFindings(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)?/:importId([0-9]*|full)?/:modulePrefix(in_gstr2a)/:entities*",
					navigation,
					true,
					"label(GST2A Data)"
				),
			],
			queries: [
				{
					resource: "generic_menu_master_data",
					subEntity: "master_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						genericPrefix: "in_gstr2a",
					},
					body: "entity, count",
				},
				{
					resource: "gst2a_data",
					args: {
						projectId: "",
						fiscalYear: "",
						importId: "",
						modulePrefixInGstr2a: "in_gstr2a",
					},
					body: "entity, count",
				},
			],
		},
	],
	generator: [
		{
			level: 0,
			priority: 99,
			module: "generator",
			name: "label(i.SAF-T Generation)",
			to: "/:projectId/:fiscalYear([0-9]{4})/generation_repository",
			exact: true,
			icon: "repository",
			routing: [
				generationRepository(
					"/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/generation_repository/",
					navigation,
					true,
					"label(Generation Repository)"
				),
			],
			queries: [
				{
					resource: "generator_repository_status",
					args: {
						projectId: "",
						fiscalYear: "",
					},
					body: "status",
				},
			],
		},
	],
	repository: [
		{
			level: 0,
			priority: 100,
			name: "label(Repository)",
			to: "/:projectId/repository/activity/status",
			exact: true,
			icon: "repository",
			routing: [
				repository(
					"/:locale(pt|en|lt)?/:projectId/repository/:section(efatura_request)",
					true
				),
				repository(
					"/:locale(pt|en|lt)?/:projectId/repository/:section(efatura_deleted_data)",
					false
				),
				repository(
					"/:locale(pt|en|lt)?/:projectId/repository/:section?/:fiscalYear?/:fileType?",
					false
				),
				entityWithoutHeader(
					"/:locale(pt|en|lt)?/:projectId/:modulePrefix/:entities(audit_findings)/:id",
					false
				),
				entityWithoutHeader(
					"/:locale(pt|en|lt)?/:projectId/:importId/:modulePrefix/:entities(audit_findings)/:id",
					true
				),
				entityWithoutHeader(
					"/:locale(pt|en|lt)?/:projectId/:entities(audit_efatura_findings)/:id",
					true
				),
			],
			queries: [
				{
					resource: "repository_status",
					args: {
						projectId: "",
						fiscalYear: "",
					},
					body: "status",
				},
			],
		},
	],
};

const filteredQueriesByModules = (modules) => {
	if (modules && modules.length > 0) {
		const filteredRoutes = (modules || []).reduce((acc, elem) => {
			const menuArray = menuRouting[`${elem}`];
			const queries = (menuArray || [])
				.filter((elem) => elem.queries !== undefined)
				.map(({ queries }) => {
					return queries;
				});
			acc.push(...queries);
			return acc;
		}, []);
		return filteredRoutes.flat();
	}
	return [];
};

const filteredQueriesByModulesForMenu = (modules) => {
	if (modules && modules.length > 0) {
		return (modules || []).reduce((acc, elem) => {
			const menuArray = menuRouting[`${elem}`];
			const queries = (menuArray || [])
				.filter((elem) => elem.isToBuildMenu && elem.queries !== undefined)
				.map(({ queries }) => {
					return queries;
				});

			acc.push({ [`${elem}`]: queries.flat() });
			return acc;
		}, []);
	}
	return [];
};

const filteredNavigationByModules = (modules) => {
	let filteredRoutes = [];
	if (modules && modules.length > 0) {
		const reorderedArray = reorderRouting(modules);
		iterateNavigation(reorderedArray, filteredRoutes);
	}
	return filteredRoutes;
};

const reorderRouting = (modules) => {
	if (modules && modules.length > 0) {
		const filteredRoutes = (modules || []).reduce((acc, elem) => {
			const menuArray = menuRouting[`${elem}`];
			if (menuArray) {
				acc.push(...menuArray);
			}
			return acc;
		}, []);
		return filteredRoutes.sort(function (a, b) {
			return a.priority - b.priority;
		});
	}
	return [];
};

const getFirstModuleOfArray = (modules) => {
	if (modules && modules.length > 0) {
		const firstModule = Object.keys(menuRouting).find(
			(element) => modules.indexOf(element) >= 0
		);
		return firstModule || "";
	}
	return [];
};

const filteredRoutesByModules = (modules) => {
	let filteredRoutes = [];
	if ((modules || []).length !== 0) {
		const reorderedArray = reorderRouting(modules);
		navigation.splice(0, navigation.length, ...constructMenu(reorderedArray)); //prevent navigation to duplicate entries
		iterateRouting(reorderedArray, filteredRoutes);
	}

	return filteredRoutes.flat();
};

const constructMenu = (reorderedArray) => {
	const menuToUse = [...JSON.parse(JSON.stringify(reorderedArray))]; //deep copy of array, to prevent any mutation on the original routing

	const navigationContent = menuToUse.map(
		({ routing, queries, ...restNavigation }) => {
			return restNavigation;
		}
	);

	return [...navigationContent, ...defaultNavigation];
};

const iterateNavigation = (navigation, arrayOfNavigations) => {
	arrayOfNavigations.push(...navigation);
};

const iterateRouting = (navigation, arrayOfNavigations) => {
	const mergeTo = navigation.map(({ routing, queries, ...restNavigation }) => {
		if (restNavigation && Array.isArray(restNavigation)) {
			iterateRouting(restNavigation, arrayOfNavigations);
		}
		return routing || [];
	});
	arrayOfNavigations.push(...(mergeTo.flat() || []));
};

export {
	menuRouting,
	filteredQueriesByModules,
	filteredRoutesByModules,
	filteredNavigationByModules,
	filteredQueriesByModulesForMenu,
	getFirstModuleOfArray,
};
