import { StackLayout } from "../layouts";
import {
	List,
	Filters,
	rows,
	AuditSummaryWidget,
} from "colbi_web_ui/lib/components";
import theme from "colbi_web_ui/lib/style/_theme.json";
import { parseFilter } from "colbi_web_ui/lib/utils";
import AppByFile from "../controllers/AppByFile/AppByFile";
import ExportButton from "../components/ExportButton/ExportButton";

const queries = (args = {}) => {
	return [
		{
			resource: args.modulePrefix ? `${args.modulePrefix}_audit_summary` : "audit_summary",
			args: {
				projectId: args.projectId,
				fiscalYear: args.fiscalYear,
				modulePrefix: args.modulePrefix,
			},
			body: "severity, count",
		},
		{
			resource: args.modulePrefix ?  `${args.modulePrefix}_finding_summary` : "finding_summary",
			args: {
				projectId: args.projectId,
				fiscalYear: args.fiscalYear,
				modulePrefix: args.modulePrefix,
				filter: args.filter
					? { type: "[FilterInput!]", value: parseFilter(args.filter) }
					: undefined,
			},
			body:
				`__typename, hasMore, items{...${args.modulePrefix ? `${args.modulePrefix}_finding_summary` : "finding_summary"}}, filters(projectId:$projectId, fiscalYear:$fiscalYear, modulePrefix:$modulePrefix){ prop, name, type, list, disableOperators, mandatory }`
		},
		{
			resource: 'finding_by_rule_export',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				modulePrefix: args.modulePrefix,
				filter: args.filter ? { type: '[FilterInput!]', value: parseFilter(args.filter) } : undefined,
			},
			body: 'report',
			downloadable: true,
			downloadOnly: true,
			xlsxTranslation: "finding_summary"
		},
	];
};

const audit = (path, navigation, exact, title) => ({
	path,
	navigation,
	component: AppByFile,
	queries,
	exact,
	title: [title, "param(fiscalYear)"],
	components: [
		{
			component: AuditSummaryWidget,
			modifiers: ["--root-margin-bottom-normal"],
			props: {
				icon: "audit-icon",
				title: "label(Audit Results)",
				summary: "query(0).list",
			},
		},
		{
			component: Filters,
			props: {
				filter: "param(filter)",
				availableFilters: "query(1).filters",
				stickyTop: parseInt(theme.layout.$headerHeight, 10),
				anchor: "results",
			},
		},
		{
			component: ExportButton,
			props: {
				data: "query(1).list",
			}
		}
	],
	routes: [
		{
			path,
			exact: true,
			queries,
			component: StackLayout,
			components: [
				{
					component: List,
					modifiers: ["--root-margin-top-small", "--row-style-alternate"],
					props: {
						id: "results",
						items: "query(1).list",
						filter: "param(filter)",
						card: rows.EntityCard,
						metadata: "query(1).metadata",
						page: "param(page)",
						status: "status(1)",
						pageSize: "param(pageSize)",
						pageDefault: 10,
						header: "query(1).header",
					},
				},
			],
		},
	],
});

export default audit;
