import React, { forwardRef, useRef, useImperativeHandle, useState, useEffect } from 'react'
import { TextInput, Dropdown, Checkbox, DatePicker, MultiSelectionInput } from 'colbi_web_ui/lib/components/inputs'
import { useGlobalValue } from 'colbi_web_ui/lib/state/GlobalProvider'
import { FORM_ACTIONS } from '../FormActions'

import styles from './OrganizationForm.module.sass'

const OrganizationForm = (props, ref) => {
	const { data, organizations, status, onChange, onSave, projectModules } = props

	const formRef = useRef()

	const {
		name,
		organizationID,
		packageID,
		active = false,
		expirationDate,
		organizationsModules
	} = data || {}

	const initialDates = {
		"expirationDate": expirationDate ? new Date(expirationDate) : ""
	};

	const [inputs, setInputs] = useState(initialDates);

	const { i18n } = useGlobalValue()

	const handleChange = (e, prop, value) => {
		onChange && onChange({
			originalEvent: e,
			target: {
				value: {
					data: {
						...data,
						[prop]: value
					}
				}
			}
		})
	}

	useEffect(() => {
		setInputs(initialDates)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [expirationDate])

	const handleDateChange = (e, fieldName) => {
		setInputs(inputs => ({
			...inputs,
			[fieldName]: e
		}))
	};

	useImperativeHandle(ref, () => ({
		submit: () => {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
		}
	}))

	return status !== 'loading' ? (
		<form ref={formRef} onSubmit={(e) => {
			e.preventDefault()
			e.stopPropagation()
			if (formRef.current.checkValidity()) {
				data["__typename"] = "Organization"
				for (const props in inputs) {
					data[`${props}`] = inputs[props]
				}
				onSave && onSave()
			} else {
				formRef.current.reportValidity()
			}
		}}>
			<div className={styles['group-content']}>
				<div className={styles['flex']}>
					<TextInput
						className={styles['input']}
						name={i18n`Organization ID`}
						placeholder={i18n`Enter an id`}
						value={organizationID || ''}
						required
						onChange={(e) => {
							handleChange(e, 'organizationID', e.target.value)
						}}
					/>
					<TextInput
						className={styles['input']}
						name={i18n`Organization Name`}
						placeholder={i18n`Enter a name`}
						value={name || ''}
						required
						onChange={(e) => {
							handleChange(e, 'name', e.target.value)
						}}
					/>
				</div>
			</div>
			<div className={styles['input-field']}>
				<MultiSelectionInput
					className={styles['input']}
					name={i18n`Modules to Current Organization`}
					value={(typeof organizationsModules === 'string' ? JSON.parse(organizationsModules || '[]') : organizationsModules || []).map((type) => type).filter(p => p)}
					placeholder={i18n`Select modules to active`}
					required
					options={
						projectModules && projectModules.length
							? projectModules.map(({ id, name }) => ({ id, name: name }))
							: []
					}
					onChange={(e) => {
						handleChange(e, 'organizationsModules', JSON.stringify(e.target.value))
					}}
				/>
			</div>
			<div className={styles['input-field']}>
				<h5 className={styles['label']}>{i18n`Package`}</h5>
				<Dropdown
					value={packageID && organizations ? organizations.find(({ id }) => id === packageID) || organizations[0] : null}
					options={organizations || []}
					className={styles['dropdown']}
					placeholder={i18n`-- Select an package --`}
					required
					onChange={(e) => {
						handleChange(e, 'packageID', e.target.value.id)
					}}
				/>
				<DatePicker
					className={`${styles['wrap-datepicker']} ${styles['input']}`}
					name={i18n`Expiration Date`}
					value={inputs["expirationDate"]}
					onChange={(e) => {
						handleDateChange(e, "expirationDate")
					}}
					dateFormat="dd-MM-yyyy"
				/>
			</div>
			<Checkbox
				style={{ marginTop: '10px' }}
				value={active}
				onChange={(e) => {
					handleChange(e, 'active', e.target.checked ? 1 : 0)
				}}
			>{i18n`Active`}</Checkbox>
		</form>
	) : null
}

const OrganizationFormRef = forwardRef(OrganizationForm)

OrganizationFormRef.queries = (args) => {
	return [
		{
			resource: 'packages',
			body: 'id,name'
		},
		{
			resource: 'available_project_modules',
			body: 'id, name'
		},
		args && args.id ? {
			resource: 'organization',
			args: { [`${'organization'}Id`]: args.id },
			body: '__typename,id,organizationID,active,expirationDate,name,packageID,organizationsModules'
		} : undefined,
	].filter(q => q)
}

OrganizationFormRef.dataMapper = ['organizations', 'projectModules', 'data']

OrganizationFormRef.actions = [FORM_ACTIONS.SAVE]
export default OrganizationFormRef