import React, { useState, forwardRef, useRef, useImperativeHandle, useEffect } from 'react'

import { useGlobalValue } from 'colbi_web_ui/lib/state/GlobalProvider'
import Icon from 'colbi_web_ui/lib/components/Icon/Icon'
import { Dropdown, TextInput, Switch } from 'colbi_web_ui/lib/components/inputs'
import { FORM_ACTIONS } from '../FormActions'

import styles from './UserForm.module.sass'

const UserForm = (props, ref) => {
	const { data, projects, organizations, status, onChange, onSave, roles = [] } = props
	const { i18n } = useGlobalValue()
	const [passwordsMatchError] = useState(false)
	const [resetPassword, setResetPassword] = useState(false)
	const [newUser, setNewUser] = useState(true)

	const generateRandomPassword = () => {
		const newPassword = `#${Math.floor(Math.random() * Math.floor(10))}${Math.random().toString(36).slice(2)}${Math.random().toString(36).toUpperCase().slice(2)}`;
		return newPassword;
	};

	const [newPasswordConfirm] = useState(()=> generateRandomPassword())

	const formRef = useRef()
	const {
		id,
		name,
		username: email,
		organizationId,
		projects: userProjects,
		password,
		role: organizationRole,
		blocked = 0
	} = data || {}

	useEffect(() => {
		if(id){
		  setNewUser(false)
		}
	},[id]);

	const allProjects = (
		projects && projects.items
			? projects.items.map(({ id, description, organization }) => ({ id, name: `${description} (${(organization || {}).name})` }))
			: []
	)

	useImperativeHandle(ref, () => ({
		submit: () => {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
		}
	}))

	const handleChange = (e, prop, value) => {
		onChange && onChange({
			originalEvent: e,
			target: {
				value: {
					data: {
						...data,
						[prop]: value
					}
				}
			}
		})
	}


	const projectItems = (userProjects || []).map((project, index) => {
		return (
			<div key={index} className={styles['project-entry']}>
				<div>
					<Dropdown
						className={styles['dropdown']}
						placeholder={''}
						value={project}
						options={allProjects}
						filter
						required
						onChange={(e) => {
							const userProject = userProjects[index]
							const nextProjects = [...(userProjects || [])]
							nextProjects.splice(index, 1, {
								...userProject,
								...(e.target.value || {})
							})
							handleChange(e, 'projects', nextProjects)
						}}
					/>
				</div>
				<div>
					<Dropdown
						className={styles['dropdown']}
						placeholder={''}
						value={roles.find(({ id }) => (id === project.role))}
						required
						options={roles}
						onChange={(e) => {
							const userProject = userProjects[index]
							const nextProjects = [...(userProjects || [])]
							nextProjects.splice(index, 1, {
								...userProject,
								role: (e.target.value || {}).id
							})
							handleChange(e, 'projects', nextProjects)
						}}
					/>
				</div>
				<div>
					<button
						type="button"
						className={styles['remove-button']}
						onClick={(e) => {
							const nextProjects = [...(userProjects || [])]
							nextProjects.splice(index, 1)
							handleChange(e, 'projects', nextProjects)
						}}
					>
						<Icon name="remove" />
					</button>
				</div>
			</div>
		)
	})

	return status !== 'loading' ? (
		<form ref={formRef} onSubmit={(e) => {
			e.stopPropagation()
			e.preventDefault()
			if (formRef.current.checkValidity()) {
				if (!resetPassword) {
					data['password'] = newUser ? newPasswordConfirm : undefined ;
				}
				onSave && onSave()
			} else {
				formRef.current.reportValidity();
			}
		}}>
			{passwordsMatchError && <div className={styles['error']}><small>{i18n`Password and confirm password do not match.`}</small></div>}
			<div className={styles['group-content']}>
				<div className={styles['flex']}>
					<TextInput
						className={styles['input']}
						name={i18n`Fullname`}
						value={name || ''}
						placeholder={i18n`Enter a name`}
						required
						onChange={(e) => {
							handleChange(e, 'name', e.target.value)
						}}
					/>
					<TextInput
						className={styles['input']}
						name={i18n`Email`}
						value={email || ''}
						placeholder={i18n`Enter an email`}
						required
						onChange={(e) => {
							handleChange(e, 'username', e.target.value)
						}}
					/>
				</div>
			</div>
			<div className={styles['input-field']}>
				<h5 className={styles['label']}>{i18n`Organization`}</h5>
				<Dropdown
					value={organizations ? organizations.find(({ id }) => id === organizationId) : null}
					placeholder={''}
					options={organizations || []}
					className={styles['dropdown']}
					required
					onChange={(e) => {
						handleChange(e, 'organizationId', e.target.value.id)
					}}
				/>
			</div>
			<div className={styles['input-field']}>
				<h5 className={styles['label']}>{i18n`Organization Role`}</h5>
				<Dropdown
					value={roles.find(({ id }) => (id === organizationRole)) || (organizationRole === 's' ? { id: 's', name: 'Admin' } : null)}
					options={roles || []}
					placeholder={''}
					className={styles['dropdown']}
					required
					onChange={(e) => {
						handleChange(e, 'role', e.target.value.id)
					}}
				/>
			</div>
			<div className={styles['input-field']}>
				<h5 className={styles['label']}>{i18n`Projects`}</h5>
				<div>{projectItems.length ? projectItems : <small>{i18n`No projects assigned`}</small>}</div>
				<div>
					<button
						className={styles['add-button']}
						type="button"
						onClick={(e) => {
							handleChange(e, 'projects', [...(userProjects || []), allProjects[0]])
						}}
					><small>{i18n`[+] Add new project`}</small></button>
				</div>
			</div>
			<div className={styles['user-management']}>
				<h4 className={styles['title']}>{i18n`User Management`} <small className={`${styles[`user-status-${blocked}`]}`}>{blocked ? i18n`(The user is currently blocked)` : i18n`(The user is currently active)`}</small></h4>
				<div>
					<Switch
						name={i18n`Disable User`}
						checked={blocked}
						onChange={(e) => {
							handleChange(e, 'blocked', e.target.value)
						}}
					/>
				</div>
				<div className={styles['group-content']}>
					<Switch
						name={i18n`Set new password`}
						checked={newUser ? true : resetPassword}
						onChange={(e) => {
							if(newUser === false){
								data['password'] = generateRandomPassword()
								setResetPassword(e.target.value)
							}
						}}
					/>
				</div>
				{(resetPassword || newUser) && (
					<div className={styles['group-content']}>
						<div className={styles['flex']}>
							<TextInput
								className={styles['input']}
								name={i18n`New Password`}
								autoComplete="off"
								placeholder={i18n`New Password`}
								defaultValue={password || newPasswordConfirm}
								disabled={true}
							/>
						</div>
					</div>
				)}
			</div>
		</form>
	) : null
}

const UserFormRef = forwardRef(UserForm)

UserFormRef.queries = (args) => {
	return [
		{
			resource: 'projects',
			args: { pageSize: 0 },
			body: 'items{id,description,organization{name}}'
		},
		{
			resource: 'user_organizations',
			body: 'id,name'
		},
		{
			resource: 'roles_to_form',
			body: 'id,name'
		},
		args && args.id ? {
			resource: 'user',
			args: { userId: args.id },
			body: '__typename,id,username,name,organizationId,role,blocked,projects{id,name,role}'
		} : undefined
	].filter(q => q)
}



UserFormRef.dataMapper = ['projects', 'organizations', 'roles' ,'data']

UserFormRef.actions = [FORM_ACTIONS.SAVE]

export default UserFormRef
