export const handleManageProfiles = ({ active }, props) => {
	const { checkList, pushDialog, i18n, params, reload, ManageProfiles } = props;
	const modulesIds = (checkList.filter((x) => x.checked) || []).map((i) => ({
		id: i.id,
		name: i.name,
		status: i.status,
	}));

	if (modulesIds.length === 0) return true;

	pushDialog("ConfirmDialog", i18n`Manage Profiles`, {
		title: i18n`Are you sure you want to change status`,
		text: i18n`Please confirm that you want to proceed!`,
	})
		.then(() => {
			ManageProfiles({
				modulesIds: modulesIds.map((i) => parseInt(i.id)),
				profileId: params.id,
				sourceType: checkList[0].__typename,
				active,
			})
				.then(() => {
					reload && reload();
				})
				.catch((e) => console.log(e));
		})
		.catch((e) => console.log(e));
};

export const handleDownloadMultipleFiles = (props) => {
	const { checkList, pushDialog, i18n, params, downloadFiles, user } = props;
	const selectedFiles = (
		checkList.filter((x) => x.checked && x.status !== "deleted") || []
	).map((i) => ({
		id: i.id,
		name: i.name,
		status: i.status,
		fileLocation: i.fileLocation,
	}));

	if (selectedFiles.length === 0) return true;

	const filename = (selectedFiles.map((x) => x.name) || "").join(", ");

	pushDialog("ConfirmDialog", i18n`Download Files`, {
		title: i18n`Do you want to download the file${
			selectedFiles.length > 1 && `s`
		}: ${filename}?`,
		text: i18n`Please confirm that you want to proceed!`,
	})
		.then(() => {
			downloadFiles({
				fiscalYear: params.fiscalYear,
				projectId: params.projectId,
				files: selectedFiles,
				userId: user.id,
			});
		})
		.catch((e) => console.log(e));
};

export const handleReprocessFiles = (props) => {
	const { pushDialog, i18n, reload, managerFileLogs, checkList, user } = props;

	const selectedFiles = (
		checkList.filter((x) => x.checked && x.status !== "deleted") || []
	).map((i) => ({
		id: i.id,
		name: i.name,
		projectId: i.projectId,
		fiscalYear: i.fiscalYear,
		type: i.type,
		filePath: i.fileLocation,
		typename: i.__typename,
	}));

	if (selectedFiles.length === 0) return true;

	pushDialog("ConfirmDialog", i18n`Process All`, {
		title: i18n`Are you sure you want to process repository?`,
		text: i18n`Please confirm that you want to proceed!`,
	})
		.then(() => {
			managerFileLogs({
				files: selectedFiles,
				typename: selectedFiles[0].typename,
				authToken: user.authToken,
				resolver: "reprocess_file_logs",
			})
				.then(() => {
					reload && reload();
				})
				.catch(() => null);
		})
		.catch(() => null);
};

export const handleDeleteFiles = (props) => {
	const { pushDialog, i18n, reload, managerFileLogs, checkList, user } = props;

	const selectedFiles = (
		checkList.filter((x) => x.checked && x.status !== "deleted") || []
	).map((i) => ({
		id: i.id,
		name: i.name,
		projectId: i.projectId,
		fiscalYear: i.fiscalYear,
		type: i.type,
		filePath: i.fileLocation,
		typename: i.__typename,
		status: i.status,
	}));

	if (selectedFiles.length === 0) return true;

	pushDialog("ConfirmDialog", i18n`Process All`, {
		title: i18n`Are you sure you want to delete these files?`,
		text: i18n`Please confirm that you want to proceed!`,
	})
		.then(() => {
			managerFileLogs({
				files: selectedFiles,
				typename: selectedFiles[0].typename,
				authToken: user.authToken,
				resolver: "delete_file_logs",
			})
				.then(() => {
					reload && reload();
				})
				.catch(() => null);
		})
		.catch(() => null);
};

export const handleDeleteFilePermanently = (props) => {
	const {
		pushDialog,
		i18n,
		reload,
		checkList,
		params,
		deleteFilePermanently,
		user,
	} = props;

	const selectedFiles = (checkList.filter((x) => x.checked) || []).map((i) => ({
		id: i.id,
		name: i.name,
		status: i.status,
	}));

	if (selectedFiles.length === 0) return true;

	const filename = (selectedFiles.map((x) => x.name) || "").join(", ");

	pushDialog("ConfirmDialog", i18n`Delete File Permanently`, {
		title: i18n`Are you sure you want to delete permanently the file${
			selectedFiles.length > 1 && `s`
		}: ${filename}?`,
		text: i18n`Please confirm that you want to proceed!`,
	})
		.then(() => {
			deleteFilePermanently({
				fiscalYear: params.fiscalYear || "",
				projectId: params.projectId,
				files: selectedFiles.map((i) => parseInt(i.id)),
				userId: user.id,
			})
				.then(() => {
					reload && reload();
				})
				.catch(() => null);
		})
		.catch(() => null);
};

export const handleMarkAsDeleted = (props) => {
	const {
		pushDialog,
		i18n,
		reload,
		checkList,
		params,
		setDataFileStatus,
	} = props;

	const selectedFiles = (
		checkList.filter((x) => x.checked && x.status === "imported") || []
	).map((i) => ({
		id: i.id,
		name: i.name,
		status: i.status,
	}));

	if (selectedFiles.length === 0) return true;

	const filename = (selectedFiles.map((x) => x.name) || "").join(", ");

	pushDialog("ConfirmDialog", i18n`Mark as Delete`, {
		title: i18n`Are you sure you want to mark as delete the file${
			selectedFiles.length > 1 && `s`
		}: ${filename}?`,
		text: i18n`Please confirm that you want to proceed!`,
	})
		.then(() => {
			setDataFileStatus({
				fiscalYear: params.fiscalYear,
				projectId: params.projectId,
				files: selectedFiles.map((i) => parseInt(i.id)),
				status: "deleted",
			})
				.then(() => {
					reload && reload();
				})
				.catch(() => null);
		})
		.catch(() => null);
};

export const handleMarkAsActived = (props) => {
	const {
		pushDialog,
		i18n,
		reload,
		checkList,
		params,
		setDataFileStatus,
	} = props;

	const selectedFiles = (
		checkList.filter((x) => x.checked && x.status === "deleted") || []
	).map((i) => ({
		id: i.id,
		name: i.name,
		status: i.status,
	}));

	if (selectedFiles.length === 0) return true;

	const filename = (selectedFiles.map((x) => x.name) || "").join(", ");

	pushDialog("ConfirmDialog", i18n`Mark as Active`, {
		title: i18n`Are you sure you want to mark as active the file${
			selectedFiles.length > 1 && `s`
		}: ${filename}?`,
		text: i18n`Please confirm that you want to proceed!`,
	})
		.then(() => {
			setDataFileStatus({
				fiscalYear: params.fiscalYear,
				projectId: params.projectId,
				files: selectedFiles.map((i) => parseInt(i.id)),
				status: "imported",
			})
				.then(() => {
					reload && reload();
				})
				.catch(() => null);
		})
		.catch(() => null);
};

export const handleReprocessRepository = (props) => {
	const { pushDialog, i18n, reload, params, reprocessRepository, user } = props;

	pushDialog("ConfirmDialog", i18n`Process All`, {
		title: i18n`Are you sure you want to process repository?`,
		text: i18n`Please confirm that you want to proceed!`,
	})
		.then(() => {
			reprocessRepository({
				fiscalYear: params.fiscalYear,
				projectId: params.projectId,
				userId: user.id,
			})
				.then(() => {
					reload();
				})
				.catch(() => null);
		})
		.catch(() => null);
};

export const handleGstReturnSubmission = (props) => {
	const { pushModal, reload, checkList, params } = props;

	const selectedRows = (checkList || [])
		.filter((row) => row.checked)
		.map((row) => ({
			id: row.id,
		}));

	if (selectedRows.length === 0) {
		return true;
	} else {
		pushModal({
			title: "GSTR1 Submission",
			data: {
				fiscalYear: params.fiscalYear,
				projectId: params.projectId,
				gstin: "",
				period: null,
				invoiceIds: selectedRows.map((x) => x.id || "").join(","),
				__typename: "GstReturn",
			},
			args: {
				fiscalYear: params.fiscalYear,
				projectId: params.projectId,
			},
			view: "GstReturnSubmissionDialog",
		})
			.then(() => {
				reload();
			})
			.catch(() => null);
	}
};

export const handleGst2aRequestSubmit = (props) => {
	const { pushModal, reload, params } = props;

	pushModal({
		title: "GSTR2A Request Data",
		data: {
			fiscalYear: params.fiscalYear,
			projectId: params.projectId,
			gstin: "",
			period: null,
			__typename: "GstReturn",
		},
		args: {
			fiscalYear: params.fiscalYear,
			projectId: params.projectId,
		},
		view: "Gst2aRequestDialog",
	})
		.then(() => {
			reload();
		})
		.catch(() => null);
};
