import { format } from "../../utils"
import I18n from '../../I18n'

const mapCard = (json) => {
    const {
        d01,
        l01,
        f07,
        f08,
        n1f07,
        n1f08,
        n2f07,
        n2f08,
        n3f07,
        n3f08
    } = json

    return [
        [
            { labels: ['Account Id'], value: `${d01} - ${I18n.translate`${l01}`}`, type: 'string', basis: '25%' }

        ],
        [
            { labels: ['debit_balance'], value: format(f08 || 0), type: 'currency', basis: '10%' }

        ],
        [
            { labels: ['credit_balance'], value: format(f07 || 0), type: 'currency', basis: '10%' }

        ],
        [
            { labels: ['n1f07'], value: format(n1f08 || 0), type: 'currency', basis: '10%' }

        ],
        [
            { labels: ['n1f08'], value: format(n1f07 || 0), type: 'currency', basis: '10%' }

        ],
        [
            { labels: ['n2f07'], value: format(n2f08 || 0), type: 'currency', basis: '10%' }

        ],
        [
            { labels: ['n2f08'], value: format(n2f07 || 0), type: 'currency', basis: '10%' }

        ],
        [
            { labels: ['n3f07'], value: format(n3f08 || 0), type: 'currency', basis: '10%' }

        ],
        [
            { labels: ['n3f08'], value: format(n3f07 || 0), type: 'currency', basis: '10%' }
        ]
    ]
}

const header = [
    { label: ['Account Id'], align: 'left', basis: '25%' },
    { label: ['Current - Debit'], align: 'right', basis: '10%' },
    { label: ['Current - Credit'], align: 'right', basis: '10%' },
    { label: ['N-1 Debit'], align: 'right', basis: '10%' },
    { label: ['N-1 Credit'], align: 'right', basis: '10%' },
    { label: ['N-2 Debit'], align: 'right', basis: '10%' },
    { label: ['N-2 Credit'], align: 'right', basis: '10%' },
    { label: ['N-3 Debit'], align: 'right', basis: '10%' },
    { label: ['N-3 Credit'], align: 'right', basis: '10%' }
]

const mapper = (json, card) => {
    return {
        ...json,
        name: json.name,
        shortName: json.name,
        card: card ? mapCard(json) : [],
        link: {
            to: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/reports/statement_accounts_accumulated',
            filter: [
                { "filters": [{ "value": json.d01 }], "column": "account_id" },
                { "filters": [{ "value": `0-${json.a01}` }], "column": "transaction__period" }
            ]
        }
    }
}

const tribalanceannualMapper = {
    map: mapper,
    header
};

export default tribalanceannualMapper