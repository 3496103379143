import { buildRequestBody, alias } from '../../utils'
import { logoutSuccess } from './userActions'

const DEFAULT_PAGE_SIZE = 24

const fetchStart = (queries, locale) => (
	{
		type: 'FETCH_QUERIES_START',
		payload: queries,
		locale
	}
)

const fetchSuccess = (data, queries, locale) => (
	{
		type: 'FETCH_QUERIES_SUCCESS',
		locale,
		payload: {
			data,
			queries
		}
	}
)

const fetchReject = (queries, locale) => (
	{
		type: 'FETCH_QUERIES_REJECT',
		locale,
		payload: queries
	}
)

let controller
export const fetchAction = (queries = [], forceRefresh, notCancelable) => (
	(dispatch, getState) => {
		const { fetch: fetchReducer, locale } = getState()


		if (controller && controller.abort) {
			controller.abort()
		}
		const queriesStatus = fetchReducer.status
		const now = Date.now()

		const outdatedQueries = []
		const uptodateQueries = []
		queries.forEach((query) => {
			const statusKey = alias({
				...query,
				locale
			}, true)

			const status = queriesStatus[statusKey] || { lastReadTime: now }

			const queryPage = (query.args || {}).page
			const queryPageSize = (query.args || {}).pageSize
			const pagging = [queryPage || 0, queryPageSize]
			const statusPagging = (status.pagging || [])
			const samePage = queryPage !== undefined ? pagging[0] === Math.abs(statusPagging[0]) : true

			let append = status.pagging && ((pagging[0] || 0) > (status.pagging[0] || 0))

			let page = pagging[0]
			let pageSize = pagging[1]
			if (
				(!status.pagging && pagging[0] > 0)
			) {
				page = 0
				pageSize = (queryPageSize > 0 ? (pagging[0] + 1) * queryPageSize : (pagging[0] + 1) * DEFAULT_PAGE_SIZE)
				append = false
			}

			if (!forceRefresh && samePage && status.success) {
				const readDiff = (now - (status.lastReadTime || 0)) / 1000 / 60

				if (readDiff < 5) { // 5 minutes cache
					uptodateQueries.push({
						...query,
						append: false
					})
					return null
				}
			}
			if (pagging[0] !== undefined) {
				const statusPage = (status.pagging || [])[0] || 0
				const pageDiff = pagging[0] - statusPage

				if (pageDiff > 1) {
					page = 0
					pageSize = (pagging[0] + 1) * DEFAULT_PAGE_SIZE
					append = false
				}
			}
			if (outdatedQueries.findIndex(({ key }) => key === statusKey) < 0) {
				outdatedQueries.push({
					...query,
					args: {
						...(query.args || {}),
						page: (query.args || {}).page !== undefined ? page : undefined,
						pageSize
					},
					append,
					key: statusKey
				})
			}
		})

		const queriesBody = buildRequestBody(outdatedQueries, locale, getState().user ? getState().user.authToken :"" )
		if (queriesBody) {
			dispatch(fetchStart(outdatedQueries, locale))
			controller = notCancelable ? null : new AbortController()
			const signal = controller && controller.signal
			return fetch('/graphql', {
				signal,
				method: 'POST',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				},
				body: JSON.stringify(queriesBody)
			})
				.then((res) => res.json().catch(() => ({})))
				.then((res) => {
					controller = null
					if( Array.isArray(res.errors) ){
						for(let error of res.errors){
							switch (error.code) {
								case "403":
									return dispatch(logoutSuccess())
								case "GRAPHQL_VALIDATION_FAILED":
								case "GRAPHQL_PARSE_FAILED":
								case "404":
								case "401":
									return window.location.href = `/${locale || 'en'}/not_found`;
								default:
									break;
							}
						}
					} 
					const data = res.data || {}
					dispatch(fetchSuccess(data, [...uptodateQueries, ...outdatedQueries], locale))
				})
				.catch((e) => {
					controller = null
					dispatch(fetchReject(queries, locale))
				})
		}
	}
)