import { List, rows } from "colbi_web_ui/lib/components";
import FindingButtons from "../components/FindingButtons/FindingButtons";
import AppByFile from "../controllers/AppByFile/AppByFile";


const queries = (args = {}) => [
	{
		resource: "nc_rule_result",
		args: {
			projectId: args.projectId,
			fiscalYear: args.fiscalYear,
			modulePrefix: args.modulePrefix,
			id: args.id,
			importId: args.importId,
			locale: args.locale || "en",
		},
		body: `DescriptionDetail,RuleType,RuleDetailedFinding`,
	},
	{
		resource: `nc_findings_lines`,
		args: {
			projectId: args.projectId,
			fiscalYear: args.fiscalYear,
			modulePrefix: args.modulePrefix,
			id: args.id,
			importId: args.importId,
			locale: args.locale || "en",
			page: parseInt(args.page, 10) || 0,
			pageSize: args.pageSize || 24,
		},
		body: `
        items { 
					__typename,
					F1,
					F2,
					F3,
					F4,
					F5,
					F6,
					F7,
					F8,
					EntityID,
					EntityName,
					TypeByField
			}, hasMore, total, headerItems`,
	},
	{
		resource: `nc_findings_lines_export`,
		args: {
			projectId: args.projectId,
			fiscalYear: args.fiscalYear,
			modulePrefix: args.modulePrefix,
			id: args.id,
			importId: args.importId,
			locale: args.locale || "en",
			page: parseInt(args.page, 10) || 0,
			pageSize: args.pageSize || 24,
		},
		body: `
        items { 
					F1,
					F2,
					F3,
					F4,
					F5,
					F6,
					F7,
					F8,
					EntityName,
				}, hasMore, total, headerItems`,
		downloadable: true,
		downloadOnly: true,
	},
];

const findingRuleByFile = (
	path,
	navigation,
	exact,
	title,
	navByImportId = false
) => ({
	path,
	navigation,
	component: AppByFile,
	exact: true,
	navByImportId,
	queries,
	title: ["label(Audit)", title, "param(fiscalYear)"],
	components: [
		{
			component: FindingButtons,
			props: {
				hideDetailButton: "query(0).RuleDetailedFinding",
				ruleId: "param(id)",
				projectId: "param(projectId)",
				fiscalYear: "param(fiscalYear)",
				importId: "param(importId)",
				modulePrefix: "param(modulePrefix)",
				ruleDescription: "query(0).DescriptionDetail",
				origin: "R",
				title: ["query(0).RuleType", ' - ', "query(0).DescriptionDetail"],
			},
		},
		{
			component: List,
			modifiers: ["--root-margin-top-small", "--row-style-alternate"],
			props: {
				items: "query(1).list",
				card: rows.EntityCard,
				metadata: "query(1).metadata",
				page: "param(page)",
				status: "status(1)",
				pageSize: "param(pageSize)",
				pageDefault: 10,
				footer: "query(1).footer",
				header: "query(1).header",
			},
		},
	],
});

export default findingRuleByFile;
