export function getPrefixByFileType(fileType){
	switch (fileType) {
		case "SaftFile":
			return "saft_pt"
		case "SaftLtFile":
			return "saft_lt"
		case "CsvFile":
			return "lt_erp"
		case /LtErp.*/.test(fileType):
			return "lt_erp"
		case "SaftAoFile":
			return "saft_ao"
		default:
			return ""
	}
}

export function convertBytesToMegaBytes(value, precison = 2){
	if( value ){
		return ((value/ Math.pow(1024,2)).toFixed(precison)).toString()
	}
	return "N/A"
}