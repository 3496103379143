const appTheme = process && process.env && process.env.REACT_APP_COLBI_THEME ? process.env.REACT_APP_COLBI_THEME : 'colbi'

const locales = () => {
	if( appTheme === "taxivity" ){
		return [
			{
				id: "en",
				name: "EN",
			},
			{
				id: "lt",
				name: "LT",
			},
		];
	}

	return [
		{
			id: "en",
			name: "EN",
		},
		{
			id: "pt",
			name: "PT",
		},
		{
			id: "lt",
			name: "LT",
		},
	];
	
};

export default locales;
