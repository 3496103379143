import React from 'react'

import { ActionButton, RegularButton } from 'colbi_web_ui/lib/components/inputs'

import styles from './MultipleDownloadDialog.module.sass'


const MultipleDownloadDialog = (props) => {
    const { data, i18n, cancel } = props
    const { queries, download, locale } = data

    const optionButtons = queries.map((elem, index) => {
        return (
            <ActionButton
                key={index}
                className={`${styles['action-button']} ${styles['download']}`}
                onClick={
                    () => {
                        download && download([elem], locale)
                        cancel()
                    }
                }
            >
                <strong>{i18n`${elem.resource}`}</strong>
            </ActionButton>
        )
    })

    return (
        <div className={styles['root']}>
            <div className={styles['content']}>
                <div className={styles['actions']}>
                    <div className={styles['options']}>{optionButtons}</div>
                    <RegularButton
                        className={`${styles['action-button']} ${styles['close']}`}
                        type="button"
                        onClick={() => { cancel && cancel() }}
                    >
                        <strong>{i18n`Close`}</strong>
                    </RegularButton>
                </div>
            </div>
        </div>
    )
}

export default MultipleDownloadDialog