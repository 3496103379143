import { format, date } from "../../utils"

const mapCard = (json) => {
	const {
		transaction__transaction_id,
		account_id,
		debit_amount,
		credit_amount,
		description,
		transaction__transaction_date,
		general_ledger__account_description,
		transaction__period,
		transaction__journal_id
	} = json

	return [
		[
			{ labels: ["Id - Account"], value: `${account_id} - ${general_ledger__account_description || "N/A"}`, type: "string", basis: '25%' }
		],
		[
			{ labels: ['transaction__transaction_id'], value: transaction__transaction_id, type: 'string' }

		],
		[
			{ labels: ['description'], value: description, type: 'string' }

		],
		[
			{ labels: ['transaction__journal_id'], value: transaction__journal_id, type: 'string', basis: '8%' }

		],
		[
			{ labels: ['Transaction date'], value: date(transaction__transaction_date), type: 'date' }

		],
		[
			{ labels: ['transaction__period'], value: transaction__period, type: 'string', basis: '4%' }

		],
		[
			{ labels: ['debit_amount'], value: format(debit_amount), type: 'currency' }

		],
		[
			{ labels: ['credit_amount'], value: format(credit_amount), type: 'currency' }

		],
	]
}

const header = [
	{ label: ["Id - Account"], align: "left", basis: '25%' },
	{ label: ['Transaction Id'], align: 'left' },
	{ label: ['Description'], align: 'left' },
	{ label: ['Journal Id'], align: 'left', basis: '8%' },
	{ label: ['Transaction date'], align: 'center' },
	{ label: ['Period'], align: 'center', basis: '4%' },
	{ label: ['Debit Amount'], align: 'right' },
	{ label: ['Credit Amount'], align: 'right' },
]

const mapper = (json, card) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: {
			to: `/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId/:modulePrefix/transactions/${json.transaction_id}`,
			filter: [{ "filters": [{ "value": json.account_id }], "column": "account_id" }]
		}
	}
}

const offsettingaccountMapper = {
	map: mapper,
	header
};

export default offsettingaccountMapper