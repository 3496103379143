import React, { useState } from 'react'
import { useGlobalValue } from 'colbi_web_ui/lib/state/GlobalProvider'
import styles from './FileDetail.module.sass'
import { date, hydrateRoute, stringifyFilter } from 'colbi_web_ui/lib/utils'
import Link from 'colbi_web_ui/lib/components/Link/Link'
import { getPrefixByFileType } from '../../utils/index'
import { SimpleButton } from 'colbi_web_ui/lib/components/inputs'
import Icon from 'colbi_web_ui/lib/components/Icon/Icon'
import { NavLink } from 'react-router-dom'
import {camelize} from '../../utils/camelize'

const downloadPDF = (importId, setDownloading) => {
    setDownloading(true)
    fetch('/api/download_pdf', {
        method: 'POST',
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ variables: { importId } })
    }).then(function (t) {
        setDownloading(false)
        return t.blob().then((b) => {
            if (b && b.size > 20) {
                const a = document.createElement("a")
                const filename = t.headers.get("Filename")
                a.href = URL.createObjectURL(b)
                a.setAttribute("download", filename)
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
            } else {
                console.error('empty file')
            }
        }
        );
    }).catch(() => {
        setDownloading(false)
    })
}

const FileDetail = (props) => {

    const {
        modifiers,
        data
    } = props

    const { i18n, params } = useGlobalValue()
    const { locale, projectId, section, fiscalYear } = params
    const [downloading, setDownloading] = useState(false)
    const prefix = (data && data.type) ?  getPrefixByFileType(data.type) : ""
    const renderActivityDetails = () => (
        <>
            <h5>{data.name}</h5>
            <h6>{`${data.type} ${data.version} ${i18n`Document by`} ${data.uploadedByName} ${i18n`at`} ${date(data.uploadedAt)}`}</h6>
            <div className={styles['list-header']}>
                <span>{i18n`Information`}</span>
            </div>
            <ul>
                <li>
                    <small>{i18n`Document ID`}</small>
                    <p>{data.id}</p>
                </li>
                <li>
                    <small>{i18n`Document Type`}</small>
                    <p>{data.type}</p>
                </li>
                <li>
                    <small>{i18n`Document Version`}</small>
                    <p>{data.version}</p>
                </li>
            </ul>
            {data  && data.__typename !== 'StatementDetail' ? 
                <div>
                    <span>{i18n`Processing State`}</span>
                    <p>{data.status}</p>
                </div>
            : null } 
        </>
    )

    const renderFoldersDetails = () => (
        <>
            <h5>{data.name}</h5>
            <h6>{`${data.type} ${data.version} ${i18n`Document by`} ${data.uploadedByName} ${i18n`at`} ${date(data.uploadedAt)}`}</h6>

            <div>
                <span>{i18n`Information`}</span>
            </div>
            <ul>
                <li>
                    <small>{i18n`Document ID`}</small>
                    <p>{data.id}</p>
                </li>
                <li>
                    <small>{i18n`Document Type`}</small>
                    <p>{data.type}</p>
                </li>
                <li>
                    <small>{i18n`Document Version`}</small>
                    <p>{data.version}</p>
                </li>
            </ul>
            {data && (data.type === 'SaftFile' || data.type === 'SaftLtFile') ?
            <div>
                <div className={styles['list-header']}>
                    <span>{i18n`Content`}</span>
                    <span>{i18n`Count`}</span>
                </div>
                <ul className={styles['list-headers-summary']}>
                    {
                        (data.entitiesKpis || []).map(({entity, count}) =>{
                            return (
                                <li key={entity} >
                                    <small>{i18n`${camelize(entity)}`}</small>
                                    <p>{count || 0}</p>
                                </li>
                            )
                        })
                    }
                </ul>
            </div> : null  }
            {data  && data.__typename !== 'StatementDetail' ? 
            <div>
                <div className={styles['list-header']}>
                    <span>{i18n`Processing State`}</span>
                    <span>{i18n`Count`}</span>
                </div>
                <ul className={styles['list-severity-summary']}>
                    {
                        data && data.totalFindings && data.totalFindings.length ?
                            data.totalFindings
                                .sort((a, b) => b.severity.localeCompare(a.severity))
                                .map(({ severity, count }) => (
                                    <li key={severity}>
                                        <small>{i18n`${severity}`}</small>
                                        <p>{count}</p>
                                    </li>
                                ))
                        :
                        <small>{i18n`There are no results!`}</small>
                    }
                </ul>
            </div> : null}
        </>
    )

    const renderAttachmentsDetails = () => (
        <>
            <h5>{data.fileName}</h5>
            <h6>{`${i18n`Document uploaded by`} ${data.uploadedByName} ${i18n`at`} ${date(data.uploadedAt)}`}</h6>

            <div>
                <span>{i18n`Information`}</span>
            </div>
            <ul>
                <li>
                    <small>File Size</small>
                    <p>{data.size}</p>
                </li>
                <li>
                    <small>Document Type</small>
                    <p>{data.type}</p>
                </li>
            </ul>
        </>
    )

    const modifiersClasses = (modifiers || []).map(m => styles[m]).filter(m => m).join(' ')

    const renderDetail = () => {
        switch (data.__typename) {
            case 'InvalidDataFile':
            case 'StatementDetail':
                return renderActivityDetails()

            case 'AttachmentDetail':
                return renderAttachmentsDetails()

            default:
                return renderFoldersDetails()
        }
    }

    return (
        <div className={`${styles['detail']} ${modifiersClasses}`}>
            {(data && data.totalFindings && data.totalFindings.length > 0) &&
                <div className={styles['wrap-buttons']}>
                    <Link
                        to={
                            hydrateRoute(`../../../../${fiscalYear}/${prefix}/audit/?filter=${
                                stringifyFilter(
                                    [{ column: 'name', filters: [{ value: data.id + ' - ' + data.name }] }]
                                )
                                }&resetFilters=${Date.now()}`, {})}
                        className={styles['close']}
                        label={i18n`See Content`} />
                    <Link to={`/${locale}/${projectId}/repository/${section}/${fiscalYear}`} className={styles['close']}
                        label={i18n`X Close`} />
                </div>
            }
            {(data && data.status === "imported" && (data.type === 'PtMod22File' || data.type === 'SaftPtMod30File')) &&
                < div className={styles['wrap-buttons']}>
                    {data.type === "PtMod22File" ?
                        <Link to={`/${locale}/${projectId}/${fiscalYear}/statement/pt_mod_22/cover_page`} className={styles['close']} label=  {i18n`See Content`}/> 
                        :
                        <div>
                            <Link to={`/${locale}/${projectId}/${fiscalYear}/statement/pt_mod_30/file`} className={styles['close']} label = {i18n`See Content`} />
                        </div>
                    }
                </div>
            }
            {(data && data.fiscalYear && data.status !== 'imported' && data.__typename !== 'StatementDetail' && data.__typename !== 'AttachmentDetail') &&
                <div className={styles['wrap-buttons']}>
                    <Link
                        to={
                            hydrateRoute(`../../../../${prefix}/audit_findings/?filter=${
                                stringifyFilter(
                                    [{ column: 'import_id', filters: [{ value: `${data.id}` }] }]
                                )
                                }&resetFilters=${Date.now()}`, {})}
                        className={styles['close']}
                        label={i18n`Details`} />
                </div>
            }

            {(data && !data.fiscalYear && data.__typename !== 'StatementDetail' && data.__typename !== 'AttachmentDetail' ) &&
                <div className={styles['wrap-buttons']}>
                    <Link
                        to={
                            hydrateRoute(`../../../../audit_findings/?filter=${
                                stringifyFilter(
                                    [{ column: 'import_id', filters: [{ value: `${data.id}` }] }]
                                )
                                }&resetFilters=${Date.now()}`, {})}
                        className={styles['close']}
                        label={i18n`Details`} />
                </div>
            }

            {(data && data.__typename === 'AttachmentDetail') &&
                <div className={styles['wrap-buttons']}>
                    <SimpleButton className={styles['download-button']} onClick={() => {
                        downloadPDF(data.id, setDownloading)
                    }}><Icon name={downloading ? 'spinner' : 'download'} className={downloading ? styles['spin'] : ''} /></SimpleButton>

                    <NavLink to={`/${locale}/${projectId}/repository/${section}/${fiscalYear}`} className={styles['close']}>
                        {i18n`X Close`}
                    </NavLink>
                </div>
            }
            
            {(((data && !data.totalFindings) || (data && data.totalFindings && !data.totalFindings.length)) && data.__typename !== 'AttachmentDetail' ) &&
             <div className={styles['close-button']}>
                <Link to={`/${locale}/${projectId}/repository/${section}/${fiscalYear}/${section === 'invalid_files' ? '' : (data.typeAggregated + "/")}`} className={styles['close']}
                label={i18n`X Close`} />
            </div>
            }
            <div className={`${styles['content']} ${styles[section]}`}>{data && renderDetail()}</div>
        </div>
    )
}

export default FileDetail