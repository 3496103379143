import { StackLayout } from "../layouts";
import {
	List,
	Filters,
	rows,
	AuditSummaryWidget,
} from "colbi_web_ui/lib/components";
import theme from "colbi_web_ui/lib/style/_theme.json";
import { parseFilter } from "colbi_web_ui/lib/utils";
import AppByFile from "../controllers/AppByFile/AppByFile";
import ExportButton from "../components/ExportButton/ExportButton";

const queries = (args = {}) => {
	return [
		{
			resource: "nc_audit_summary",
			args: {
				projectId: args.projectId,
				fiscalYear: args.fiscalYear,
                modulePrefix: args.modulePrefix,
                importId: args.importId
			},
			body: "severity, count",
		},
		{
			resource: "nc_rules_summary",
			args: {
				projectId: args.projectId,
				fiscalYear: args.fiscalYear,
                modulePrefix: args.modulePrefix,
                importId: args.importId,
				filter: args.filter
					? { type: "[FilterInput!]", value: parseFilter(args.filter) }
					: undefined,
			},
			body:
				`__typename, hasMore, items{...nc_rules_summary}, filters(projectId:$projectId, fiscalYear:$fiscalYear, modulePrefix:$modulePrefix, importId:$importId){ prop, name, type, list, disableOperators, mandatory }`,
			downloadable: true,
			xlsxTranslation: `nc_rules_summary`
		},
	];
};

const auditByFile = (path, navigation, exact, title, navByImportId = false) => ({
	path,
	navigation,
	component: AppByFile,
	queries,
	exact,
	navByImportId,
	title: [title, "param(fiscalYear)"],
	components: [
		{
			component: AuditSummaryWidget,
			modifiers: ["--root-margin-bottom-normal"],
			props: {
				icon: "audit-icon",
				title: "label(Audit Results)",
				summary: "query(0).list",
			},
		},
		{
			component: Filters,
			props: {
				filter: "param(filter)",
				availableFilters: "query(1).filters",
				stickyTop: parseInt(theme.layout.$headerHeight, 10),
				anchor: "results",
			},
		},
		{
			component: ExportButton,
		},
	],
	routes: [
		{
			path,
			exact: true,
			queries,
			component: StackLayout,
			components: [
				{
					component: List,
					modifiers: ["--root-margin-top-small", "--row-style-alternate"],
					props: {
						id: "results",
						items: "query(1).list",
						filter: "param(filter)",
						card: rows.EntityCard,
						metadata: "query(1).metadata",
						page: "param(page)",
						status: "status(1)",
						pageSize: "param(pageSize)",
						pageDefault: 10,
						header: "query(1).header",
					},
				},
			],
		},
	],
});

export default auditByFile;
