import I18n from "../../I18n";
import { alias, format } from "../../utils";
import * as mappers from "../mappers";

const fetch = (
	state = { results: {}, status: {}, activity: false },
	action
) => {
	switch (action.type) {
		case "FETCH_QUERIES_START": {
			const result = JSON.parse(JSON.stringify(state));
			const queries = action.payload;
			queries.forEach(query => {
				const statusKey = alias(
					{
						...query,
						locale: action.locale
					},
					true
				);
				// const statusKey = `${ action.locale ? `${ action.locale }:` : '' }${ queryAlias }${ query.body ? `(${ query.body })` : '' }`
				const statusPagging = (
					result.status[statusKey] || { pagging: [undefined, undefined] }
				).pagging;
				result.status[statusKey] = {
					...(result.status[statusKey] || {}),
					loading: true,
					pagging: [(query.args || {}).page, statusPagging[1], statusPagging[2]]
				};
			});
			return {
				...result,
				activity: true
			};
		}
		case "FETCH_QUERIES_REJECT": {
			const result = JSON.parse(JSON.stringify(state));
			const queries = action.payload;

			queries.forEach(query => {
				const statusKey = alias(
					{
						...query,
						locale: action.locale
					},
					true
				);
				result.status[statusKey] = {
					...(result.status[statusKey] || {}),
					loading: false
				};
			});
			return {
				...result,
				activity: false
			};
		}
		case "FETCH_QUERIES_SUCCESS": {
			const result = JSON.parse(JSON.stringify(state));
			result.activity = false;
			const { data, queries } = action.payload;
			const now = Date.now();

			// TODO: add append logic - fetchAction should set if data is to append or not
			queries.forEach(query => {
				const queryAlias = alias({
					...query,
					locale: action.locale
				});
				const statusKey = alias(
					{
						...query,
						locale: action.locale
					},
					true
				);
				const modulePrefix = (query.args || {}).modulePrefix
				const queryData = data[queryAlias];

				if (queryData) {
					result.status[statusKey] = {
						success: true,
						loading: false,
						lastReadTime: Object.keys(queryData).length
							? now
							: (result.status[statusKey] || {}).lastReadTime,
						resultKey: queryAlias,
						pagging: [(query.args || {}).page, (query.args || {}).pageSize]
					};
					if (
						Array.isArray(queryData) ||
						(queryData && queryData.hasMore !== undefined)
					) {
						const items = queryData.items || queryData;
						const headerItems = queryData.headerItems || [];
						const entity =
							(items[0] || {}).__typename ||
							(queryData.__typename || "").replace(/s?Response$/, "") ||
							"";
						
						let mapper = modulePrefix && mappers[`${modulePrefix}`] && entity.toLowerCase() !== 'genericview' ? mappers[`${modulePrefix}`][`${entity.toLowerCase()}Mapper`] : mappers[`${entity.toLowerCase()}Mapper`];
						
						// QUICK FIX
						// NEED REAFACTOR
						if(mapper === undefined){
							mapper =  mappers[`${entity.toLowerCase()}Mapper`]
						}

						const currentList =
							query.append &&
								result.results[queryAlias] &&
								result.results[queryAlias].list
								? result.results[queryAlias].list
								: [];
						if (entity && mapper) {
							const concatedList = [
								...currentList,
								...items.map(json =>
									mapper.map(json, true, false, action.locale, queryData.entity)
								)
							];
							result.results[queryAlias] = {
								header: typeof mapper.header === "function" ? mapper.header(headerItems) : mapper.header,
								list: concatedList,
								filters:
									mapper && mapper.mapFilters
										? mapper.mapFilters(queryData.filters)
										: queryData.filters,
								footer: [
									queryData.total
										? I18n.translate`${format(
											concatedList.length,
											"normal",
											null
										)} of ${format(queryData.total, "normal", null)}`
										: ""
								],
								totalizers: queryData.totalizers,
								metadata: {
									summary: queryData.summary,
									hasMore: queryData.hasMore,
									total: queryData.total
								}
							};
						} else {
							const concatedList = [...currentList, ...items];
							result.results[queryAlias] = {
								...(result.results[queryAlias] || {}),
								filters:
									mapper && mapper.mapFilters
										? mapper.mapFilters(queryData.filters)
										: queryData.filters,
								footer: [
									queryData.total
										? I18n.translate`${format(
											concatedList.length,
											"normal",
											null
										)} of ${format(queryData.total, "normal", null)}`
										: ""
								],
								totalizers: queryData.totalizers,
								metadata: {
									summary: queryData.summary,
									hasMore: queryData.hasMore,
									total: queryData.total
								},
								list: concatedList
							};
						}
					} else if (
						queryData.lines &&
						(Array.isArray(queryData.lines) ||
							(queryData.lines && queryData.lines.hasMore !== undefined))
					) {
						const items = queryData.lines.items || queryData.lines;
						const entity =
							(items[0] || {}).__typename ||
							(queryData.lines.__typename || "").replace(/s?Response$/, "") ||
							"";
						const mapper = modulePrefix ? mappers[`${modulePrefix}`][`${entity.toLowerCase()}linesMapper`]: mappers[`${entity.toLowerCase()}linesMapper`];
						const currentList =
							query.append &&
								result.results[queryAlias] &&
								result.results[queryAlias].list
								? result.results[queryAlias].list
								: [];
						if (entity && mapper) {
							const concatedList = [
								...currentList,
								...items.map(json =>
									mapper.map(json, true, false, action.locale)
								)
							];

							const others = Object.keys(queryData)
								.filter(key => !["lines"].includes(key))
								.reduce((obj, key) => {
									obj[key] = queryData[key];
									return obj;
								}, {});

							result.results[queryAlias] = {
								others,
								header: mapper.header,
								list: concatedList,
								filters:
									mapper && mapper.mapFilters
										? mapper.mapFilters(queryData.lines.filters)
										: queryData.lines.filters,
								footer: [
									queryData.lines.total
										? I18n.translate`${format(
											concatedList.length,
											"normal",
											null
										)} of ${format(queryData.lines.total, "normal", null)}`
										: ""
								],
								totalizers: queryData.totalizers,
								metadata: {
									hasMore: queryData.lines.hasMore,
									total: queryData.lines.total
								}
							};
						} else {
							const concatedList = [...currentList, ...items];
							result.results[queryAlias] = {
								...(result.results[queryAlias] || {}),
								filters:
									mapper && mapper.mapFilters
										? mapper.mapFilters(queryData.filters)
										: queryData.filters,
								footer: [
									queryData.total
										? I18n.translate`${format(
											concatedList.length,
											"normal",
											null
										)} of ${format(queryData.total, "normal", null)}`
										: ""
								],
								totalizers: queryData.totalizers,
								metadata: {
									hasMore: queryData.hasMore,
									total: queryData.total
								},
								list: concatedList
							};
						}
					} else if (
						queryData[`${(modulePrefix || "")}_lines`] &&
						(Array.isArray(queryData[`${(modulePrefix || "")}_lines`]) ||
							(queryData[`${(modulePrefix || "")}_lines`] && queryData[`${(modulePrefix || "")}_lines`].hasMore !== undefined))
					) {
						const items = queryData[`${(modulePrefix || "")}_lines`].items || queryData[`${(modulePrefix || "")}_lines`];
						const entity =
							(items[0] || {}).__typename ||
							(queryData[`${(modulePrefix || "")}_lines`].__typename || "").replace(/s?Response$/, "") ||
							"";
						const mapper = modulePrefix && mappers[`${modulePrefix}`] ? mappers[`${modulePrefix}`][`${entity.toLowerCase()}linesMapper`]: mappers[`${entity.toLowerCase()}linesMapper`];
						const currentList =
							query.append &&
								result.results[queryAlias] &&
								result.results[queryAlias].list
								? result.results[queryAlias].list
								: [];
						if (entity && mapper) {
							const concatedList = [
								...currentList,
								...items.map(json =>
									mapper.map(json, true, false, action.locale)
								)
							];

							const others = Object.keys(queryData)
								.filter(key => !["lines"].includes(key))
								.reduce((obj, key) => {
									obj[key] = queryData[key];
									return obj;
								}, {});

							result.results[queryAlias] = {
								others,
								header: mapper.header,
								list: concatedList,
								filters:
									mapper && mapper.mapFilters
										? mapper.mapFilters(queryData[`${(modulePrefix || "")}_lines`].filters)
										: queryData[`${(modulePrefix || "")}_lines`].filters,
								footer: [
									queryData[`${(modulePrefix || "")}_lines`].total
										? I18n.translate`${format(
											concatedList.length,
											"normal",
											null
										)} of ${format(queryData[`${(modulePrefix || "")}_lines`].total, "normal", null)}`
										: ""
								],
								totalizers: queryData.totalizers,
								metadata: {
									hasMore: queryData[`${(modulePrefix || "")}_lines`].hasMore,
									total: queryData[`${(modulePrefix || "")}_lines`].total
								}
							};
						} else {
							const concatedList = [...currentList, ...items];
							result.results[queryAlias] = {
								...(result.results[queryAlias] || {}),
								filters:
									mapper && mapper.mapFilters
										? mapper.mapFilters(queryData.filters)
										: queryData.filters,
								footer: [
									queryData.total
										? I18n.translate`${format(
											concatedList.length,
											"normal",
											null
										)} of ${format(queryData.total, "normal", null)}`
										: ""
								],
								totalizers: queryData.totalizers,
								metadata: {
									hasMore: queryData.hasMore,
									total: queryData.total
								},
								list: concatedList
							};
						}
					} else {
						const entity = (queryData || {}).__typename || "";
						const mapper = modulePrefix && entity.toLowerCase() !== "genericview" && mappers[`${modulePrefix}`] ? mappers[`${modulePrefix}`][`${entity.toLowerCase()}Mapper`]: mappers[`${entity.toLowerCase()}Mapper`];

						if (mapper) {
							result.results[queryAlias] = mapper.map(queryData, false, true, action.locale, queryData.entity);
						} else {
							result.results[queryAlias] = queryData;
						}
					}
				} else {
					result.status[statusKey] = {
						...(result.status[statusKey] || {}),
						resultKey: queryAlias,
						loading: false,
						pagging: [(query.args || {}).page, (query.args || {}).pageSize]
					};
				}
			});

			return result;
		}
		case "LOGOUT_SUCCESS": {
			return { results: {}, status: {}, activity: false };
		}
		default:
			break;
	}
	return state;
};

export default fetch;
