// export default routes
import Login from '../controllers/Login/Login'
import ResetPassword from '../controllers/ResetPassword/ResetPassword'
import Settings from '../controllers/Settings/Settings'
import notFound from './notFound'
import { filteredRoutesByModules} from './manageRouting'
import SetNewPassword from '../controllers/SetNewPassword/SetNewPassowrd'
import ProjectsByFile from '../controllers/Projects/ProjectsByFile'

const defaultRoutes = [
	{
		path: '/:locale(pt|en|lt)?/login',
		component: Login,
		exact: true
	},
	{
		path: '/:locale(pt|en|lt)?/my_profile/reset_password',
		component: SetNewPassword,
		exact: false
	},
	{
		path: '/:locale(pt|en|lt)?/reset_password',
		component: ResetPassword,
		exact: true
	},
	{
		path: '/:locale(pt|en|lt)?',
		component: ProjectsByFile,
		exact: true
	},
	{
		path: '/:locale(pt|en|lt)?/organizations/:organizationId',
		component: ProjectsByFile,
		exact: true
	},
	{
		path: '/:locale(pt|en|lt)?/settings/:section?',
		component: Settings,
		exact: false
	},
	notFound('/:locale(pt|en|lt)?/not_found'),
]

const filteredRoutes = (modules) => {
	let getFilteredRoutes = filteredRoutesByModules(modules)
	
	return [
		...defaultRoutes,
		...getFilteredRoutes,
		notFound('/:locale(pt|en|lt)?/:projectId?/:fiscalYear([0-9]{4}|current)?/*'),
	]
}

export {filteredRoutes, defaultRoutes}