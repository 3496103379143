import {
	List,
	rows,
	Filters,
	charts,
	Grid,
	Widget,
} from "colbi_web_ui/lib/components";
import { parseFilter } from "colbi_web_ui/lib/utils";
import ExportButton from "../components/ExportButton/ExportButton";
import AppByFile from "../controllers/AppByFile/AppByFile";
import { checkForCustomFragment } from "../utils/queryUtils";

const queries = (args = {}) => {
	const entityResource = args.modulePrefix
		? `${args.modulePrefix}_${args.entity}`
		: args.entity;
	const result = [];

	const customEntityFragment = checkForCustomFragment(
		args.modulePrefix,
		args.entity
	);
	if (customEntityFragment) {
		result.push(
			{
				resource: entityResource,
				args: {
					projectId: args.projectId,
					fiscalYear: args.fiscalYear,
					modulePrefix: args.modulePrefix,
					importId: args.importId,
					filter: args.filter
						? { type: "[FilterInput!]", value: parseFilter(args.filter) }
						: undefined,
					sort: args.sort
						? { type: "[SortInput!]", value: parseFilter(args.sort) }
						: undefined,
					page: parseInt(args.page, 10) || 0,
				},
				body: `__typename, items{ ...${entityResource} }, hasMore, total, totalizers, filters(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix){ prop, name, type, list, disableOperators, mandatory }`,
			},
			{
				resource: `${entityResource}_summary`,
				args: {
					projectId: args.projectId,
					fiscalYear: args.fiscalYear,
					modulePrefix: args.modulePrefix,
				},
				body: "summary{ key, value }",
			},
			{
				resource: `${entityResource}_export`,
				args: {
					projectId: args.projectId,
					fiscalYear: args.fiscalYear,
					modulePrefix: args.modulePrefix,
					filter: args.filter
						? { type: "[FilterInput!]", value: parseFilter(args.filter) }
						: undefined,
					sort: args.sort
						? { type: "[SortInput!]", value: parseFilter(args.sort) }
						: undefined,
					page: parseInt(args.page, 10) || 0,
				},
				body: `items{...${entityResource}}`,
				downloadable: true,
				downloadOnly: true,
				xlsxTranslation: entityResource,
			}
		);
	} else {
		result.push(
			{
				resource: "generic_views",
				args: {
					projectId: args.projectId,
					fiscalYear: args.fiscalYear,
					importId: args.importId,
					modulePrefix: args.modulePrefix,
					entity: args.entity,
					filter: args.filter
						? { type: "[FilterInput!]", value: parseFilter(args.filter) }
						: undefined,
					sort: args.sort
						? { type: "[SortInput!]", value: parseFilter(args.sort) }
						: undefined,
					page: parseInt(args.page, 10) || 0,
				},
				body: `__typename, items, headerItems hasMore, total, totalizers, entity, filters(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix,entity:$entity,importId:$importId){ prop, name, type, list, disableOperators, mandatory }`,
				xlsxTranslation: args.entity,
			},
			{
				resource: `generic_view_summary`,
				args: {
					projectId: args.projectId,
					fiscalYear: args.fiscalYear,
					modulePrefix: args.modulePrefix,
					importId: args.importId,
					entity: args.entity,
				},
				body: "summary{ key, value }",
			},
			{
				resource: "generic_views_export",
				args: {
					projectId: args.projectId,
					fiscalYear: args.fiscalYear,
					importId: args.importId,
					modulePrefix: args.modulePrefix,
					entity: args.entity,
					filter: args.filter
						? { type: "[FilterInput!]", value: parseFilter(args.filter) }
						: undefined,
					sort: args.sort
						? { type: "[SortInput!]", value: parseFilter(args.sort) }
						: undefined,
					page: parseInt(args.page, 10) || 0,
				},
				body: `__typename, items, headerItems hasMore, total, totalizers, entity, filters(projectId:$projectId,fiscalYear:$fiscalYear,modulePrefix:$modulePrefix,entity:$entity,importId:$importId){ prop, name, type, list, disableOperators, mandatory }`,
				downloadable: true,
				downloadOnly: true,
				xlsxTranslation: args.entity,
			}
		);
	}
	return result.filter((q) => !!q.resource);
};

const transactionsByFile = (
	path,
	navigation,
	exact,
	title,
	navByImportId = false
) => ({
	path,
	navigation,
	component: AppByFile,
	queries,
	exact,
	navByImportId,
	title: [title, "label(param(entity))"].filter((l) => l),
	components: [
		{
			component: Widget,
			modifiers: ["--root-margin-bottom-small"],
			props: {
				data: "query(1).list",
				icon: "sales",
				title: ["Number of ", "param(entity)", " per type"],
				entity: "param(entity)",
				children: [
					{
						component: Grid,
						props: {
							columnsTemplate: [1],
							aspect: ["18x6"],
							items: [
								{
									data: "query(1).list",
									labelsPosition: "inside",
									stacked: true,
									summarized: true,
									links: [
										{
											to: [
												"param(projectId)",
												"param(fiscalYear)",
												"param(importId)",
												"param(modulePrefix)",
												"/",
												"param(entity)",
											],
											filterColumn: "period",
											options: {
												payments: "payment_type",
												stock_movements: "movement_type",
												transactions: "transaction_type",
												work_documents: "work_type",
												purchase_invoices: "invoice_type",
											},
										},
									],
								},
							],
							cards: [charts.BarChart],
						},
					},
				],
			},
		},
		{
			component: Filters,
			props: {
				filter: "param(filter)",
				availableFilters: "query(0).filters",
				stickyTop: 40,
				anchor: "results",
			},
		},
		{
			component: ExportButton,
		},
		{
			component: List,
			modifiers: ["--root-margin-top-small", "--row-style-alternate"],
			props: {
				id: "results",
				items: "query(0).list",
				filter: "param(filter)",
				sort: "param(sort)",
				basePath: path,
				cardKey: "param(entity)",
				card: rows.EntityCard,
				header: "query(0).header",
				metadata: "query(0).metadata",
				page: "param(page)",
				status: "status(0)",
				pageSize: "param(pageSize)",
				footer: "query(0).footer",
				totalizers: "query(0).totalizers",
			},
		},
	],
});

export default transactionsByFile;
