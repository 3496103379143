import I18n from '../../I18n'
import { datetime } from '../../utils'

const mapCard = (json) => {
	const {
		name,
		type,
		uploadedAt,
		submitedBy
	} = json

	return [
		[
			{ labels: ['name'], value: name, type: 'string', basis: '30%' }
		],
		[
			{ labels: ['user'], value: submitedBy, type: 'string', mobileHidden: true, basis: '30%' }
		],
		[
			{ labels: ['type'], value: I18n.translate`${type}`, type: 'string', basis: '20%', align: 'center' }
		],
		[
			{ labels: ['uploaded'], value: datetime(uploadedAt), type: 'datetime', mobileHidden: true, basis: '30%', align: 'center' }
		]

	]
}

const mapper = (json, card) => {
	return {
		...json,
		id: json.id,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: {
			to: `/:locale(pt|en)?/:projectId/repository/statements/${json.fiscalYear}/${json.id}`
		}
	}
}

const header = [
	{ label: ['Name'], align: 'left', columnName: "name", sortable: true, basis: '29.3%' },
	{ label: ['Uploaded by'], align: 'left', mobileHidden: true, basis: '30.7%' },
	{ label: ['Type'], align: 'center', basis: '20%' },
	{ label: ['Uploaded at'], align: 'center', mobileHidden: true, columnName: "uploaded_at", sortable: true, basis: '30%' },
]

const statementMapper = {
	map: mapper,
	header
};

export default statementMapper