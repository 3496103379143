import { date, format } from "../../../utils";

const mapDetails = (json) => {
	const {
		SapDocNo,
		SapDocType,
		InvoiceNo,
		InvoiceDate,
		Ctin,
		CustomerName,
		Pos,
		CustomerPinCode,
		CustomerStateCode,
		CustomerCountry,
		CgstAmount,
		IgstAmount,
		SgstAmount,
		UtilityCostAmount,
		ShipToAddress1,
		ShipToAddress2,
		ShipToPlace,
		ShipToPincode,
		ShipToStateCode,
		Currency,
		ExchangeRate,
		EwbTransporterID,
		EwbModeTransport,
		EwbTransportDocumentNo,
		EwbTranportDocumentDate,
		EwbVehicleNumber,
		EwbVehicleType,
		Irn,
		EwbNo,
		EwbDt,
		EwbValidTill,
		CancelDateEWB,
		CancelDateIRN,
		Status,
		CreatedAt,
		ResponseGenIRN,
	} = json;

	return [
		[
			{ label: "SAP Document No.", value: SapDocNo, type: "string" },
			{ label: "SAP Document Type", value: SapDocType, type: "string" },
			{ label: "Currency", value: Currency, type: "string" },
			{ label: "Exchange Rate", value: ExchangeRate, type: "string" },
		],
		[
			{ label: "Invoice No.", value: InvoiceNo, type: "string" },
			{ label: "Invoice Date", value: date(InvoiceDate), type: "string" },
			{ label: "IRN", value: Irn, type: "string" },
			{ label: "IRN Requested On", value: date(CreatedAt), type: "string" },
			{ label: "IRN Cancelled On", value: date(CancelDateIRN), type: "string" },
			{ label: "E-Invoice Status", value: Status, type: "string" },
		],
		[
			{ label: "Customer GSTIN", value: Ctin, type: "string" },
			{ label: "Customer Name", value: CustomerName, type: "string" },
		],
		[
			{ label: "Customer Country", value: CustomerCountry, type: "string" },
			{ label: "Customer Sate Code", value: CustomerStateCode, type: "string" },
			{ label: "Place Of Supply", value: Pos, type: "string" },
			{ label: "Customer Pincode", value: CustomerPinCode, type: "string" },
		],
		[
			{
				label: "Utility Cost Amount",
				value: format(UtilityCostAmount, null, null, 2),
				type: "string",
				align: "left",
			},
			{
				label: "CGST Amount",
				value: format(CgstAmount, null, null, 2),
				type: "string",
				align: "left",
			},
			{
				label: "SGST Amount",
				value: format(SgstAmount, null, null, 2),
				type: "string",
				align: "left",
			},
			{
				label: "IGST Amount",
				value: format(IgstAmount, null, null, 2),
				type: "string",
				align: "left",
			},
		],
		[
			{ label: "Ship To Address 1", value: ShipToAddress1, type: "string" },
			{ label: "Ship To Address 2", value: ShipToAddress2, type: "string" },
		],
		[
			{ label: "Ship To Place", value: ShipToPlace, type: "string" },
			{ label: "Ship To Pincode", value: ShipToPincode, type: "string" },
			{ label: "Ship To State Code", value: ShipToStateCode, type: "string" },
		],
		[
			{ label: "EWB No", value: EwbNo, type: "string" },
			{ label: "EWB Date", value: date(EwbDt), type: "string" },
			{ label: "EWB Valid Until", value: date(EwbValidTill), type: "string" },
			{ label: "EWB Cancelled On", value: date(CancelDateEWB), type: "string" },
		],
		[
			{ label: "EWB Transporter ID", value: EwbTransporterID, type: "string" },
			{ label: "EWB Mode Transport", value: EwbModeTransport, type: "string" },
			{
				label: "EWB Transport Document No",
				value: EwbTransportDocumentNo,
				type: "string",
			},
			{
				label: "EWB Tranport Document Date",
				value: date(EwbTranportDocumentDate),
				type: "string",
			},
		],
		[
			{ label: "EWB Vehicle Number", value: EwbVehicleNumber, type: "string" },
			{ label: "EWB Vehicle Type", value: EwbVehicleType, type: "string" },
		],
		[
			{
				label: "Errors",
				value: Status === "ERROR" ? formartJsonErrors(ResponseGenIRN) : "",
				type: "string",
			},
		],
	];
};

const formartJsonErrors = (jsonString) => {
	let data = "";
	const json = JSON.parse(jsonString);

	for (let item of json.ErrorDetails) {
		data =
			data +
			"Error Code: " +
			item.ErrorCode +
			"   " +
			"Error Message: " +
			item.ErrorMessage +
			" 	||	 ";
	}

	return data;
};

const mapCard = (json) => {
	const {
		SapDocNo,
		SapDocType,
		Status,
		InvoiceNo,
		InvoiceDate,
		Ctin,
		CustomerName,
		Pos,
		CgstAmount,
		IgstAmount,
		SgstAmount,
		TaxableValue,
		InvoiceValue,
	} = json;

	return [
		[
			{
				labels: ["SAP Document No."],
				value: SapDocNo,
				type: "string",
				basis: "15%",
			},
		],
		[
			{
				labels: ["SAP Document Type."],
				value: SapDocType,
				type: "string",
				basis: "15%",
			},
		],
		[{ labels: ["Invoice No."], value: InvoiceNo, type: "string" }],
		[
			{
				labels: ["Invoice Date"],
				value: date(InvoiceDate),
				type: "string",
			},
		],

		[{ labels: ["Customer GSTIN"], value: Ctin, type: "string" }],
		[{ labels: ["Customer Name"], value: CustomerName, type: "string" }],
		[{ labels: ["Place of Supply"], value: Pos, type: "string", basis: "7%" }],
		[
			{
				labels: ["CGST Amount (₹)"],
				value: format(CgstAmount, null, null, 2),
				type: "number",
				align: "right",
			},
		],
		[
			{
				labels: ["SGST Amount (₹)"],
				value: format(SgstAmount, null, null, 2),
				type: "number",
				align: "right",
			},
		],
		[
			{
				labels: ["IGST Amount (₹)"],
				value: format(IgstAmount, null, null, 2),
				type: "number",
				align: "right",
			},
		],
		[
			{
				labels: ["Taxable Value (₹)"],
				value: format(TaxableValue, null, null, 2),
				type: "number",
				align: "right",
			},
		],
		[
			{
				labels: ["Invoice Value (₹)"],
				value: format(InvoiceValue, null, null, 2),
				type: "number",
				align: "right",
			},
		],
		[{ labels: ["Status"], value: Status, align: "center", type: "string" }],
		[
			{
				labels: ["Send SAP"],
				value: [SapDocNo, Status],
				type: "sendSAP",
				align: "center",
				basis: "15%",
			},
		],
		[
			{
				labels: ["Download File"],
				value: [SapDocNo, Status],
				type: "downloadJson",
				basis: "15%",
			},
		],
	];
};

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : [],
	};
};

const header = [
	{
		label: ["Doc No."],
		align: "left",
		columnName: "SapDocNo",
		sortable: true,
		basis: "15%",
	},
	{
		label: ["DocType."],
		align: "left",
		columnName: "SapDocType",
		sortable: true,
		basis: "15%",
	},
	{
		label: ["Invoice No."],
		align: "left",
		columnName: "InvoiceNo",
		sortable: true,
	},
	{
		label: ["Invoice Date"],
		align: "left",
		columnName: "InvoiceDate",
		sortable: true,
	},
	{
		label: ["Customer GSTIN"],
		align: "left",
		columnName: "Ctin",
		sortable: false,
	},
	{
		label: ["Customer Name"],
		align: "left",
		columnName: "CustomerName",
		sortable: true,
	},
	{
		label: ["POS"],
		align: "left",
		columnName: "Pos",
		sortable: false,
		basis: "7%",
	},
	{
		label: ["CGST (₹)"],
		align: "right",
		columnName: "CgstAmount",
		sortable: false,
	},
	{
		label: ["SGST (₹)"],
		align: "right",
		columnName: "SgstAmount",
		sortable: false,
	},
	{
		label: ["IGST (₹)"],
		align: "right",
		columnName: "IgstAmount",
		sortable: false,
	},
	{
		label: ["Total Assessable (₹)"],
		align: "right",
		columnName: "IgstAmount",
		sortable: false,
	},
	{
		label: ["Invoice value (₹)"],
		align: "right",
		columnName: "IgstAmount",
		sortable: false,
	},

	{
		label: ["Status"],
		align: "center",
		columnName: "Status",
		sortable: true,
	},
	{
		label: ["Send IRN"],
		align: "center",
		columnName: "SapDocNo",
		sortable: false,
		basis: "15%",
	},
	{
		label: ["JSON TA"],
		align: "center",
		columnName: "SapDocNo",
		sortable: false,
		basis: "15%",
	},
];

const einvoiceMapper = {
	map: mapper,
	header,
};

export default einvoiceMapper;
