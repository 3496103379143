import labels from './labels.json'
import { format } from '../utils/index.js';

const typeInfoRegex = /^:([a-z])(\((.+)\))?/
const localizedStringsRegex = /({[0-9]+}(:[a-z])?)*/gi

const I18n = {
	use(locale) {
		I18n.locale = locale
		I18n.messageBundle = labels[locale] || {}
		return I18n.translate
	},

	translate(strings, ...values) {
		const isString = typeof strings === 'string'

		const translationKey = isString ? strings : I18n.buildKey(strings)
		const translationString = I18n.messageBundle[translationKey.toLowerCase()] || translationKey
		if (translationString) {
			if (isString) {
				const stringVariables = strings.match(localizedStringsRegex).filter((s) => s.length)
				if (stringVariables.length) {
					const subStrings = strings.split(/(\{[0-9]\}(:[scn])?)/gi).filter(s => s && !s.match(/^:/))
					const subValues = subStrings.filter(s => s.match(/^{[0-9]}.*/)).map(s => s.split(':'))
					const localizedValues = values.map((v, i) => {
						const typeInfoForValue = I18n.extractTypeInfo(`:${ subValues[i].slice(1) }`)
						const localized = v ? I18n.localize(v, typeInfoForValue || {}) : v
						return localized
					})
					const translationString = subStrings.map((s) => s.match(/^{[0-9]}:[scn]/) ? s.split(':')[0] : s)
					return I18n.buildMessage(translationString.join(''), ...localizedValues)
				}
				return I18n.buildMessage(translationString, ...values)
			}
			const typeInfoForValues = strings.slice(1).map(I18n.extractTypeInfo)
			const localizedValues = values.map((v, i) => I18n.localize(v, typeInfoForValues[i] || {}))
			return I18n.buildMessage(translationString, ...localizedValues)
		}

		return Array.isArray(strings) ? strings.join(' ') : strings
	},

	localizers: {
		s /* string */: v => (v || '').toLocaleString(I18n.locale),
		c /* currency */: (v, currency) => format(v, 'short', currency),
		n /* number */: (v, fractionalDigits) => format(v, 'short', null)
	},

	extractTypeInfo(str) {
		const match = typeInfoRegex.exec(str)
		if (match) {
			return { type: match[1], options: match[3] }
		}
		return { type: 's', options: '' }

	},

	localize(value, { type, options }) {
		const localized = I18n.localizers[type](value, options) || ''
		return I18n.messageBundle[localized.toLowerCase()] || localized
	},

	// e.g. I18n.buildKey(['', ' has ', ':c in the']) == '{0} has {1} in the bank'
	buildKey(strings) {
		const stripType = s => s.replace(typeInfoRegex, '')
		const lastPartialKey = stripType(strings[strings.length - 1])
		const prependPartialKey = (memo, curr, i) => `${ stripType(curr) }{${ i }}${ memo }`
		return strings.slice(0, -1).reduceRight(prependPartialKey, lastPartialKey)
	},

	// e.g. I18n.formatStrings('{0} {1}!', 'hello', 'world') == 'hello world!'
	buildMessage(str, ...values) {
		return str.replace(/{(\d)}/g, (_, index) => values[Number(index)])
	}
}

export default I18n
