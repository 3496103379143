import {
	ActionButton,
	RegularButton,
} from "colbi_web_ui/lib/components/inputs";
import { useGlobalValue } from "colbi_web_ui/lib/state/GlobalProvider";
import React from "react";
import styles from "./FindingButtons.module.sass";

const FindingButtons = (props) => {
	const {
		hideDetailButton = true,
		projectId,
		fiscalYear,
		importId,
		ruleId,
		modulePrefix,
		ruleDescription,
		origin,
		entityName,
		entityId,
		title,
	} = props;
	const {
		pushDialog,
		getFindingDetails,
		pushModal,
		reload,
		i18n,
	} = useGlobalValue();

	const data = {
		projectId,
		fiscalYear,
		importId,
		ruleId,
	};
	return (
		<div>
			<h3 className={styles["title"]}>{title}</h3>
			<div className={`${styles["finding-buttons"]}`}>
				<div className={`${styles["finding-button"]}`}>
					<RegularButton
						onClick={() => {
							pushModal({
								title: ruleDescription,
								data: { data },
								args: {
									id: ruleId,
									projectId,
									fiscalYear,
									importId,
									modulePrefix,
									origin,
									entityName,
									entityId,
								},
								view: "AuditNotesForm",
								modalType: "center",
							})
								.then(() => {
									reload && reload();
								})
								.catch(() => null);
						}}
					>
						{i18n`Audit Note`}
					</RegularButton>
				</div>
				{hideDetailButton ? null : (
					<div className={`${styles["finding-button"]}`}>
						<ActionButton
							primary
							onClick={() => {
								pushDialog &&
									pushDialog("ConfirmDialog", "Rule Execution", {
										title: `You want to run the rule in his totalidad?`,
										text: `Please confirm that you want to proceed!`,
									}).then(() => {
										getFindingDetails(
											projectId,
											fiscalYear,
											modulePrefix,
											ruleId,
											importId
										).then(() => {});
									});
							}}
						>
							Details
						</ActionButton>
					</div>
				)}
			</div>
		</div>
	);
};

export default FindingButtons;
