import Icon from "colbi_web_ui/lib/components/Icon/Icon";
import { useGlobalValue } from "colbi_web_ui/lib/state/GlobalProvider";
import React from "react";
import styles from "./ExportButton.module.sass";
import Connect from "colbi_web_ui/lib/components/Connect";
import Spinner from "colbi_web_ui/lib/components/Spinner/Spinner";

const ExportButton = (props) => {
	const { data } = props;
	const {
		download,
		downloadableQueries,
		locale,
	} = useGlobalValue();
	return (
		<Connect to={["search", "downloading"]}>
			{({ downloading }) => (
				<div>
					{data && data.length ? (
						<div className={`${styles["export-button"] || ""}`}>
							<button
								key="download"
								className={`${styles["action-button"]} ${
									downloading ? styles["has-activity"] : ""
								}`}
								onClick={() => {
									download && download(downloadableQueries, locale);
								}}
							>
								<Icon name="csv" />
								{downloading && <Spinner className={styles['spinner']} />}
							</button>
						</div>
					) : null}
				</div>
			)}
		</Connect>
	);
};

export default ExportButton;
