import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { parse } from "query-string";
import { connect } from "react-redux";

import I18n from "../../I18n";
import locales from '../../I18n/locales'

import { GlobalProvider as ColbiUIProvider } from "colbi_web_ui/lib/state/GlobalProvider";
import {
	ActionButton,
	Dropdown,
	TextInput,
} from "colbi_web_ui/lib/components/inputs";

import { login, forgetPassword, setLocale } from "../../store/actions";

import styles from "./Login.module.sass";
import { GlobalProvider } from "../../state/globalProvider";

import { UserRoles } from "../../enums/userRoles";
import { LocalePerTheme } from "../../enums/localePerTheme";
import { ContactEmailPerTheme } from "../../enums/contactEmailPerTheme";
import { decryptSession } from 'colbi_web_ui/lib/utils/roleValidator'

const Provider = ({ children, ...props }) => {
	return (
		<GlobalProvider {...props}>
			<ColbiUIProvider {...props}>{children}</ColbiUIProvider>
		</GlobalProvider>
	);
};

const localesByAppTheme = locales();

const Login = (props) => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [email, setEmail] = useState("");
	const [hideWelcome, setHideWelcome] = useState(false);
	const [hideForgot, setHideForgot] = useState(true);
	const [hideForgetPasswordMessage, setHideForgetPasswordMessage] = useState(
		true
	);

	const handleSubmit = (e) => {
		e.preventDefault();
		props.login(username, password);
	};

	const root = document.getElementsByTagName("html")[0];
	root.setAttribute("class", process.env.REACT_APP_COLBI_THEME);
	const theme = process.env.REACT_APP_COLBI_THEME;

	const {
		user,
		loginError,
		location,
		fetch,
		forgetPasswordMessage,
		match,
		history,
	} = props;

	const handleForgetPassword = (e) => {
		e.preventDefault();
		props.forgetPassword(email);
		setEmail("");
		setTimeout(() => {
			setHideForgetPasswordMessage(false);
		}, 500);
	};

	const locale =
		match.params.locale ||
		LocalePerTheme[process.env.REACT_APP_COLBI_THEME ? process.env.REACT_APP_COLBI_THEME.toUpperCase() : ""] ||
		"en";
	const i18n = I18n.use(locale);
	setLocale(locale);

	const version = (fetch.results[`en_application_version`] || "").version;

	const emailServiveActive = (fetch.results[`en_email_service_active`] || {})
		.active;

	let userInformation = decryptSession(user)

	if (userInformation) {
		const { redirect } = parse(location.search);
		return (
			<Redirect
				to={
					redirect || parseInt(userInformation.mysqlPasswordReset)
						? `/${locale}/reset_password`
						: userInformation.role === UserRoles.BACKOFFICE
						? `/${locale}/settings/`
						: `/${locale}/`
				}
			/>
		);
	}
	window.localStorage.clear();
	localStorage.setItem("theme", process.env.REACT_APP_COLBI_THEME);
	return (
		<Provider
			{...{
				i18n,
				location,
			}}
		>
			<div className={styles["root"]}>
				<div className={styles["login"]}>
					{theme ? <div className={styles["company-logo"]} /> : null}
					<div className={styles["login-container"]}>
						<div className={styles["details"]}>
							{/*Quick fix to remove logo in login page to ey theme*/}
							{theme && theme !== 'ey' ? (
								<h1 className={styles["title-with-logo"]}>{i18n`Sign In`}</h1>
							) : (
								<h1 className={styles["title"]}>{i18n`Sign in to Col.bi`}</h1>
							)}
							{loginError && (
								<div className={styles["error"]}>{loginError}</div>
							)}
							<form
								className={styles["form"]}
								onSubmit={(e) => {
									handleSubmit(e);
								}}
							>
								<TextInput
									type="text"
									autoComplete="off"
									autoCorrect="off"
									autoCapitalize="off"
									spellCheck="false"
									name={i18n`Email`}
									placeholder={i18n`Your Email`}
									required
									value={username}
									onChange={(e) => {
										setUsername(e.target.value);
									}}
								/>
								<TextInput
									type="password"
									autoComplete="off"
									name={i18n`Password`}
									placeholder={i18n`Your password`}
									required
									value={password}
									onChange={(e) => {
										setPassword(e.target.value);
									}}
								/>
								<div className={styles["login-actions"]}>
									<Dropdown
										value={locale.toUpperCase()}
										placeholder={""}
										options={localesByAppTheme}
										i18n={i18n}
										className={styles["dropdown"]}
										onChange={(e) => {
											history.push(`/${e.target.value.id || "en"}/login`);
											setLocale(e.target.value.id);
										}}
									/>
									<ActionButton
										icon="login-arrow"
										primary
										className={styles["action-button"]}
										type="submit"
									>
										{i18n`Sign in`}
									</ActionButton>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div className={styles["sidebar"]}>
					<div className={styles["background"]} />
					<div
						className={` ${styles["container-sidebar"]} ${
							hideWelcome ? styles["hide"] : "show"
						} `}
					>
						<h2 className={styles["title"]}>{i18n`Welcome`}</h2>
						<p className={styles["subtitle"]}>
							{i18n`login-page-description-${
								process.env.REACT_APP_COLBI_THEME
									? process.env.REACT_APP_COLBI_THEME.toUpperCase()
									: "Col.bi"
							}`}
						</p>
						<p
							className={styles["item-link"]}
							onClick={() => {
								setHideWelcome(true);
								setHideForgot(false);
							}}
						>
							{i18n`Forgot your password?`}
						</p>
					</div>
					<div
						className={` ${styles["container-sidebar"]} ${
							hideForgot ? styles["hide"] : "show"
						} `}
					>
						{!emailServiveActive ? (
							<div>
								<h2
									className={styles["title"]}
								>{i18n`Forgot your password?`}</h2>
								<p className={styles["subtitle"]}>
									{process.env.REACT_APP_COLBI_THEME &&
									ContactEmailPerTheme[
										process.env.REACT_APP_COLBI_THEME.toUpperCase()
									]
										? i18n`recover-password-message-${
												ContactEmailPerTheme[
													process.env.REACT_APP_COLBI_THEME.toUpperCase()
												]
										  }`
										: i18n`You should ask your system admin to reset your password.`}
								</p>
								<p
									className={styles["item-link"]}
									onClick={() => {
										setHideWelcome(false);
										setHideForgot(true);
										setHideForgetPasswordMessage(false);
									}}
								>
									{i18n`Understood!`}
								</p>
							</div>
						) : (
							<div className={styles["container-sidebar"]}>
								{!hideForgetPasswordMessage && forgetPasswordMessage && (
									<div
										className={
											forgetPasswordMessage.success
												? styles["success"]
												: styles["error"]
										}
									>
										{i18n`${forgetPasswordMessage.message}`}
									</div>
								)}
								<form
									className={styles["form"]}
									onSubmit={(e) => {
										handleForgetPassword(e);
									}}
								>
									<h2
										className={styles["title"]}
									>{i18n`Forgot your password?`}</h2>
									<TextInput
										type="text"
										autoComplete="off"
										autoCorrect="off"
										autoCapitalize="off"
										spellCheck="false"
										placeholder={i18n`Your email`}
										value={email}
										onChange={(e) => {
											setEmail(e.target.value);
											setHideForgetPasswordMessage(true);
										}}
									/>
									<div className={styles["reset-actions"]}>
										<ActionButton
											primary
											icon="forget-password"
											className={styles["action-button"]}
											type="submit"
										>
											{i18n`Reset Password`}
										</ActionButton>
										<p
											className={styles["item-link"]}
											onClick={() => {
												setHideWelcome(false);
												setHideForgot(true);
												setHideForgetPasswordMessage(true);
											}}
										>
											{i18n`Back!`}
										</p>
									</div>
								</form>
							</div>
						)}
					</div>
					{version && (
						<div className={styles["version"]}>
							<small>
								{i18n`Version`}: {version}
							</small>
						</div>
					)}
				</div>
			</div>
		</Provider>
	);
};

Login.queries = (args) => [
	{
		resource: "application_version",
		body: "version",
	},
	{
		resource: "email_service_active",
		body: "active",
	},
];

export default connect(
	({ user, fetch, loginError, forgetPasswordMessage }) => ({
		user,
		fetch,
		loginError,
		forgetPasswordMessage,
	}),
	{
		login,
		forgetPassword,
		setLocale,
	}
)(Login);
