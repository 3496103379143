import Repository from '../controllers/Repository/Repository'
import FileDetail from '../controllers/Repository/FileDetail'
import { StackLayout } from '../layouts'
import { rows, List, inputs, Filters } from 'colbi_web_ui/lib/components'
import { parseFilter } from 'colbi_web_ui/lib/utils'
import theme from 'colbi_web_ui/lib/style/_theme.json';
import HeaderListButtons from '../actions/HeaderListButtons/HeaderListButtons'
import UserActions from '../actions/UserActions/UserActions'

const queries = (args = {}) => {
	return ([
		{
			resource: (args.section),
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear || ""),
				fileType: (args.fileType || ""),
				sort: args.sort ? { type: '[SortInput!]', value: parseFilter(args.sort) } : undefined,
				filter: args.filter ? { type: '[FilterInput!]', value: parseFilter(args.filter) } : undefined,
			},
			body: `__typename, items{...${args.section}}, total, hasMore,filters(projectId:$projectId, fiscalYear:$fiscalYear){ prop, name, type, list, disableOperators }`,
			downloadable: true
		},
		args.id ? {
			resource: `${args.section}_detail`,
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				id: (args.id),
			},
			body: `...${args.section}_detail`
		} : {},
	]).filter(r => !!r.resource)
}

const repository = (path, showEfaturaRequestData) => ({
	path,
	component: Repository,
	exact: false,
	queries,
	components: [
		{
			component: List,
			modifiers: [
				'--root-margin-top-small',
				'--row-style-alternate'
			],
			props: {
				id: 'results',
				card: rows.EntityCard,
				headerComponent: {
					component: inputs.FileUploader,
					props: {
						lastReadTime: 'status(0).lastReadTime',
						total: 'query(0).metadata.total',
						userActions: {
							component: UserActions,
						}
					}
				},
				headerListComponent: {
					component: Filters,
					props: {
						filter: 'param(filter)',
						availableFilters: 'query(0).filters',
						stickyTop: parseInt(theme.layout.$headerHeight, 10),
						anchor: 'results'
					}
				},
				headerListButtonsActions: {
					component: HeaderListButtons,
					props: {
						showEfaturaRequestData: showEfaturaRequestData,
						showEfaturaDeleteData: showEfaturaRequestData,
					}
				},
				checkbox: true,
				items: 'query(0).list',
				header: 'query(0).header',
				footer: 'query(0).footer',
				metadata: 'query(0).metadata',
				page: 'param(page)',
				status: 'status(0)',
				pageSize: 'param(pageSize)',
				pageDefault: 10,
				filter: 'param(filter)',
				availableFilters: 'query(0).filters',
				sort: "param(sort)"
			}
		}
	],
	routes: [
		{
			path: '/:locale(pt|en|lt)?/:projectId/repository/:section/:fiscalYear/:fileType/:id',
			exact: true,

			queries,
			component: StackLayout,
			components: [
				{
					component: FileDetail,
					props: {
						data: 'query(1)'
					}
				}
			]
		}

	]
})

export default repository
