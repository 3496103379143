import { SectionTitle, List, rows } from 'colbi_web_ui/lib/components'
import { parseFilter } from 'colbi_web_ui/lib/utils'
import ExportButton from '../components/ExportButton/ExportButton'
import AppByFile from '../controllers/AppByFile/AppByFile'

const queries = (args = {}) => ([
	{
		resource: args.modulePrefix ? `${args.modulePrefix}_rule_result` : 'rule_result',
		args: {
			projectId: (args.projectId),
			fiscalYear: (args.fiscalYear),
			modulePrefix: args.modulePrefix,
			id: (args.id),
			locale: (args.locale) || 'en',
			filter: args.filter ? { type: '[FilterInput!]', value: parseFilter(args.filter) } : { type: '[FilterInput!]', value: [] },
			page: parseInt(args.page, 10) || 0,
			pageSize: (args.pageSize) || 24
		},
		body: `	
		ruleIdentifier,
    	description,
    	${args.modulePrefix}_lines(fiscalYear:$fiscalYear, projectId: $projectId, modulePrefix: $modulePrefix, id: $id, locale: $locale, page: $page, filter: $filter, pageSize:$pageSize){
			items { 
				__typename,
				id,
				type,
				message,
				referenceType,
				referenceId,
				severity,
				verified 
			}, hasMore, total
		}`,
		downloadable: true,
		xlsxTranslation: 'rule_result'
	}
])

const findingRule = (path, navigation, exact, title) => ({
	path,
	navigation,
	component: AppByFile,
	exact: true,
	queries,
	title: ['label(Audit)', title, 'param(fiscalYear)'],
	components: [
		{
			component: SectionTitle,
			props: {
				title: 'query(0).others.ruleIdentifier',
				subtitle: 'query(0).others.description'
			}
		},
		{
			component: ExportButton,
			props: {
				data: "query(0).list",
			}
		},
		{
			component: List,
			modifiers: [
				'--root-margin-top-small',
				'--row-style-alternate'
			],
			props: {
				items: 'query(0).list',
				card: rows.EntityCard,
				metadata: 'query(0).metadata',
				page: 'param(page)',
				status: 'status(0)',
				pageSize: 'param(pageSize)',
				pageDefault: 10,
				footer: 'query(0).footer',
				header: 'query(0).header'
			}
		}
	]
})

export default findingRule
