const searchStart = (q, projectId) => (
	{
		type: 'SEARCH_TERM_START',
		payload: q
	}
)

const searchSuccess = (data, q, projectId) => (
	{
		type: 'SEARCH_TERM_SUCCESS',
		payload: {
			data,
			q,
			projectId
		}
	}
)

let controller
export const searchAction = (q, location, projectId, fiscalYear, modulePrefix) => (
	(dispatch) => {
		if (controller && controller.abort) {
			controller.abort()
		}
		const query = {
			query: `query ($q: String, $location: [String!], $projectId: String, $fiscalYear: String, $modulePrefix: String) {
				${modulePrefix}_search_${ location.toLowerCase() }(q: $q, location: $location, projectId: $projectId, fiscalYear: $fiscalYear, modulePrefix: $modulePrefix) {
					__typename,
					id,
					name
				}
			}`,
			variables: {
				q,
				location,
				projectId,
				fiscalYear,
				modulePrefix: modulePrefix
			}

		}
		dispatch(searchStart(q, projectId))
		controller = new AbortController()
		const signal = controller.signal
		return fetch('/graphql', {
			signal,
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			},
			body: JSON.stringify(query)
		})
			.then((res) => res.json().catch(() => ({})))
			.then((res) => {
				const data = res.data || {}
				const searchData = (data[`${modulePrefix}_search_${ location.toLowerCase() }`] || [])
				dispatch(searchSuccess(searchData, q, projectId))
			})
			.catch((e) => {
				console.log(e)
			})
	}
)