import { format } from "../../utils"
import I18n from '../../I18n'

const mapCard = (json) => {
	const {
		d01,
		l01,
		n1f09,
		n1f10,
		n2f09,
		n2f10,
		n3f09,
		n3f10,
		byBalance,
		f09,
		f10
	} = json
	const byBalanceTranslation = byBalance.concat("_", "byBalance")
	return [
		[
			{ labels: ['taxonomy'], value: d01, type: 'string', basis: '7%' }

		],
		[
			{ labels: ['description'], value: I18n.translate`${l01}`, type: 'string', basis: '33%' }

		],
		[
			{ labels: ['Balance'], value: I18n.translate`${byBalanceTranslation}`, type: 'string', basis: '10%' }

		],
		[
			{ labels: ['debit_balance'], value: format(f10 || 0), type: 'currency', basis: '10%' }

		],
		[
			{ labels: ['credit_balance'], value: format(f09 || 0), type: 'currency', basis: '10%' }

		],
		[
			{ labels: ['n1_debit'], value: format(n1f10 || 0), type: 'currency', basis: '10%' }

		],
		[
			{ labels: ['n1_credit'], value: format(n1f09 || 0), type: 'currency', basis: '10%' }

		],
		[
			{ labels: ['n2_debit'], value: format(n2f10 || 0), type: 'currency', basis: '10%' }

		],
		[
			{ labels: ['n2_credit'], value: format(n2f09 || 0), type: 'currency', basis: '10%' }

		],
		[
			{ labels: ['n3_debit'], value: format(n3f10 || 0), type: 'currency', basis: '10%' }

		],
		[
			{ labels: ['n3_credit'], value: format(n3f09 || 0), type: 'currency', basis: '10%' }

		]
	]
}

const header = [
	{ label: ['Taxonomy'], align: 'left', basis: '7%' },
	{ label: ['Description'], align: 'left', basis: '33%' },
	{ label: ['by Balance'], align: 'left', basis: '13%' },
	{ label: ['Current - Debit'], align: 'right', basis: '10%' },
	{ label: ['Current - Credit'], align: 'right', basis: '10%' },
	{ label: ['N-1 Debit'], align: 'right', basis: '10%' },
	{ label: ['N-1 Credit'], align: 'right', basis: '10%' },
	{ label: ['N-2 Debit'], align: 'right', basis: '10%' },
	{ label: ['N-2 Credit'], align: 'right', basis: '10%' },
	{ label: ['N-3 Debit'], align: 'right', basis: '10%' },
	{ label: ['N-3 Credit'], align: 'right', basis: '10%' }
]

const mapper = (json, card) => {
	let toFilters;
	if (json.byBalance === "C") {
		toFilters = "f08";
	} else if (json.byBalance === "D") {
		toFilters = "f07";
	}

	return {
		...json,
		id: json.d01,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: {
			to: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/reports/trial_balance_annual',
			appendFilters: ['a01'],
			filter: json.byBalance !== "S" ? [{ "filters": json.accountId.map(elem => ({ "value": elem })), "column": "account_id" }, { "filters": [{ "value": json.a02 }], "column": "a02" }, { "filters": [{ "value": "0" }], "column": toFilters }]
				: [{ "filters": json.accountId.map(elem => ({ "value": elem })), "column": "account_id" }, { "filters": [{ "value": json.a02 }], "column": "a02" }]
		}
	}
}

const trialbalancetaxonomyannualdetailsMapper = {
	map: mapper,
	header
};

export default trialbalancetaxonomyannualdetailsMapper