import I18n from '../../I18n'
import { datetime, date } from '../../utils'
import { stringifyFilter } from 'colbi_web_ui/lib/utils'

const mapCard = (json) => {
	const {
		id,
		name,
		status,
		uploadedAt,
		startDate,
		endDate,
		submitedBy,
		statusCode,
	} = json

	const hasFindings = statusCode ? ((status === "imported" && statusCode === 1008) ? 2 : 3) : null

	return [
		[
			{ labels: ['id'], value: id, type: 'string', align: 'align-left', basis: '5%' }
		],
		[
			{ labels: ['name'], value: name, type: 'string', align: 'align-left', basis: '30%' }
		],
		[
			{ labels: ['user'], value: submitedBy, type: 'string', align: 'align-left', mobileHidden: true }
		],
		[
			{ labels: ['uploaded'], value: datetime(uploadedAt), type: 'datetime', mobileHidden: true, basis: "20%" }
		],
		[
			{
				labels: ['status'],
				value: [status, I18n.translate`${status}`],
				type: 'dataFileStatus',
			}
		],
		[
			{ labels: ['active'], value: hasFindings, type: 'is-active', basis: '10%', align: 'center' }
		],
		[
			{ labels: ['start'], value: date(startDate), type: 'datetime', mobileHidden: true }
		],
		[
			{ labels: ['end'], value: date(endDate), type: 'datetime', mobileHidden: true }
		],
		[
			{ labels: [], value: ['delete'], type: 'user_actions' }
		],
		[
			{ labels: [""], value: id, type: 'tooltipDownloadFileError', basis: "1%" }
		]

	]
}

const mapper = (json, card) => {
	const fileType = json.type.toString().startsWith("LtErp") ? "LtErpFile" : json.type.toString()
	const resType = fileType.replace("File", "")

	return {
		...json,
		id: json.id,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: {
			to: `/:locale(pt|en|lt)?/:projectId/repository/folders/${json.fiscalYear}/${resType}/${json.id}?filter=${stringifyFilter(
				[{ column: 'id', filters: [{ value: `${json.id}` }] }]
			)
				}`
		}
	}
}

const header = [
	{ label: ['Id'], align: 'left', basis: '5%', columnName: "name" },
	{ label: ['Name'], align: 'left', basis: '30%', columnName: "name", sortable: true },
	{ label: ['Uploaded by'], align: 'left', mobileHidden: true },
	{ label: ['Uploaded at'], align: 'center', mobileHidden: true, columnName: "uploaded_at", sortable: true, basis: "20%" },
	{ label: ['Status'], align: 'center' },
	{ label: ['Findings'], align: 'center', basis: '10%' },
	{ label: ["Start Date"], align: 'center', mobileHidden: true },
	{ label: ["End Date"], align: 'center', mobileHidden: true },
	{ label: ["checkbox"], align: 'center', width: 'actions-width' },
	{ label: [""], align: 'center', width: 'actions-width', basis: "1%" },
]

const datafileMapper = {
	map: mapper,
	header
};

export default datafileMapper