import I18n from '../../I18n'
import { datetime, formatNumberValues } from 'colbi_web_ui/lib/utils'

const mapCard = (json) => {
    const {
        requestUser,
        timestampStart,
        numberOfRecords,
        startDate,
        endDate,
        informationType,
        status,
        hasFindings,
    } = json

    return [
        [
            { labels: ['user'], value: requestUser, type: 'string', align: 'left', basis: '6%' }
        ],
        [
            { labels: ['timestamp_start'], value: datetime(timestampStart), type: 'string', align: 'center', basis: '10%' },
        ],
        [
            { labels: ['startDate'], value: startDate, type: 'string', align: 'center' }
        ],
        [
            { labels: ['endDate'], value: endDate, type: 'string', align: 'center' }
        ],
        [
            { labels: ['informationType'], value: I18n.translate`${informationType}`, type: 'string', align: 'center' }
        ],
        [
            { labels: ['status'], value: [status, I18n.translate`${status}`], type: 'dataFileStatus', align: 'center' }
        ],
        [
            { labels: ['active'], value: hasFindings ? 3 : 2, type: 'is-active', basis: '10%', align: 'center' }
        ],
        [
            { labels: ['number_of_records'], value: formatNumberValues(numberOfRecords), type: 'number', align: 'right' }
        ],
    ]
}

const mapper = (json, card) => {
    return {
        ...json,
        id: json.id,
        name: json.name,
        shortName: json.name,
        card: card ? mapCard(json) : [],
    }
}

const header = [
    { label: ['User'], align: 'left', columnName: "requestUser", basis: '15%' },
    { label: ['Request Date'], align: 'center', columnName: "timestamp_start" },
    { label: ['Start Date'], align: 'center', columnName: "startDate" },
    { label: ['End Date'], align: 'center', columnName: "endDate" },
    { label: ['Type'], align: 'center', columnName: "informationType" },
    { label: ['Status'], align: 'center', columnName: "status" },
    { label: ['Findings'], align: 'center', },
    { label: ['Number of Records'], align: 'right', columnName: "number_of_records" }
]

const deletedMapper = {
    map: mapper,
    header
};

export default deletedMapper