export const GLOBAL_ROUTE_PREFIX = "/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:importId";

const proxy = {
	FindingSummary: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/audit/rules/:id',
	Finding: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/audit/rules/:subId/findings/:id',
	FindingStructure: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/audit_structures/:id',
	FindingErrorStructure: '/:locale(pt|en|lt)?/:projectId/audit_findings/:id',
	
	Customer: `${GLOBAL_ROUTE_PREFIX}/:modulePrefix/master_data/customers/:id`,
	Account: `${GLOBAL_ROUTE_PREFIX}/:modulePrefix/master_data/accounts/:id`,
	Product: `${GLOBAL_ROUTE_PREFIX}/:modulePrefix/master_data/products/:id`,
	GeneralLedger: `${GLOBAL_ROUTE_PREFIX}/:modulePrefix/master_data/general_ledgers/:id`,
	GeneralLedgerAccount: `${GLOBAL_ROUTE_PREFIX}/:modulePrefix/master_data/general_ledger_accounts/:id`,
	Header: `${GLOBAL_ROUTE_PREFIX}/:modulePrefix/master_data/headers/:id`,
	Supplier: `${GLOBAL_ROUTE_PREFIX}/:modulePrefix/master_data/suppliers/:id`,
	Transaction: `${GLOBAL_ROUTE_PREFIX}/:modulePrefix/transactions/:id`,
	Invoice: `${GLOBAL_ROUTE_PREFIX}/:modulePrefix/invoices/:id`,
	PurchaseInvoice: `${GLOBAL_ROUTE_PREFIX}/:modulePrefix/purchase_invoices/:id`,
	StockMovement: `${GLOBAL_ROUTE_PREFIX}/:modulePrefix/stock_movements/:id`,
	WorkDocument: `${GLOBAL_ROUTE_PREFIX}/:modulePrefix/work_documents/:id`,
	Payment: `${GLOBAL_ROUTE_PREFIX}/:modulePrefix/payments/:id`,
	
	Report: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/reports/:id',
	ErpCustomer: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/lt_erp/master_data/customers/:id',
	ErpProduct: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/lt_erp/master_data/products/:id',
	ErpSupplier: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/lt_erp/master_data/suppliers/:id',
	ErpTransaction: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/lt_erp/general_ledgers/:id',
	ErpAccount: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/lt_erp/master_data/erp_accounts/:id',
	ErpGeneralLedger: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/lt_erp/general_ledgers/:id',
	ErpInvoice: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/lt_erp/sales_invoices',
	ErpPurchaseInvoice: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/lt_erp/purchase_invoices',
	ErpStockMovement: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/lt_erp/stock_movements',
	ErpPayment: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/lt_erp/payments',

	// To the Findgins links 
	CustomerOpenSalesInvoice: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/master_data/customers',
	SupplierOpenPurchaseInvoice: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/master_data/suppliers',
	AnalysisTypeTableEntry: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/master_data/analysis_types',
	MovementTypeTableEntry: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/master_data/movement_types',
	TransactionLineAnalysis: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/transactions',
	TransactionLineTaxInformation: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/transactions',
	AssetSupplier: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/master_data/assets',
	PurchaseInvoiceTaxInfoTotal: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/purchase_invoices',
	InvoiceTaxInformationTotal: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/invoices',
	AssetValuationImpairment: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/master_data/assets',
	PaymentLineAnalysis: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/payments',
	IlTaxInformation: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/invoice',
	AssetValuation: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/master_data/assets',
	PurchaseIlAnalysis: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/purchase_invoices',
	IlAnalysis: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/invoice',
	AnalysisTypes: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/master_data/analysis_types',
	StockMovementLineTaxInformation: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/stock_movements',
	PurchaseIlTaxInformation: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/purchase_invoices',
	CustomerAccount: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/master_data/customers',
	SupplierAccount: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/master_data/suppliers',
	PaymentSettlement: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/payments',
	PurchaseInvoiceSettlement: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/purchase_invoices',
	InvoiceSettlement: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/invoices',
	TaxTableEntry: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/master_data/tax_table_entry_details',
	AssetValuationAppreciation: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/master_data/assets',
	PhysicalStockAcquisition: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/master_data/physical_stocks',
	Journal: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/:modulePrefix/transactions',
	FileDetail: '/:locale(pt|en|lt)?/:projectId/repository/folders/:fiscalYear/:id',
	TrialBalanceTaxonomy: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/reports/trial_balance?filter=:projectId',

	LtCustomer: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/customers/:id',
	LtProduct: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/products/:id',
	LtSupplier: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/suppliers/:id',
	LtInvoice: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/invoices/:id',
	LtGeneralLedger: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/general_ledgers/:id',
	LtTransaction: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/transactions/:id',
	LtAccount: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/accounts/:id',
	LtHeader: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/headers/:id',
	LtPurchaseInvoice: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/purchase_invoices/:id',
	LtStockMovement: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/stock_movements/:id',
	LtWorkDocument: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/work_documents/:id',
	LtPayment: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/payments/:id',
	LtCustomerOpenSalesInvoice: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/customers',
	LtSupplierOpenPurchaseInvoice: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/suppliers',
	LtAnalysisTypeTableEntry: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/analysis_types',
	LtMovementTypeTableEntry: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/movement_types',
	LtTransactionLineAnalysis: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/transactions',
	LtTransactionLineTaxInformation: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/transactions',
	LtAssetSupplier: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/assets',
	LtAsset: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/assets/:id',
	LtPurchaseInvoiceTaxInfoTotal: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/purchase_invoices',
	LtInvoiceTaxInformationTotal: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/invoices',
	LtAssetValuationImpairment: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/assets',
	LtPaymentLineAnalysis: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/payments',
	LtIlTaxInformation: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/invoice',
	LtAssetValuation: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/assets',
	LtPurchaseIlAnalysis: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/purchase_invoices',
	LtIlAnalysis: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/invoice',
	LtAnalysisTypes: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/analysis_types',
	LtStockMovementLineTaxInformation: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/stock_movements',
	LtPurchaseIlTaxInformation: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/purchase_invoices',
	LtCustomerAccount: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/customers',
	LtSupplierAccount: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/suppliers',
	LtPaymentSettlement: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/payments',
	LtPurchaseInvoiceSettlement: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/purchase_invoices',
	LtInvoiceSettlement: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/invoices',
	LtTaxTableEntry: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/tax_table_entry_details',
	LtAssetValuationAppreciation: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/assets',
	LtPhysicalStockAcquisition: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/physical_stocks',
	LtJournal: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/transactions',

	LtPurchaseInvoiceLine: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/purchase_invoices',
	LtStockMovementLine: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/stock_movements',
	LtInvoiceLine: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/invoices',
	LtAssetTransaction: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/asset_transactions/:id',
	LtTransactionLine: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/transactions',
	LtPhysicalStock: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/physical_stocks/:id',
	LtPaymentLine: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/payments',
	LTTaxTableEntryDetail: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/tax_table_entry_details',
	LTOwner: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/owners/:id',
	LtPhysicalStockCustomsProcedure: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/physical_stocks',
	LtIlOrderReference: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/invoice',
	LtUomTableEntry: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/master_data/uom_table_entries',
	LtPaymentTaxInformationTotal: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/saft_lt/payments',

	//Saft Cv proxies
	CvHeader: `${GLOBAL_ROUTE_PREFIX}/saft_cv/master_data/headers/:id`,
	CvGeneralLedgerAccount: `${GLOBAL_ROUTE_PREFIX}/saft_cv/master_data/general_ledger_accounts/:id`,
	CvRule: `${GLOBAL_ROUTE_PREFIX}/saft_cv/audit/rules/:id`,
	CvSupplier: `${GLOBAL_ROUTE_PREFIX}/saft_cv/master_data/suppliers/:id`,
	CvProduct: `${GLOBAL_ROUTE_PREFIX}/saft_cv/master_data/products/:id`,
	CvTransaction: `${GLOBAL_ROUTE_PREFIX}/saft_cv/transactions/:id`,
	CvPayment: `${GLOBAL_ROUTE_PREFIX}/saft_cv/payments/:id`,
	CvWorkDocument: `${GLOBAL_ROUTE_PREFIX}/saft_cv/work_documents/:id`,
	CvPhysicalStock: `${GLOBAL_ROUTE_PREFIX}/saft_cv/master_data/physical_stocks/:id`,
	CvCustomer: `${GLOBAL_ROUTE_PREFIX}/saft_cv/customers/:id`,
	CVCustomer: `${GLOBAL_ROUTE_PREFIX}/saft_cv/customers/:id`,
	CVPayments: `${GLOBAL_ROUTE_PREFIX}/saft_cv/payments/:id`,
}

export default proxy
