import { format } from "../../utils"
import I18n from '../../I18n'

const mapCard = (json) => {
	const {
		name,
		currency,
		currencyN1,
		currencyN2,
		currencyN3
	} = json

	return [
		[
			{ labels: ['name'], value: I18n.translate`${name}`, type: 'string', basis: '30%' }
		],
		[
			{ labels: ['currency'], value: format(currency), type: 'currency', basis: '10%' }
		],
		[
			{ labels: ['currencyN1'], value: format(currencyN1 || 0), type: 'currency', basis: '10%' }
		],
		[
			{ labels: ['currencyN2'], value: format(currencyN2 || 0), type: 'currency', basis: '10%' }
		],
		[
			{ labels: ['currencyN3'], value: format(currencyN3 || 0), type: 'currency', basis: '10%' }
		]
	]
}

const header = [
	{ label: ['Description'], align: 'left', basis: '30%' },
	{ label: ['Current'], align: 'right', basis: '10%' },
	{ label: ['N -1'], align: 'right', basis: '10%' },
	{ label: ['N -2'], align: 'right', basis: '10%' },
	{ label: ['N -3'], align: 'right', basis: '10%' },
]

const mapper = (json, card) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: {
			to: '/:locale(pt|en|lt)?/:projectId/:fiscalYear([0-9]{4}|current)/reports/trial_balance_taxonomy_annual_details',
			appendFilters: ['a01'],
			filter: [{ "filters": json.taxonomyCode.map(elem => ({ "value": elem })), "column": "d01" }, { "filters": [{ "value": json.code }], "column": "code" }, { "filters": [{ "value": json.a02 }], "column": "a02" }]
		}
	}
}

const mapFilters = (filters) => {
	return filters.map((filter) => {
		if (filter.prop === 'd_02') {
			return {
				...filter,
				listMapper: {
					'01AMLP': I18n.translate`Non-Current Assets`,
					'02ACP': I18n.translate`Current Assets`,
					'03EQUITY': I18n.translate`Equity`,
					'04LIABILITIES_MLP': I18n.translate`Non-Current Liability`,
					'05LIABILITIES_CP': I18n.translate`Current Liability`,
					'02RAD': I18n.translate`Operating income before depreciation`,
					'02RO': I18n.translate`Operating Income`,
					'02RAI': I18n.translate`Earning before tazes`,
					'02RLP': I18n.translate`Net result for the year`,
					'01ASSETS': I18n.translate`Current Assets`,
				}
			}
		}
		if (filter.prop === 'l_01') {
			return {
				...filter,
				listMapper: {
					'Capital Proprio + Passivo': I18n.translate`Liabilities and Owner's Equitity`,
					'Ativo': I18n.translate`Assets`,
					'Gastos': I18n.translate`Expenses`,
					'Rendimentos': I18n.translate`Income`,
					'Resultados': I18n.translate`resultados`,
				}
			}
		}

		return filter
	})
}

const balanceannualMapper = {
	map: mapper,
	mapFilters,
	header
};

export default balanceannualMapper