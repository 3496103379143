import I18n from "../../I18n";
import { datetime } from "../../utils";

const mapDetails = (json) => {
	const { AuditNote, AuditStatus, CreatedBy, ModifiedBy } = json;
	return [
		[{ labels: ["AuditNote"], value: AuditNote, type: "string" }],
		[{ labels: ["AuditStatus"], value: AuditStatus, type: "string" }],
		[{ labels: ["CreatedBy"], value: CreatedBy, type: "string" }],
		[{ labels: ["ModifiedBy"], value: ModifiedBy, type: "string" }],
	];
};

const mapCard = (json) => {
	const {
		AuditNote,
		AuditStatus,
		CreatedBy,
		ModifiedBy,
		Origin,
		CreatedAt,
		UpdatedAt,
		EntityName,
		RowID,
		Source
	} = json;
	return [
		[{ labels: ["AuditNote"], value: AuditNote, type: "string" }],
		[{ labels: ["AuditStatus"], value: [AuditStatus.replaceAll(' ', '-').toLowerCase(), AuditStatus, 'center'], type: "status" }],
		[{ labels: ["Origin"], value: I18n.translate`${Origin}`, type: "string", align: "center", }],
		[{ labels: ["CreatedBy"], value: CreatedBy, type: "string" }],
		[{ labels: ["ModifiedBy"], value: ModifiedBy, type: "string" }],
		[
			{
				labels: ["Created At"],
				value: datetime(CreatedAt),
				type: "datetime",
			},
		],
		[{ labels: ["Updated At"], value: datetime(UpdatedAt), type: "datetime" }],
		[{
			labels: ["Entity"], value: {
				id: Source || RowID,
				name: EntityName,
				__typename: `Cv${EntityName}`,
			},
			type: "object",
			align: "center",
		}]
	];
};

const mapper = (json, card, details) => {
	return {
		id: json.id,
		...json,
		name: json.id,
		shortName: json.id,
		card: card ? mapCard(json) : [],
		details: details ? mapDetails(json) : [],
	};
};

const header = [
	{ label: ["Audit Note"], align: "left", sortable: true, columnName: "AuditNote" },
	{ label: ["Audit Status"], align: "center", sortable: true, columnName: "AuditStatus" },
	{ label: ["Origin"], align: "center", sortable: true, columnName: "Origin" },
	{ label: ["Created By"], align: "left" },
	{ label: ["Modified By"], align: "left" },
	{ label: ["Created At"], align: "center" },
	{ label: ["Updated At"], align: "center" },
	{ label: ["Entity"], align: "center" }
];

const auditnoteMapper = {
	map: mapper,
	header
};

export default auditnoteMapper