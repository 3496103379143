import React, { useEffect, useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { renderRoutes, matchRoutes } from "react-router-config";
import { connect } from "react-redux";
import { parse } from "query-string";
import { changeDeepObject, mapPropertiesForMenu } from "../../utils";

import { GlobalProvider as ColbiUIProvider } from "colbi_web_ui/lib/state/GlobalProvider";
import {
	mapProperties,
	hydrateRoute,
	stringifyFilter,
} from "colbi_web_ui/lib/utils";

import AsideMenu from "colbi_web_ui/lib/components/layout/AsideMenu/AsideMenu";
import Header from "colbi_web_ui/lib/components/layout/Header/Header";
import Connect from "colbi_web_ui/lib/components/Connect";

import { GlobalProvider } from "../../state/globalProvider";
import MainTabs from "../App/MainTabs/MainTabs";

import ModalController from "../App/ModalController/ModalController";
import DialogController from "../App/DialogController/DialogController";

import { ImageRender, LoadingIndicator } from "colbi_web_ui/lib/components";
import {
	logout,
	pushModal,
	pushDialog,
	popModal,
	uploadAction,
	downloadAction,
	toggleMenu,
	setLocale,
	auditNoteSubmit,
	removeModal,
	setDataFileStatusAction,
	deleteFilePermanentlyAction,
	deleteInvalidFilePermanentlyAction,
	hideFileAction,
	downloadFileAction,
	reprocessRepositoryAction,
	searchAction,
	getFindingDetails,
	sendSAPAction,
	downloadJSONAction,
} from "../../store/actions";
import I18n from "../../I18n";
import locales from "../../I18n/locales";
import theme from "../../style/_theme.json";
import proxy from "../../routes/proxy";

import { persistor } from "../../../src/index";
import styles from "./AppByFile.module.sass";
import { PersistGate } from "redux-persist/integration/react";
import { UserRoles } from "../../enums/userRoles";
import {
	filteredQueriesByModules,
	filteredQueriesByModulesForMenu,
	getFirstModuleOfArray,
} from "../../routes/manageRouting";
import socketIOClient from "socket.io-client";
import {
	checkRoles,
	decryptSession,
} from "colbi_web_ui/lib/utils/roleValidator";

const Provider = ({ children, ...props }) => {
	return (
		<GlobalProvider {...props}>
			<ColbiUIProvider {...props}>{children}</ColbiUIProvider>
		</GlobalProvider>
	);
};
const localesByAppTheme = locales();
const logo = () => {
	return (
		process &&
		process.env && (
			<ImageRender
				icon={`${
					process.env.REACT_APP_COLBI_THEME
						? `${process.env.REACT_APP_COLBI_THEME}-logo`
						: "logo"
				}`}
			/>
		)
	);
};

const items = (i18n) => {
	let data = [
		{ type: "invoice", name: i18n`Invoice` },
		{ type: "payment", name: i18n`Payment` },
		{ type: "stock_movement", name: i18n`Stock Movement` },
		{ type: "transaction", name: i18n`Transaction` },
		{ type: "customer", name: i18n`Customer` },
		{ type: "supplier", name: i18n`Supplier` },
		{ type: "product", name: i18n`Product` },
	];

	if (process.env.REACT_APP_LOCATION === "PT") {
		data.push(
			{ type: "work_document", name: i18n`Work Document` },
			{ type: "general_ledger", name: i18n`General Ledger` }
		);
	}

	if (process.env.REACT_APP_LOCATION === "LT") {
		data.push(
			{ type: "physical_stock", name: i18n`Physical Stock` },
			{ type: "asset", name: i18n`Asset` },
			{ type: "purchase_invoice", name: i18n`Purchase Invoice` },
			{ type: "general_ledger_account", name: i18n`General Ledger Account` },
			{ type: "asset_transaction", name: i18n`Asset Transaction` },
			{ type: "owner", name: i18n`Owner` }
		);
	}

	if (process.env.REACT_APP_LOCATION === "AO") {
		data.push({ type: "purchase_invoice", name: i18n`Purchase Invoice` });
	}

	return data;
};

const AppByFile = (props) => {
	const {
		user,
		fetch,
		menu,
		logout,
		pushModal,
		popModal,
		toggleMenu,
		searchAction,
		setLocale,
		match,
		route,
		location,
		history,
		reload,
		uploads,
		pushDialog,
		uploadFiles,
		download,
		submitAuditNote,
		setDataFileStatus,
		deleteFilePermanently,
		deleteInvalidFilePermanently,
		hideFile,
		downloadFile,
		sendSAP,
		downloadJSON,
		reprocessRepository,
		auditNote,
		getFindingDetails,
	} = props;

	const locale = match.params.locale || "en";

	useEffect(() => {
		const pop = history.action === "POP";
		setLocale(locale);
		if (!pop && !fetch.activity) {
			if (location.hash) {
				const scrollElement = window.document.querySelector(location.hash);
				if (scrollElement) {
					const headerOffset = 70;

					const previousRect =
						scrollElement.previousElementSibling.getBoundingClientRect();
					let stuckOffset = previousRect.height;
					const elementPosition = scrollElement.offsetTop;
					const offsetPosition =
						elementPosition - headerOffset - stuckOffset - 100;

					setTimeout(() => {
						window.scrollTo({
							top: offsetPosition,
							behavior: "smooth",
						});
					}, 0);
				}
			} else if (location.search.search("page") === -1) {
				setTimeout(() => {
					window.scrollTo(0, 0);
				}, 0);
			}
		}
	}, [
		fetch.activity,
		location.hash,
		location.pathname,
		location.search,
		history.action,
		locale,
		setLocale,
	]);
	const [hover, setHover] = useState(false);

	let userInformation = decryptSession(user);
	const socketOptions = {
		reconnection: false,
	};
	useEffect(() => {
		const socket = socketIOClient("http://192.168.1.125:4001", socketOptions);
		socket.on(`${match.params.projectId}_notifications`, (data) => {
			setResponse((prevState) => [...prevState, data]);
		});
		socket.on("connect_error", (err) =>
			console.log("SocketIO cannot reach the server")
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [response, setResponse] = useState([]);

	if (!userInformation) {
		return <Redirect to={`/${locale}/login`} push />;
	}

	if (userInformation && parseInt(userInformation.mysqlPasswordReset)) {
		return <Redirect to={`/${locale}/reset_password`} />;
	}

	if (userInformation && userInformation.role === UserRoles.BACKOFFICE) {
		return <Redirect to={`/${locale}/settings/`} />;
	}

	const { projectId, modulePrefix } = match.params;
	const organizations = (
		fetch.results[`${locale}_user_organizations`] || { list: [] }
	).list;

	let hasFullNavigation = "";
	const modulesOrganizations = (organizations || []).reduce((res, item) => {
		if (item.projects) {
			for (const projectTemp of item.projects) {
				const { modules } = projectTemp;
				const modulePrefix = getFirstModuleOfArray(
					(modules || []).map((elem) => elem.name)
				);
				projectTemp["menuModule"] = modulePrefix;
				if (projectTemp.id === projectId) {
					const moduleTypeOfNavigation = modules.find(
						(elem) => elem.name === modulePrefix
					);
					hasFullNavigation =
						moduleTypeOfNavigation &&
						moduleTypeOfNavigation.navigation_by_import_id
							? ""
							: "full";
				}
			}
		}
		res.push(item);
		return res;
	}, []);

	const version = (fetch.results[`${locale}_application_version`] || "")
		.version;

	if (!projectId) {
		return <Redirect to={`${locale ? `/${locale}` : ""}/`} />;
	}

	const i18n = I18n.use(locale);

	const project =
		fetch.results[`${locale}_project_targetProjectId_${projectId}`] || {};

	const fiscalYears = Object.keys(project.fiscalYears || []);
	const fiscalYearsWithImportId = project.fiscalYears || [];

	if (
		projectId &&
		!match.params.fiscalYear &&
		!match.params.importId &&
		!fiscalYears.length
	) {
		return (
			<Redirect to={`${locale ? `/${locale}` : ""}/${projectId}/repository`} />
		);
	}

	const fiscalYearsStatus =
		fetch.status[
			`${locale}_project_targetProjectId_${projectId}(name, description, fiscalYears, taxRegistrationNumber)`
		] || [];

	let fiscalYear;

	if (match.params.fiscalYear) {
		fiscalYear = match.params.fiscalYear;
	} else if (project && project.fiscalYears) {
		fiscalYear =
			fiscalYears.length > 0 ? Math.max(...fiscalYears) : "repository";
	}

	const importIds =
		fiscalYearsWithImportId[`${match.params.fiscalYear || fiscalYear}`];
	const importId = match.params.importId
		? match.params.importId
		: importIds && importIds.length
		? Math.max(...importIds)
		: "";

	const repositoryStatus = fetch.results[
		`${locale}_repository_status_fiscalYear_${fiscalYear}_projectId_${projectId}`
	] || { status: 1 };

	//############ Build Generic Menu  ############
	const projectModules = (
		fetch.results[`${locale}_project_modules_projectId_${projectId}`] || {}
	).modules;
	const queriesForMenu = filteredQueriesByModulesForMenu(projectModules);
	const navData = mapPropertiesForMenu(
		queriesForMenu,
		fetch.results,
		locale,
		fiscalYear,
		projectId,
		importId
	);

	const importIdToUse =
		hasFullNavigation.length > 0 ? hasFullNavigation : importId;

	if (
		fiscalYear &&
		fiscalYear !== match.params.fiscalYear &&
		match.params.importId !== importIdToUse
	) {
		const modulePrefix = getFirstModuleOfArray(projectModules);
		const to = hydrateRoute(match.path, {
			...match.params,
			fiscalYear,
			importId: importIdToUse,
			modulePrefix,
		});

		if (to !== match.url && !to.match(/\*$/)) {
			return <Redirect to={to} />;
		}
	}

	if (match.params.entities !== "audit_findings") {
		if (
			fiscalYearsStatus.success &&
			!fiscalYears.length &&
			!match.url.match(/\/(repository|settings|efatura_request)$/)
		) {
			return (
				<Redirect
					to={`${locale ? `/${locale}` : ""}/${projectId}/repository`}
				/>
			);
		}
	}

	const queryParams = parse(location.search) || {};
	const routeParams = match.params || {};
	const params = {
		...queryParams,
		...routeParams,
	};

	const goto = (page, filters, anchor) => {
		const pageUrl = page
			? (typeof page.join === "function" ? page.join("/") : page).replace(
					/\/+/g,
					"/"
			  )
			: null;
		const urlFilters = filters
			? Array.isArray(filters)
				? stringifyFilter(filters)
				: stringifyFilter([filters])
			: "";
		const localePrefixed = pageUrl
			? `${locale ? `/${locale}${!pageUrl.match(/^\//) ? "/" : ""}` : ""}`
			: null;
		const gotoPage = pageUrl
			? `${localePrefixed}${pageUrl}${
					urlFilters.length
						? `?filter=${urlFilters}&resetFilters=${Date.now()}`
						: ""
			  }`
			: `${location.pathname}?filter=${urlFilters}&resetFilters=${Date.now()}${
					anchor ? `#${anchor}` : ""
			  }`;
		history.push(gotoPage);
	};

	const routeQueries = (route.queries && route.queries(params)) || [];

	const genericOverview =
		fetch.results[
			`en_generic_overview_fiscalYear_${fiscalYear}_importId_full_modulePrefix_${modulePrefix}_projectId_${projectId}`
		];

	const getLayout = JSON.parse(genericOverview || "[]");

	changeDeepObject(getLayout, ["component", "cards"]);

	let displayComponents = [];

	displayComponents.push(...route.components);

	if (getLayout.length > 0 && route && route.isOverview) {
		displayComponents.push(...getLayout);
	}

	const children = displayComponents.map((c, index) => {
		const Component = c.component;
		const componentProps = mapProperties(
			c.props,
			(fetch || {}).results,
			(fetch || {}).status,
			routeQueries,
			props,
			params,
			i18n,
			locale
		);

		return (
			<Component
				key={index}
				modifiers={c.modifiers}
				{...componentProps}
				activity={fetch.activity}
			/>
		);
	});

	const title = mapProperties(
		{ title: route.title },
		(fetch || {}).results,
		(fetch || {}).status,
		routeQueries,
		props,
		params,
		i18n,
		locale
	).title;

	const branch = matchRoutes(
		[route, ...(route.routes || [])],
		location.pathname
	);

	const branchQueries = branch.reduce((allQueries, { route }) => {
		const innerQueries = route.queries ? route.queries(params) : [];
		allQueries = [...innerQueries];
		return allQueries;
	}, []);

	const downloadableQueries = branchQueries.filter(
		({ downloadable }) => downloadable
	);

	const { navigation } = mapProperties(
		{ navigation: route.navigation },
		(fetch || {}).results,
		(fetch || {}).status,
		AppByFile.queries(params),
		{
			...props,
			...navData,
		},
		params,
		i18n,
		locale
	);

	const handleHover = (state) => {
		setHover(state);
	};

	return (
		<Provider
			user={userInformation}
			proxy={proxy}
			theme={theme}
			uploads={uploads}
			actions={{
				pushModal,
				pushDialog,
				popModal,
				uploadFiles,
				download,
				submitAuditNote,
				removeModal,
				setDataFileStatus,
				deleteFilePermanently,
				deleteInvalidFilePermanently,
				hideFile,
				downloadFile,
				reprocessRepository,
				logout,
				sendSAP,
				downloadJSON,
				getFindingDetails,
				setLocale: (locale) => {
					history.push(`/${locale}${history.location.pathname.substring(3)}`);
				},
				search: searchAction,
				goto,
				downloadableQueries,
			}}
			i18n={i18n}
			locale={locale || localesByAppTheme[0].id}
			locales={localesByAppTheme}
			reload={reload}
			params={{
				...params,
				fiscalYear: params.fiscalYear || fiscalYear,
				importId: params.importId || importId,
			}}
			history={history}
			location={location}
			repositoryStatus={repositoryStatus}
			auditNote={auditNote}
		>
			<PersistGate persistor={persistor}>
				<div className={styles["app"]}>
					<div
						onMouseEnter={() =>
							menu === "collapsed" ? handleHover(true) : null
						}
						onMouseLeave={() =>
							menu === "collapsed" ? handleHover(false) : null
						}
						className={`${styles["aside"]} ${
							menu === "expanded" ? "" : styles["is-collapsed"]
						}`}
					>
						<AsideMenu
							logo={logo}
							navigation={navigation}
							organizations={modulesOrganizations}
							version={version}
							project={{
								...(project || {}),
								id: params.projectId,
								name: (project || {}).name || (project || {}).description,
							}}
							expanded={menu === "expanded"}
							toggleMenu={toggleMenu}
							hover={hover}
							setHover={setHover}
							settingsAction={checkRoles(userInformation, [
								UserRoles.ADMIN,
								UserRoles.OWNER,
								UserRoles.PP_SUPPORT,
								UserRoles.SUPPORT,
							])}
						/>
					</div>
					<div className={styles["main"]}>
						<Connect to={["search", "downloading"]}>
							{({ search, downloading }) => (
								<Header
									className={styles["header"]}
									searchAction
									reportsAction
									settingsAction={checkRoles(userInformation, [
										UserRoles.ADMIN,
										UserRoles.OWNER,
										UserRoles.PP_SUPPORT,
										UserRoles.SUPPORT,
									])}
									project={{
										...(project || {}),
										id: params.projectId,
										name: (project || {}).name || (project || {}).description,
										taxRegistrationNumber: project.taxRegistrationNumber,
									}}
									hasFullNavigation={hasFullNavigation}
									fiscalYears={fiscalYearsWithImportId}
									importIds={importIds}
									items={items(i18n)}
									downloadableQueries={downloadableQueries}
									title={title}
									downloading={downloading}
									search={search}
									navigation={navigation}
									organizations={organizations}
									menuLogo={logo}
									navByImportId={route.navByImportId}
									notifications={response.length}
									notificationList={response}
									showCsvButton={
										route && route.showCsvButton ? route.showCsvButton : false
									}
								/>
							)}
						</Connect>
						<main
							className={`${styles["content"] || ""} ${
								styles["foreground"] || ""
							}`}
						>
							{fetch.activity && (
								<LoadingIndicator
									className={`${styles["loading-indicator"]} ${
										menu === "expanded" ? "" : styles["small-margin"]
									}`}
								/>
							)}
							{children}
							{route.routes && route.routes.length > 1 && (
								<MainTabs
									className={styles["tabs"]}
									routes={route.routes}
									params={match.params}
								/>
							)}
							{renderRoutes(route.routes)}
						</main>
					</div>
					<ModalController popModal={popModal} />
					<DialogController i18n={i18n} />
				</div>
			</PersistGate>
		</Provider>
	);
};

AppByFile.queries = (args, userModulesByProject) => {
	const queries = filteredQueriesByModules(userModulesByProject);
	const deepCopyQueries = JSON.parse(JSON.stringify(queries));
	if (deepCopyQueries) {
		//needed to change arguments to the respective value
		deepCopyQueries.forEach((query) =>
			Object.keys(query.args).forEach((k) =>
				query.args[k]
					? query.args[k]
					: (query.args[k] = args[k] || query.args[k])
			)
		);
	}

	return [
		{
			resource: "user_organizations",
			body: "id,name,organizationExpired,projects{id,name,fiscalYears,status,taxRegistrationNumber,projectExpired,modules{navigation_by_import_id,name}}",
		},
		{
			resource: "project",
			args: {
				targetProjectId: args.projectId,
			},
			body: "name, description, fiscalYears, taxRegistrationNumber",
		},
		{
			resource: "application_version",
			body: "version",
		},
		{
			resource: "project_modules",
			args: {
				projectId: args.projectId,
			},
			body: "modules",
		},
		...deepCopyQueries,
	];
};

export default connect(
	({ user, fetch, menu, uploads, auditNote }) => ({
		user,
		fetch,
		menu,
		uploads,
		auditNote,
	}),
	{
		logout,
		pushModal,
		popModal,
		removeModal,
		toggleMenu,
		setLocale,
		pushDialog,
		getFindingDetails,
		sendSAP: sendSAPAction,
		downloadJSON: downloadJSONAction,
		setDataFileStatus: setDataFileStatusAction,
		deleteFilePermanently: deleteFilePermanentlyAction,
		deleteInvalidFilePermanently: deleteInvalidFilePermanentlyAction,
		hideFile: hideFileAction,
		downloadFile: downloadFileAction,
		reprocessRepository: reprocessRepositoryAction,
		uploadFiles: uploadAction,
		download: downloadAction,
		submitAuditNote: auditNoteSubmit,
		searchAction,
	}
)(withRouter(AppByFile));
